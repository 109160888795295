import { List } from "immutable";

import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { ClientEnqueuedDocument } from "model/document";
import { ClientDocument } from "model/user";

const prefix = `${API_PREFIX}/document`;

const getClientDocuments = () => fetch.doGet(`${prefix}/client`, null, ClientDocument.fromServerList);
const getClientDocumentDownloadUrl = (idDocument) => `${prefix}/client/document-pdf/${idDocument}`;

const getContractDocuments = (idObject) => fetch.doGet(`${prefix}/contract/${idObject}`, null, ClientDocument.fromServerList);
const getContractDocumentDownloadUrl = (idObject, documentGuid) => {
    const encodedGuid = encodeURIComponent(documentGuid);
    return `${prefix}/contract/${idObject}/document-pdf-guid?guid=${encodedGuid}`;
};

const generateAndEnqueueDocument = (idObject, documentType, documentParams) =>
    fetch.doPost(`${prefix}/generate-and-enqueue-document`, { idObject, documentType, documentParams });
const getContractEnqueuedDocuments = (idObject) =>
    fetch.doGet(`${prefix}/contract/${idObject}/enqueued`, null, ClientEnqueuedDocument.fromServerList);
const getContractEnqueuedDocumentDownloadUrl = (idObject, idDocument) =>
    `${prefix}/contract/${idObject}/enqueued/document-pdf/${idDocument}`;

const getGreenCardDownloadUrl = (idObject, vinNumber) => {
    const encodedVinNumber = encodeURIComponent(vinNumber);
    return `${prefix}/contract/${idObject}/green-card?vinNumber=${encodedVinNumber}`;
};

const getLifeTaxCertificateDownloadUrl = (idObject, year) => `${prefix}/contract/${idObject}/get-life-tax-certificate/${year}`;
const getAvailableYearsLifeTaxCertificate = (idObject) =>
    fetch.doGet(`${prefix}/contract/${idObject}/get-available-years-life-tax-certificate`, null, List);

const getPensionRedeemSavingsDocumentDownloadUrl = (idObject) => `${prefix}/contract/${idObject}/pension-redeem-savings-document`;

export default {
    getClientDocuments,
    getClientDocumentDownloadUrl,
    getContractDocuments,
    getContractDocumentDownloadUrl,
    generateAndEnqueueDocument,
    getGreenCardDownloadUrl,
    getContractEnqueuedDocuments,
    getContractEnqueuedDocumentDownloadUrl,
    getAvailableYearsLifeTaxCertificate,
    getLifeTaxCertificateDownloadUrl,
    getPensionRedeemSavingsDocumentDownloadUrl,
};
