import { API_PREFIX } from "app/constants";
import superagent from "superagent";
import to from "core/util/fetch/awaitToJs";

const uriPrefix = `${API_PREFIX}/vehicle-subject-change`;

const isFeasible = async (idObject: string) => {
    return await to(superagent.get(`${uriPrefix}/${idObject}/is-feasible`));
};

const submit = async (body: VehicleSubjectChangeSubmitDTO) => {
    return await to(superagent.post(`${uriPrefix}/submit`).send(body).set("Content-Type", "application/json"));
};

const sign = async (idChange: string, body: SignatureDto) => {
    return await to(superagent.post(`${uriPrefix}/sign/${idChange}`).send(body).set("Content-Type", "application/json"));
};

const finalize = async (idChange: string, envelopeId: string) => {
    return await to(superagent.get(`${uriPrefix}/sign/${idChange}/finalize/${envelopeId}`));
};

export default {
    isFeasible,
    submit,
    sign,
    finalize,
};

export type AddressDTO = {
    street: string;
    orientationNumber: string;
    descriptionNumber: string;
    city: string;
    postalCode: string;
    countryCode: string;
};

export type PersonDTO = {
    subjectType: "INDIVIDUAL" | "COMPANY";
    firstName: string;
    lastName: string;
    birthNumber: string;
    phoneNumber: string;
    email: string;
    companyIdentificationNumber: string;
    companyName: string;
    address: AddressDTO;
};

export type VehicleSubjectChangeSubmitDTO = {
    vinNumber: string;
    validityStartDate: Date | string;
    idObject: number;
    licencePlateNumber: string;
    owner: PersonDTO;
    operator: PersonDTO;
    signatureType: string;
    dateOfSigning: Date | string;
    placeOfSigning: string;
};

export type SignatureDto = {
    idObject: number;
    redirectUrl: string;
};
