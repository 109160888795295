import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import userContracts from "core/userContracts";
import pageContext from "core/pageContext";
import { fn } from "core/util";

import { FeatureFlag, GridItemVariant } from "types";

import {
    BalanceOverdueGridItem,
    ContractPaymentSchedulerGridItem,
    HarmonizedContractPaymentSchedulerGridItem,
    InsuranceProductPaymentGridItem,
    TotalInsuranceWithBalanceOverdueGridItem,
} from "containers/contract";

import {
    createGetActualPaymentScheduler,
    createGetAdvancedPaymentScheduler,
    createGetBalanceOnContract,
    getIsTaxCertificateAvailable,
} from "../selectors";
import { ENTITY_FINANCIAL_OPERATIONS, ENTITY_PAYMENT_INFORMATION } from "../constants";
import { FinancialOperationsGridItem, TaxStatementGridItem } from "../containers";

export const FinancialOperationsTab: FC = () => {
    const { idObject } = useParams();

    const actualScheduler = useSelector(createGetActualPaymentScheduler);
    const advancedScheduler = useSelector(createGetAdvancedPaymentScheduler);
    const balanceOnContract = useSelector(createGetBalanceOnContract);
    const isContractBalanceZeroOrValueNull = !balanceOnContract || fn.isContractBalanceZeroOrValueNull(balanceOnContract);
    const isTaxCertificateAvailable = useSelector(getIsTaxCertificateAvailable);
    const contract = userContracts.useGetContractById(idObject);
    const isUniqaContract = contract?.isUniqaContract;

    const isDisplayTotalInsuranceEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.DISPLAY_TOTAL_INSURANCE);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    return (
        <GridLayout defaultSpacing>
            {!isInFutureOrTerminated && (
                <>
                    {isUniqaContract ? (
                        <HarmonizedContractPaymentSchedulerGridItem
                            actualPaymentScheduler={actualScheduler}
                            advancePaymentsScheduler={advancedScheduler}
                        />
                    ) : (
                        <ContractPaymentSchedulerGridItem
                            actualPaymentScheduler={actualScheduler}
                            advancePaymentsScheduler={advancedScheduler}
                        />
                    )}
                    <InsuranceProductPaymentGridItem
                        idObject={Number(idObject)}
                        entityName={ENTITY_PAYMENT_INFORMATION}
                        variant={GridItemVariant.SEPARATED}
                    />
                </>
            )}

            {isUniqaContract && !isDisplayTotalInsuranceEnabled && !isContractBalanceZeroOrValueNull && (
                <BalanceOverdueGridItem contractBalance={balanceOnContract.balanceOnContract} />
            )}

            {(!isUniqaContract || isDisplayTotalInsuranceEnabled) && (
                <TotalInsuranceWithBalanceOverdueGridItem contractBalance={balanceOnContract} />
            )}

            <FinancialOperationsGridItem entityName={ENTITY_FINANCIAL_OPERATIONS} />

            {/* ECM tax statement */}
            {isTaxCertificateAvailable && <TaxStatementGridItem />}
        </GridLayout>
    );
};
