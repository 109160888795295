import React from "react";
import PropTypes from "prop-types";

import { Flexbox, GridItem } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { ClaimCategory } from "enums";

import { CardAxaIncident } from "./CardAxaIncident";
import { CardUniqaIncident } from "./CardUniqaIncident";
import { WebclaimIncident } from "./WebclaimIncident";
import { IncidentDtoIncidentStateEnum } from "types/generated";

export const IncidentsList = ({ incidentsData, incidentType, shouldDisplayContractNumber }) => {
    const { t } = i18n.useTranslation();

    const openedIncidents = incidentsData.incidents.filter((incident) => incident.categoryClaim !== ClaimCategory.FIN.id);
    const closedIncidents = incidentsData.incidents.filter((incident) => incident.categoryClaim === ClaimCategory.FIN.id);
    const dataToDisplay = incidentType === IncidentDtoIncidentStateEnum.Opened ? openedIncidents : closedIncidents;

    return (
        <>
            <GridItem xs={12}>
                <Flexbox alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h3">
                        {incidentType === IncidentDtoIncidentStateEnum.Opened ? t("incident.openIncidents") : t("incident.closedIncidents")}
                    </Typography>
                </Flexbox>
            </GridItem>

            {/* Empty list */}
            {dataToDisplay.size === 0 && (
                <GridItem xs={12}>
                    <Typography>
                        {incidentType === IncidentDtoIncidentStateEnum.Opened
                            ? t("incident.noOpenIncidents")
                            : t("incident.noClosedIncidents")}
                    </Typography>
                </GridItem>
            )}

            {/* Non empty list */}
            {dataToDisplay.size > 0 &&
                dataToDisplay.map((item, index) => {
                    if (item.get("categoryClaim") === "WEBCLAIM") {
                        return <WebclaimIncident incident={item} shouldDisplayContractNumber={shouldDisplayContractNumber} />;
                    } else
                        return !item.isUniqaClaim ? (
                            <CardAxaIncident
                                key={index}
                                incident={item}
                                isActive={incidentType === IncidentDtoIncidentStateEnum.Opened}
                                lifeDetailLink={incidentsData.lifeClaimsDetailLink}
                                nonLifeDetailLink={incidentsData.nonLifeClaimsDetailLink}
                                shouldDisplayContractNumber={shouldDisplayContractNumber}
                            />
                        ) : (
                            <CardUniqaIncident
                                key={index}
                                incident={item}
                                isActive={incidentType === IncidentDtoIncidentStateEnum.Opened}
                                shouldDisplayContractNumber={shouldDisplayContractNumber}
                            />
                        );
                })}
        </>
    );
};

IncidentsList.propTypes = {
    incidentsData: PropTypes.object.isRequired,
    incidentType: PropTypes.string.isRequired,
    shouldDisplayContractNumber: PropTypes.bool,
};

IncidentsList.defaultProps = {
    shouldDisplayContractNumber: false,
};
