import { call, getContext, put, select, spawn, takeLatest } from "redux-saga/effects";
import pageContext from "core/pageContext";
import router from "core/router";
import user from "core/user";
import userInfo from "core/userInfo";
import { PAGES_WITH_KERBEROS_LOGIN, PUBLIC_PAGES } from "app/constants";
import { fn, sentry } from "core/util";
import { Locales } from "enums";
import { Pages, Tabs } from "routeConstants";
import { innerRoutes, pageRoutes } from "routeUrls";
import { authApi } from "serverApi";

import { LogoutAction } from "./constants";
import { getIsSsoLoginEnabled } from "./selectors";
import slice from "./slice";

export default function* () {
    yield takeLatest(slice.actions.logIn.type, onLogin);
    yield takeLatest(slice.actions.logOut.type, onLogout);
}

function* onLogin() {
    yield call(userInfo.loadCriticalUserInfo);
    yield spawn(userInfo.loadAgents);
    yield spawn(userInfo.loadMessages);

    const acceptedDisclaimer = yield select(userInfo.getCurrentDisclaimerAccepted);
    // true will replace login page (last entry) from history with new entry. Allows cleaner navigation
    if (!acceptedDisclaimer) {
        yield put(router.navigate(Pages.DISCLAIMER));
    } else {
        yield put(router.navigate(Pages.CONTRACTS));
    }
}

function* onLogout(action) {
    const isSsoLoginEnabled = yield select(getIsSsoLoginEnabled);

    try {
        yield call(authApi.logout);

        // If SSO enabled we need to
        if (isSsoLoginEnabled) {
            const logoutUri = yield select(pageContext.getLogoutUri);
            const locale = yield select(pageContext.getLocale);
            const redirectUri = getLogoutRedirectUri(action.payload, locale);

            yield put(router.navigateExternal(logoutUri, { redirect_uri: redirectUri }));
        }
    } catch (e) {
        sentry.captureException(e);
    }
    // We are using location.href here because it forces the browser to refresh.
    // TODO: Dirty solution to clean everything up. Find better way.
    if (!isSsoLoginEnabled) {
        window.location.href = pageRoutes[Pages.LOGIN];
    }
}

export const ssoLoginSaga = function* ssoLoginSaga() {
    const { href, pathname, search } = window.location;
    const pageInformation = router.parseLocation(pathname, search);
    const pageName = pageInformation?.name;

    // Check if user is already logged in. If yes we do not need to run SSO process.
    const isUserLoggedIn = yield call(checkIsUserLoggedIn);
    const isSsoLoginEnabled = yield select(getIsSsoLoginEnabled);
    if ((isUserLoggedIn || !isSsoLoginEnabled) && !pageInformation?.query?.code) {
        return;
    }

    if (
        // If code in query is defined (call from SSO) and page is not impersonate or use-case (separate saga)
        // we want to continue in saga further.
        (pageInformation?.query?.code && ![Pages.IMPERSONATE].includes(pageName)) ||
        (![Pages.LOGIN].includes(pageName) && !PUBLIC_PAGES.includes(pageName))
    ) {
        try {
            // First step - we don't have code in query params = SSO call is needed.
            if (!pageInformation.query?.code) {
                const isKerberosLoginEnabled = PAGES_WITH_KERBEROS_LOGIN.includes(pageName);
                yield put(router.navigateSsoLogin(isKerberosLoginEnabled));
                yield call(fn.block);
            } else if (pageInformation.query?.code) {
                const redirectUri = href.split("?").shift();
                const response = yield call(authApi.ssoLogin, {
                    code: pageInformation.query?.code,
                    redirectUri,
                });

                // Remove query params.
                const history = yield getContext("history");
                history.replace({ search: "" });

                if (!response.isLoggedInSuccessfully) {
                    yield put(slice.actions.setLoginUnsuccessfulReason(response.loginUnsuccessfulReason));
                }
            }
        } catch (e) {
            console.error(e);
            yield put(router.navigate(Pages.LOGIN));
        }
    }
};

function* checkIsUserLoggedIn() {
    try {
        yield call(userInfo.loadCriticalUserInfo);
        const userRoles = yield select(user.getUserRoles);
        return userRoles?.length !== 0;
    } catch (e) {
        return false;
    }
}

function getLogoutRedirectUri(logoutAction, locale) {
    const { protocol, host } = window.location;
    const appPath = getLogoutRedirectAppPath(logoutAction);
    const langPostfix = locale === Locales.en_US ? `?${router.LANG_PARAM}=${Locales.en_US.languageCode}` : "";

    return `${protocol}//${host}${appPath}${langPostfix}`;
}

function getLogoutRedirectAppPath(logoutAction) {
    switch (logoutAction) {
        case LogoutAction.PASSWORD_CHANGED:
            return `${pageRoutes[Pages.NOTIFICATION]}/${innerRoutes[Tabs.NOTIFICATION_CHANGE_PASSWORD_SUCCESS]}`;
        case LogoutAction.USERNAME_CHANGED:
            return `${pageRoutes[Pages.NOTIFICATION]}/${innerRoutes[Tabs.NOTIFICATION_CHANGE_USERNAME_SUCCESS]}`;
        case LogoutAction.ONE_TIME_LOGOUT:
            return pageRoutes[Pages.CONTRACTS];
        case LogoutAction.WRONG_PORTAL_USER:
            const { pathname } = window.location;
            return pathname;
        default:
            return pageRoutes[Pages.LOGIN];
    }
}
