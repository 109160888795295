import React, { FC } from "react";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { emailCharacters, Field, InputText, max20Characters, max50Characters, phoneNumberCharacters } from "core/form";
import { FieldSize } from "enums";

import { EMAIL_FIELD, MOBILE_FIELD } from "../../constants";

export interface PhoneEmailFormBoxProps {
    formName: string;
}

export const PhoneEmailFormBox: FC<PhoneEmailFormBoxProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <ContentBox>
            <GridLayout spacing={1}>
                <GridItem md={4}>
                    <Field
                        name={MOBILE_FIELD}
                        component={InputText}
                        label={t("holder.tel")}
                        normalize={[phoneNumberCharacters, max20Characters]}
                        preventDefaultNormalization
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem md={8}>
                    <Field
                        name={EMAIL_FIELD}
                        component={InputText}
                        label={t("holder.emailAddress")}
                        normalize={[emailCharacters, max50Characters]}
                        preventDefaultNormalization
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
            </GridLayout>
        </ContentBox>
    );
};
