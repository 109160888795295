let useSnackbarRef;

export const snackbarUtils = {
    setUseSnackbarRef: (snackbarRef) => {
        useSnackbarRef = snackbarRef;
    },
    success: (message, options = {}) => {
        useSnackbarRef.enqueueSnackbar(message, { variant: "success", ...options });
    },
    error: (message, options = {}) => {
        useSnackbarRef.enqueueSnackbar(message, { variant: "error", ...options });
    },
    info: (message, options = {}) => {
        useSnackbarRef.enqueueSnackbar(message, { variant: "info", ...options });
    },
    warning: (message, options = {}) => {
        useSnackbarRef.enqueueSnackbar(message, { variant: "warning", ...options });
    },
};
