import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { DataLoadingsStatusEnum, LegalEntityV2 as LegalEntityV2Type } from "types";

import { clear, setClientData, setClientDataState } from "./actions";
import { LegalEntityV2 } from "model/common/v2";

const clientData = createReducer<LegalEntityV2Type>(LegalEntityV2.fromServer(), (builder) => {
    builder.addCase(setClientData, (_, { payload }) => payload);
    builder.addCase(clear, () => LegalEntityV2.fromServer());
});

const clientDataState = createReducer<DataLoadingsStatusEnum>(DataLoadingsStatusEnum.LOADING, (builder) => {
    builder.addCase(setClientDataState, (_, { payload }) => payload);
    builder.addCase(clear, () => DataLoadingsStatusEnum.LOADING);
});

export default immutableCombineReducers({
    data: combineReducers({
        clientData,
        clientDataState,
    }),
});
