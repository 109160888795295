import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form/immutable";

import { Box, GridItem, GridLayout } from "ui-library/layouts";

import { Field, getFormFieldValue, RadioGroup, required } from "core/form";
import i18n from "core/i18n";

import { SubmitButton } from "../../components";
import { SUBMIT_TYPE_FIELD, TYPE_OF_DATA_CHANGE_FIELD } from "../../constants";
import { DataChangeType, SubmitType } from "../../types";
import { getIsAnyClientDataValueChanged, getIsAnyContractSelected } from "../../selectors";

export interface SelectChangeSubmitFormBoxProps {
    formName: string;
    handleSubmit: () => void;
    isFormBeingSubmitted: boolean;
}

export const SelectChangeSubmitFormBox: FC<SelectChangeSubmitFormBoxProps> = ({ formName, handleSubmit, isFormBeingSubmitted }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const isAnyContractSelected = useSelector(getIsAnyContractSelected(formName));
    const isAnyClientDataValueChanged = useSelector(getIsAnyClientDataValueChanged(formName));

    const selectedTypeOfDataChange = useSelector(getFormFieldValue(formName, TYPE_OF_DATA_CHANGE_FIELD));
    const canBeSubmitted =
        selectedTypeOfDataChange === DataChangeType.ALL ||
        (selectedTypeOfDataChange === DataChangeType.CHANGED && isAnyClientDataValueChanged);

    const dataChangeTypeOptions = [
        { value: DataChangeType.ALL, label: t("form.clientDataOnContracts.dataChangeType.all") },
        { value: DataChangeType.CHANGED, label: t("form.clientDataOnContracts.dataChangeType.changed") },
    ];

    const onSubmitClick = () => {
        dispatch(change(formName, SUBMIT_TYPE_FIELD, SubmitType.CHANGE_CLIENT_DATA));
        handleSubmit();
    };

    return (
        <GridLayout spacing={1}>
            <GridItem xs={9}>
                <Box mb={1}>
                    <Field
                        component={RadioGroup}
                        name={TYPE_OF_DATA_CHANGE_FIELD}
                        direction="row"
                        validateStatic={[required]}
                        options={dataChangeTypeOptions}
                    />
                </Box>
            </GridItem>

            <GridItem xs={3}>
                <SubmitButton
                    onClick={onSubmitClick}
                    title={t("common.update")}
                    additionalStyle={{ marginTop: 4 }}
                    disabled={!isAnyContractSelected || !canBeSubmitted || isFormBeingSubmitted}
                />
            </GridItem>
        </GridLayout>
    );
};
