import React from "react";
import { useSelector } from "react-redux";

import { dateTimeToLocalFullDate, formatClaimVehicle } from "core/formatters";
import i18n from "core/i18n";
import { ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import { getClaimData } from "../selectors";
import { formatAdvancedPayment, formatClaimStatusWithPaymentInfo, getClaimStateObj } from "../util";
import { ClaimOrders } from "./ClaimOrders";

const formatDamageDescription = (subject) => subject?.damageDescription || "-";

export const ClaimDetailVehicle = () => {
    const { t } = i18n.useTranslation();
    const claimDetail = useSelector(getClaimData);
    const claimState = getClaimStateObj(claimDetail.get("claimState"));
    const isClaimClosed = claimState.isClaimClosed;
    const advancePayment = !isClaimClosed ? formatAdvancedPayment(t, claimDetail) : null;

    return (
        <GridItem lg={7} xs={12}>
            <ContentBox bigger mb={2}>
                <InfoPanel icon="document-info" title={t("claim.information")}>
                    <ContentBox bigger>
                        <LabelTextItem label={t("claim.vehicle")} text={formatClaimVehicle(claimDetail.subject)} />
                        <LabelTextItem label={t("vehicle.licencePlateNumber")} text={claimDetail.subject?.licensePlate} />
                        <LabelTextItem label={t("claim.damageDescription")} text={formatDamageDescription(claimDetail.subject)} />
                        <LabelTextItem label={t("claim.incidentRegistrationDate")} text={dateTimeToLocalFullDate(claimDetail.reportDate)} />
                        {isClaimClosed && (
                            <LabelTextItem label={t("claim.claimClosureDate")} text={dateTimeToLocalFullDate(claimDetail.closureDate)} />
                        )}
                        <LabelTextItem label={t("claim.claimActualState")} text={t(claimState.msg)} />
                        {isClaimClosed && (
                            <LabelTextItem label={t("claim.closureType")} text={formatClaimStatusWithPaymentInfo(t, claimDetail)} />
                        )}
                        {advancePayment && <LabelTextItem label={t("claim.advancePayment")} text={advancePayment} />}
                    </ContentBox>
                </InfoPanel>
            </ContentBox>
            <ClaimOrders />
        </GridItem>
    );
};
