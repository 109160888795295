import React, { FC } from "react";
import { useFormContext } from "react-hook-form";

import { Button, Typography } from "ui-library/atoms";
import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { nbsp } from "core/util";
import { max6Characters, numberCharacters } from "core/form";

import { default as Field } from "./Field";

/**
 * We get weird number of X from server (xxxxxxxxxx605). So here, we use only numbers at the end.
 */
const formatPhoneNumber = (phoneString) => {
    const number = phoneString.replace(/x/g, "");
    return `+xxx${nbsp}xxx${nbsp}xxx${nbsp}${number}`;
};

export interface FormOtpBoxProps {
    handleOtpResend: () => void;
    isOtpResent?: boolean;
}

export const FormOtpBox: FC<FormOtpBoxProps> = ({ handleOtpResend, isOtpResent = false }) => {
    const { watch } = useFormContext();
    const phone = watch("otpPhone");

    const { t } = i18n.useTranslation();
    const key = isOtpResent ? "form.otp.phonePinTextAgain" : "form.otp.phonePinText";
    const text = phone ? t(key, { phone: formatPhoneNumber(phone) }) : undefined;

    return (
        <Box>
            <ContentBox>
                <Typography variant="h3">{t("form.confirmation")}</Typography>
            </ContentBox>

            {text && (
                <ContentBox>
                    <Typography markDown={text} />
                </ContentBox>
            )}

            <ContentBox mb={6}>
                <Box mb={1}>
                    <Field
                        input="textField"
                        name="otpCode"
                        label={t("form.otp.label")}
                        normalize={[numberCharacters, max6Characters]}
                        placeholder={t("form.otp.ePinPlaceholder")}
                    />
                </Box>
                <Button variant="text" color="blue" onClick={handleOtpResend}>
                    {t("form.otp.resend")}
                </Button>
            </ContentBox>
        </Box>
    );
};
