import { model } from "core/util";
import { IdentityCardV2 as IdentityCardV2Type } from "types";

export const IdentityCardV2 = model.createModel<IdentityCardV2Type, IdentityCardV2Type>((json) => ({
    idTypeCode: json.idTypeCode,
    number: json.number,
    validFrom: json.validFrom,
    validTo: json.validTo,
    authority: json.authority,
}));
