import { FC, PropsWithChildren } from "react";
import { StyledCircularLoader, StyledLoaderComponentWrapper, StyledLoaderWrapper } from "ui-library/Styled";

export interface SimpleLoaderProps extends PropsWithChildren {
    loading: boolean;
    size?: number;
}

export const SimpleLoader: FC<SimpleLoaderProps> = ({ loading, size = 20 }) => {
    if (loading) {
        return <StyledCircularLoader size={size} />;
    }
    return null;
};
