import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import throttle from "lodash/throttle";
import { Autocomplete } from "@mui/material";

import { GOOGLE_MAPS_MIN_CHARACTERS_FETCH } from "app/constants";
import { getErrorsAsString } from "core/form";

import { TextField } from "ui-library/atoms";

import { makeSelectSuggestion } from "./actions";

const autocompleteService = { current: null };

/**
 * Creates component for single autocomplete.
 *
 * @param {string} name Unique name for autocomplete for redux purposes.
 *      Having same name with multiple instances means these instances will
 *      have shared state
 */
export default (name: string) => {
    const selectSuggestion = makeSelectSuggestion(name);

    return ({ input, label, meta, disabled, id, size }) => {
        const [value, setValue] = useState(null);
        const [inputValue, setInputValue] = useState("");
        const [options, setOptions] = useState([]);
        const dispatch = useDispatch();

        const fetch = useMemo(
            () =>
                throttle((request, callback) => {
                    autocompleteService.current.getPlacePredictions(request, callback);
                }, 200),
            [],
        );

        useEffect(() => {
            // We want to start fetching only after defined number of characters is typed.
            if (!inputValue || inputValue.length < GOOGLE_MAPS_MIN_CHARACTERS_FETCH) {
                return false;
            }

            let active = true;

            if (!autocompleteService.current && window.google) {
                autocompleteService.current = new window.google.maps.places.AutocompleteService();
            }
            if (!autocompleteService.current) {
                return undefined;
            }

            if (inputValue === "") {
                setOptions(value ? [value] : []);
                return undefined;
            }

            fetch({ input: inputValue }, (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });

            return () => {
                active = false;
            };
        }, [value, inputValue, fetch]);

        const errorContent = getErrorsAsString(meta);

        return (
            <Autocomplete
                disablePortal
                freeSolo
                id={id}
                includeInputInList
                options={options}
                getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
                disabled={disabled}
                value={input.value}
                onChange={(event, newValue) => {
                    if (newValue && newValue.place_id) {
                        dispatch(selectSuggestion(newValue.place_id));
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    input.onChange(newInputValue || "");
                }}
                size={size}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        error={meta.touched && meta.error && !meta.error.isEmpty()}
                        errorContent={errorContent}
                        isValueChanged={meta.dirty}
                    />
                )}
            />
        );
    };
};
