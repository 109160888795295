import React from "react";
import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";

import { Select } from "ui-library/atoms";

import { FieldSize } from "enums";

import { ENTER_KEY } from "../constants";
import { Consumer } from "../FormWideOnEnterContext";
import { getErrorsAsString } from "../util";

const SingleSelect = ({
    input,
    options,
    meta,
    tabIndex,
    disabled,
    id,
    onEnter,
    label,
    labelOptional,
    labelDesc,
    fullWidth,
    tooltip,
    placeholder,
    autoFocus,
    size,
    colourWhenChanged,
    hideErrorMessage,
}) => {
    // TODO handle error content.
    const errorContent = getErrorsAsString(meta);

    return (
        <Consumer>
            {(formWideOnEnter) => (
                <Select
                    label={label}
                    labelOptional={labelOptional}
                    labelDesc={labelDesc}
                    value={input.value}
                    isValueChanged={meta.dirty}
                    options={options}
                    fullWidth={fullWidth}
                    tooltip={tooltip}
                    error={meta.touched && meta.error && !meta.error.isEmpty()}
                    errorContent={errorContent}
                    placeholder={placeholder}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    id={id}
                    onKeyPress={handleKeyPress(onEnter || formWideOnEnter)}
                    autoFocus={autoFocus}
                    size={size}
                    colourWhenChanged={colourWhenChanged}
                    hideErrorMessage={hideErrorMessage}
                />
            )}
        </Consumer>
    );
};

const handleKeyPress = (onEnterHandler) => (ev) => {
    if (ev.key === ENTER_KEY && onEnterHandler) {
        onEnterHandler();
    }
};

SingleSelect.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    tooltip: PropTypes.string,
    error: PropTypes.bool,
    errorContent: PropTypes.string,
    labelDesc: PropTypes.string,
    size: PropTypes.string,
    colourWhenChanged: PropTypes.bool,
    hideErrorMessage: PropTypes.bool,
};

SingleSelect.defaultProps = {
    placeholder: null,
    tabIndex: null,
    type: null,
    disabled: false,
    onEnter: null,
    autoFocus: false,
    fullWidth: true,
    size: FieldSize.MEDIUM,
    colourWhenChanged: false,
    hideErrorMessage: false,
};

export default SingleSelect;
