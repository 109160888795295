import React, { FC } from "react";

import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";
import { ErrorContent } from "ui-library/atoms";
import { GridItem, GridLayout, LandingPageLayout } from "ui-library/layouts";
import { SectionBanner } from "ui-library/modules";

export interface FullPageMessageProps {
    title: string;
    message: string;
}

export const FullPageMessage: FC<FullPageMessageProps> = ({ title, message }) => (
    <LandingPageLayout>
        <SectionBanner title={title} imageUrl={bannerImg}>
            <GridLayout spacing={1}>
                <GridItem xs={12} mb={2}>
                    <ErrorContent content={message} />
                </GridItem>
            </GridLayout>
        </SectionBanner>
    </LandingPageLayout>
);
