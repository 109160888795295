import { List } from "immutable";
import { createSelector } from "reselect";

import entity from "core/entity";
import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { DEFAULT_EMPTY_PARAM } from "app/constants";
import { oneLineLegalEntityWithDescription } from "core/formatters";
import { app, claims } from "core/util";
import { ClaimsEvents } from "model/claim";
import { ContractOverview } from "model/common";

import { ENTITY_CONTRACT_INCIDENTS, ENTITY_CONTRACT_OVERVIEW, ENTITY_FINANCIAL_OPERATIONS, NAME } from "./constants";
import { getPaymentSchedulerOrEmptyScheduler } from "./util";

const FINANCIAL_OPERATIONS_TRANSLATION_PREFIX = "travel.financialOperation.";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");
const getTravelContract = (state) => getData(state).get("travelContractDetail");
export const getContractOverview = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_OVERVIEW, ContractOverview.fromServer());
const getLineOfBusinessClasses = (state) => getContractOverview(state).getIn(["lineOfBusinessClasses"]);
export const createGetCompliantLineOfBusinessClasses = (idObject) =>
    createSelector(
        getLineOfBusinessClasses,
        userContracts.createGetProductGroupLvl2ById(idObject),
        (lineOfBusinessClasses, productGroupLvl2) => claims.getCompliantLineOfBusinesses(lineOfBusinessClasses, productGroupLvl2),
    );

export const getIdObject = (state) => getTravelContract(state).get("idObject") || DEFAULT_EMPTY_PARAM;

export const getTravelContractDetail = (state) => getTravelContract(state).get("contractDetail");
export const getPolicyHolder = (state) => getTravelContractDetail(state).get("holder");

export const getElectronicCommunication = (state) => getTravelContractDetail(state).get("electronicCommunication");

export const getTravelAccountDetail = (state) => getTravelContractDetail(state).get("lifeAccountDetail");
export const getInsureds = (state) => getTravelAccountDetail(state).get("insureds");
export const getInsuredProperties = (state) => getTravelContractDetail(state).get("insuredRealEstateProperties");

export const getActualPaymentScheduler = (state) =>
    getPaymentSchedulerOrEmptyScheduler(getTravelContractDetail(state).get("actualPaymentsScheduler"));
export const getActualSchedulerRecord = (state) => getActualPaymentScheduler(state).first();
export const getAdvancedPaymentScheduler = (state) =>
    getPaymentSchedulerOrEmptyScheduler(getTravelContractDetail(state).get("advancePaymentsScheduler"));

export const getInsuranceCostSummary = (state) => getTravelContract(state).get("insuranceCostSummary");
export const getBalanceOnContract = (state) => getTravelContractDetail(state).balanceOnContract;

export const getIncidents = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_INCIDENTS, ClaimsEvents.fromServer());
export const getClaims = (state) => getIncidents(state).get("claims");

/** appState */
const getAppState = (state) => getModel(state).get("appState");
export const getDetailSelectedInsured = (state) => getAppState(state).get("detailSelectedInsured");
export const getStatementTab = (state) => getAppState(state).get("statementTab");

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

/** custom selectors */
export const getSelectedRisk = createSelector(
    getInsureds,
    getInsuredProperties,
    getDetailSelectedInsured,
    (insureds, insuredProperties, selectedInsured) => {
        const foundInsuredPerson = insureds.find((insuredPerson) => insuredPerson.reactKey === selectedInsured);
        if (foundInsuredPerson) {
            return foundInsuredPerson;
        }

        const foundProperty = insuredProperties.find((property) => property.reactKey === selectedInsured);
        if (foundProperty) {
            return foundProperty;
        }
    },
);

export const getInsuredsSelectOptions = createSelector(getInsureds, getInsuredProperties, (insureds, insuredProperties) => {
    const peopleInsurance = insureds.map((insured) => ({
        value: insured.get("reactKey"),
        label: oneLineLegalEntityWithDescription(insured.get("insured")),
    }));

    const propertyInsurance = insuredProperties.map((risk) => ({
        value: risk.reactKey,
        label: `${risk.description} - ${risk.category?.value}`,
    }));

    return peopleInsurance.concat(propertyInsurance);
});

// Financial operations
export const getFinancialOperations = createSelector(entity.getData(ENTITY_FINANCIAL_OPERATIONS, new List()), (financialOperations) =>
    financialOperations.map((finOperation) => ({
        id: finOperation.reactKey,
        status: finOperation.status,
        valueDate: finOperation.dateOfIdentification,
        type: i18n.translateDirectly(`${FINANCIAL_OPERATIONS_TRANSLATION_PREFIX}${finOperation.type}`),
        amount: finOperation.amount,
    })),
);
