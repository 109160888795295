import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { LegalEntity } from "model/common";
import { DataLoadingsStatusEnum, LegalEntity as LegalEntityType } from "types";

import { clear, setClientData, setClientDataState } from "./actions";

const clientData = createReducer<LegalEntityType>(LegalEntity.fromServer(), (builder) => {
    builder.addCase(setClientData, (_, { payload }) => payload);
    builder.addCase(clear, () => LegalEntity.fromServer());
});

const clientDataState = createReducer<DataLoadingsStatusEnum>(DataLoadingsStatusEnum.LOADING, (builder) => {
    builder.addCase(setClientDataState, (_, { payload }) => payload);
    builder.addCase(clear, () => DataLoadingsStatusEnum.LOADING);
});

export default immutableCombineReducers({
    data: combineReducers({
        clientData,
        clientDataState,
    }),
});
