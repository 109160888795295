import type { Selector } from "@reduxjs/toolkit";
import { app } from "core/util";
import { DataLoadingsStatusEnum, LegalEntity as LegalEntityType, State } from "types";

import { NAME } from "./constants";

// Data
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

export const getClientData: Selector<State, LegalEntityType> = (state) => getData(state).clientData;
export const getClientDataState: Selector<State, DataLoadingsStatusEnum> = (state) => getData(state).clientDataState;
