import { useSelector } from "react-redux";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { ContractChangesWithRestrictionTab } from "containers/contract";
import { ContractChangeType, FeatureFlag } from "types";

import { changesDefinition } from "../changesDefinition";
import { getIsActiveOperationsSet } from "../selectors";
import { RestrictionMessage } from "../components";

export function ChangesTab() {
    const { t } = i18n.useTranslation();

    const isAoEnforcement = pageContext.useIsFeatureFlagEnabled(FeatureFlag.PENSION_DDS_ACTIVE_OPERATIONS_ENFORCEMENT);
    const isActiveOperationsSet = useSelector(getIsActiveOperationsSet);
    const isRestricted = isAoEnforcement && !isActiveOperationsSet;

    return (
        <ContractChangesWithRestrictionTab
            changesDefinition={changesDefinition(t, isAoEnforcement, isActiveOperationsSet)}
            nonRestrictedChanges={[ContractChangeType.CONTRACT_ACTIVE_OPERATIONS, ContractChangeType.CONTRACT_NAME]}
            isRestricted={isRestricted}
            RestrictionComponent={RestrictionMessage}
        />
    );
}
