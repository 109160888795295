import { getLocalizedCountryCode } from "core/localized";
import { fn } from "core/util";

const areValuesDifferent = (value1: string, value2: string) => (!value1 && !value2 ? false : value1 !== value2);

export const formatBoldIfDifferent = (newValue: string, originalValue: string) => {
    return areValuesDifferent(newValue, originalValue) ? `**${newValue}**` : newValue;
};

export const formatOneLineNameWithTitlesBoldDifferent = (value, originalValue) => {
    let result = "";

    if (value) {
        if (value.companyName && value.companyName.trim() !== "") {
            result += formatBoldIfDifferent(value.companyName, originalValue.companyName);
        } else {
            if (value.titleBefore) {
                result += formatBoldIfDifferent(value.titleBefore, originalValue.titleBefore) + " ";
            }

            if (value.firstName) {
                result += formatBoldIfDifferent(value.firstName, originalValue.firstName) + " ";
            }

            if (value.lastName) {
                result += formatBoldIfDifferent(value.lastName, originalValue.lastName);
            }

            if (value.titleAfter) {
                result += " " + formatBoldIfDifferent(value.titleAfter, originalValue.titleAfter);
            }
        }
    }

    return result.trim();
};

export const getLocalizedOneLineAddressFullBoldDifferent = (t, address, originalAddress) => {
    let result = "";

    if (address) {
        if (address.street) {
            result += formatBoldIfDifferent(address.street, originalAddress.street);
        }

        if (address.descriptionNumber) {
            if (result.length > 0) {
                result += " ";
            }
            result += formatBoldIfDifferent(address.descriptionNumber, originalAddress.descriptionNumber);
        }

        if (address.orientationNumber) {
            if (address.descriptionNumber) {
                result += "/";
            } else if (result.length > 0) {
                result += " ";
            }
            result += formatBoldIfDifferent(address.orientationNumber, originalAddress.orientationNumber);
        }

        if (address.city) {
            if (result.length > 0) {
                result += ", ";
            }
            result += formatBoldIfDifferent(address.city, originalAddress.city);
        }

        if (address.zip) {
            if (result.length > 0) {
                result += ", ";
            }
            result += formatBoldIfDifferent(address.zip, originalAddress.zip);
        }
        if (address.state) {
            const localizedCountry = getLocalizedCountryCode(t, address.state);
            const originalCountry = getLocalizedCountryCode(t, originalAddress.state);
            if (!fn.isEmpty(localizedCountry)) {
                if (result.length > 0) {
                    result += ", ";
                }
                result += formatBoldIfDifferent(localizedCountry, originalCountry);
            }
        }
    }
    return result;
};
