import RequestError from "./RequestError";
import NotAuthenticatedError from "./NotAuthenticatedError";
import NotAuthorizedError from "./NotAuthorizedError";
import EmptyPreconditionFailedError from "./EmptyPreconditionFailedError";
import {
    ACCESS_DENIED,
    PRECONDITION_FAILED,
    BAD_REQUEST,
    doGet,
    doGetPlain,
    doGetWithHeader,
    doPost,
    doPut,
    doDelete,
    timeoutPromise,
    TOO_MANY_REQUESTS,
} from "./fetch";

export default {
    ACCESS_DENIED,
    PRECONDITION_FAILED,
    BAD_REQUEST,
    TOO_MANY_REQUESTS,
    RequestError,
    NotAuthenticatedError,
    NotAuthorizedError,
    doGet,
    doGetPlain,
    doGetWithHeader,
    doPost,
    doPut,
    doDelete,
    EmptyPreconditionFailedError,
    timeoutPromise,
};
