import React, { FC } from "react";

import { ContentBox } from "ui-library/layouts";

import { CompanyNameField } from "./fields";

export interface CompanyParticipantFormBoxProps {
    formName: string;
}

export const CompanyParticipantFormBox: FC<CompanyParticipantFormBoxProps> = ({ formName }) => (
    <ContentBox>
        <CompanyNameField formName={formName} />
    </ContentBox>
);
