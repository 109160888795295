import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Box, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    DatePicker,
    Field,
    FormWideDisabledProvider,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    required,
    SingleSelect,
} from "core/form";
import { FieldSize } from "enums";

import { SectionTitle } from "../../components";
import { getTranslatedIdTypeOptions } from "../../selectors";

export interface IdentityCardTypeFormBoxProps {
    idCardDirty: boolean;
}

export const IdentityCardTypeFormBox: FC<IdentityCardTypeFormBoxProps> = ({ idCardDirty }) => {
    const { t } = i18n.useTranslation();
    const idTypeOptions = useSelector(getTranslatedIdTypeOptions);

    return (
        <FormWideDisabledProvider value={true}>
            <SectionTitle title={t("holder.identityCard")} />

            <GridLayout spacing={1}>
                <GridItem xs={3}>
                    <Field
                        name="identificationCard.identification"
                        component={SingleSelect}
                        label={t("holder.identityCard.typeShort")}
                        options={idTypeOptions}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                    />
                </GridItem>

                <GridItem xs={3}>
                    <Field
                        name="identificationCard.cardId"
                        component={InputText}
                        label={t("holder.identityCard.number")}
                        // validated async in saga!
                        normalize={[max20Characters]}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>

                <GridItem xs={3}>
                    <Field
                        name="identificationCard.releaseDate"
                        component={DatePicker}
                        label={t("common.dateFrom")}
                        validateStatic={idCardDirty ? [required] : []}
                        disabled={!idCardDirty}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                    />
                </GridItem>

                <GridItem xs={3}>
                    <Field
                        name="identificationCard.dateOfExpiry"
                        component={DatePicker}
                        label={t("common.dateTo")}
                        validateStatic={idCardDirty ? [required] : []}
                        disabled={!idCardDirty}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                    />
                </GridItem>
            </GridLayout>

            <Box>
                <Field
                    name="identificationCard.authority"
                    component={InputText}
                    label={t("holder.identityCard.authority")}
                    validateStatic={idCardDirty ? [required] : []}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    disabled={!idCardDirty}
                    size={FieldSize.SMALL}
                    colourWhenChanged
                    displayErrorAsPopover
                />
            </Box>
        </FormWideDisabledProvider>
    );
};
