import { TFunction } from "core/i18n";
import { ChangesWithFormComponentDefinition, ContractChangeType } from "types";
import {
    ActiveOperationsForm,
    BeneficiariesForm,
    ContractNameForm,
    ElectronicCommunicationForm,
    ParticipantForm,
    ParticipantPaymentsForm,
} from "./form";

export const changesDefinition = (
    t: TFunction,
    isAoEnforcement: boolean,
    isActiveOperationsSet: boolean,
): ChangesWithFormComponentDefinition => {
    const authenticatedChangeOptions = [
        {
            code: ContractChangeType.CONTRACT_NAME,
            value: t("changeType.CONTRACT_NAME"),
            formComponent: ContractNameForm,
        },
        {
            code: ContractChangeType.ELECTRONIC_COMMUNICATION,
            value: t("changeType.ELECTRONIC_COMMUNICATION"),
            formComponent: ElectronicCommunicationForm,
        },
        {
            code: ContractChangeType.PARTICIPANT,
            value: t("changeType.PARTICIPANT"),
            formComponent: ParticipantForm,
        },
        {
            code: ContractChangeType.BENEFICIARIES,
            value: t("changeType.BENEFICIARIES"),
            formComponent: BeneficiariesForm,
        },
        {
            code: ContractChangeType.PARTICIPANT_PAYMENTS,
            value: t("changeType.PARTICIPANT_PAYMENTS"),
            formComponent: ParticipantPaymentsForm,
        },
    ];

    // Active operations change option is available by usage of feature flag.
    if (isAoEnforcement) {
        authenticatedChangeOptions.push({
            code: ContractChangeType.CONTRACT_ACTIVE_OPERATIONS,
            value: t("changeType.CONTRACT_ACTIVE_OPERATIONS_SET"),
            formComponent: ActiveOperationsForm,
        });
    }

    const oneTimeChangeOptions = [];

    return {
        authenticatedChangeOptions,
        oneTimeChangeOptions,
    };
};
