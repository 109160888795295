import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Box } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { formatPercentage } from "core/formatters";
import { model } from "core/util";
import { PensionInvestmentStrategiesChange } from "model/changePropositions";

export const PensionInvestmentStrategiesChangeDetail = ({ pensionInvestmentStrategiesChange }) => {
    const { t } = i18n.useTranslation();
    const locale = 18n.getLanguageCode();

    return (
        <>
            <Box mb={1}>
                <Typography component="p" variant="b">
                    {t("changeProposition.transactionDetail")}
                </Typography>
            </Box>
            <Box>
                <Typography component="p">{t("changeProposition.pensionInvestmentStrategyChangeList")}</Typography>

                {pensionInvestmentStrategiesChange.newInvestmentStrategyFunds.map((newInvestmentStrategyFund) => (
                    <LabelTextItem
                        key={newInvestmentStrategyFund.fund.code}
                        fontVariant="caption"
                        text={`${model.getLocaleCodeName(newInvestmentStrategyFund.fund, locale)} - ${formatPercentage(
                            newInvestmentStrategyFund.percentage,
                        )}`}
                    />
                ))}
            </Box>
        </>
    );
};

PensionInvestmentStrategiesChangeDetail.propTypes = {
    pensionInvestmentStrategiesChange: PropTypes.instanceOf(PensionInvestmentStrategiesChange).isRequired,
    locale: PropTypes.string.isRequired,
};
