import { Roles } from "enums";
import { ChangesDefinition, ChangesWithFormComponentDefinition, ChangeType, ChangeWithFormComponentType } from "types";

import { useHasRole } from "../user/useHasRole";

export function useGetUserChangeTypesWithFormComponent(
    changeDefinition: ChangesWithFormComponentDefinition,
): ChangeWithFormComponentType[] {
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    return isAuthenticated ? changeDefinition.authenticatedChangeOptions : changeDefinition.oneTimeChangeOptions;
}
