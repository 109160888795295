export enum AddressTypeCodeEnum {
    PER = "PER",
    CON = "CON",
}

export enum CountryEnum {
    AD = "AD",
    AE = "AE",
    AF = "AF",
    AG = "AG",
    AI = "AI",
    AL = "AL",
    AM = "AM",
    AO = "AO",
    AQ = "AQ",
    AR = "AR",
    AS = "AS",
    AT = "AT",
    AU = "AU",
    AW = "AW",
    AX = "AX",
    AZ = "AZ",
    BA = "BA",
    BB = "BB",
    BD = "BD",
    BE = "BE",
    BF = "BF",
    BG = "BG",
    BH = "BH",
    BI = "BI",
    BJ = "BJ",
    BL = "BL",
    BM = "BM",
    BN = "BN",
    BO = "BO",
    BQ = "BQ",
    BR = "BR",
    BS = "BS",
    BT = "BT",
    BV = "BV",
    BW = "BW",
    BY = "BY",
    BZ = "BZ",
    CA = "CA",
    CC = "CC",
    CD = "CD",
    CF = "CF",
    CG = "CG",
    CI = "CI",
    CK = "CK",
    CL = "CL",
    CM = "CM",
    CN = "CN",
    CO = "CO",
    CR = "CR",
    CU = "CU",
    CV = "CV",
    CW = "CW",
    CX = "CX",
    CY = "CY",
    CZ = "CZ",
    DE = "DE",
    DJ = "DJ",
    DK = "DK",
    DM = "DM",
    DO = "DO",
    DZ = "DZ",
    EC = "EC",
    EE = "EE",
    EG = "EG",
    EH = "EH",
    ER = "ER",
    ES = "ES",
    ET = "ET",
    FI = "FI",
    FJ = "FJ",
    FK = "FK",
    FM = "FM",
    FO = "FO",
    FR = "FR",
    GA = "GA",
    GB = "GB",
    GD = "GD",
    GE = "GE",
    GF = "GF",
    GG = "GG",
    GH = "GH",
    GI = "GI",
    GL = "GL",
    GM = "GM",
    GN = "GN",
    GP = "GP",
    GQ = "GQ",
    GR = "GR",
    GS = "GS",
    GT = "GT",
    GU = "GU",
    GW = "GW",
    GY = "GY",
    HK = "HK",
    HM = "HM",
    HN = "HN",
    HR = "HR",
    HT = "HT",
    HU = "HU",
    CH = "CH",
    ID = "ID",
    IE = "IE",
    IL = "IL",
    IM = "IM",
    IN = "IN",
    IO = "IO",
    IQ = "IQ",
    IR = "IR",
    IS = "IS",
    IT = "IT",
    JE = "JE",
    JM = "JM",
    JO = "JO",
    JP = "JP",
    KE = "KE",
    KG = "KG",
    KH = "KH",
    KI = "KI",
    KM = "KM",
    KN = "KN",
    KP = "KP",
    KR = "KR",
    KW = "KW",
    KY = "KY",
    KZ = "KZ",
    LA = "LA",
    LB = "LB",
    LC = "LC",
    LI = "LI",
    LK = "LK",
    LR = "LR",
    LS = "LS",
    LT = "LT",
    LU = "LU",
    LV = "LV",
    LY = "LY",
    MA = "MA",
    MC = "MC",
    MD = "MD",
    ME = "ME",
    MF = "MF",
    MG = "MG",
    MH = "MH",
    MK = "MK",
    ML = "ML",
    MM = "MM",
    MN = "MN",
    MO = "MO",
    MP = "MP",
    MQ = "MQ",
    MR = "MR",
    MS = "MS",
    MT = "MT",
    MU = "MU",
    MV = "MV",
    MW = "MW",
    MX = "MX",
    MY = "MY",
    MZ = "MZ",
    NA = "NA",
    NC = "NC",
    NE = "NE",
    NF = "NF",
    NG = "NG",
    NI = "NI",
    NL = "NL",
    NO = "NO",
    NP = "NP",
    NR = "NR",
    NU = "NU",
    NZ = "NZ",
    OM = "OM",
    PA = "PA",
    PE = "PE",
    PF = "PF",
    PG = "PG",
    PH = "PH",
    PK = "PK",
    PL = "PL",
    PM = "PM",
    PN = "PN",
    PR = "PR",
    PS = "PS",
    PT = "PT",
    PW = "PW",
    PY = "PY",
    QA = "QA",
    RE = "RE",
    RO = "RO",
    RS = "RS",
    RU = "RU",
    RW = "RW",
    SA = "SA",
    SB = "SB",
    SC = "SC",
    SD = "SD",
    SE = "SE",
    SG = "SG",
    SH = "SH",
    SI = "SI",
    SJ = "SJ",
    SK = "SK",
    SL = "SL",
    SM = "SM",
    SN = "SN",
    SO = "SO",
    SR = "SR",
    SS = "SS",
    ST = "ST",
    SV = "SV",
    SX = "SX",
    SY = "SY",
    SZ = "SZ",
    TC = "TC",
    TD = "TD",
    TF = "TF",
    TG = "TG",
    TH = "TH",
    TJ = "TJ",
    TK = "TK",
    TL = "TL",
    TM = "TM",
    TN = "TN",
    TO = "TO",
    TR = "TR",
    TT = "TT",
    TV = "TV",
    TW = "TW",
    TZ = "TZ",
    UA = "UA",
    UG = "UG",
    UM = "UM",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VA = "VA",
    VC = "VC",
    VE = "VE",
    VG = "VG",
    VI = "VI",
    VN = "VN",
    VU = "VU",
    WF = "WF",
    WS = "WS",
    YE = "YE",
    YT = "YT",
    ZA = "ZA",
    ZM = "ZM",
    ZW = "ZW",
}

export enum IdTypeEnum {
    OP = "OP",
    CP = "CP",
}

export enum LegalPersonalityCodeEnum {
    Fo = "FO",
    Po = "PO",
}

export enum PensionStatementPeriodicityEnum {
    QUARTERLY = "QUARTERLY",
    BIANNUALLY = "HALFYEARLY",
    ANNUALLY = "YEARLY",
}

export enum PaymentModeDtoCodeEnum {
    BankAccount = "BY_BANK_ACCOUNT",
    Cash = "BY_CASH",
    DirectDebit = "BY_DIRECT_DEBIT",
    PostalOrder = "BY_POSTAL_ORDER",
    Sipo = "BY_SIPO",
    Employment = "BY_EMPLOYMENT",
}

export enum PaymentTypeEnum {
    A = "A",
    P = "P",
}

export enum PeriodicityDtoCodeEnum {
    None = "NONE",
    Onetime = "ONETIME",
    Monthly = "MONTHLY",
    Quarterly = "QUARTERLY",
    Halfyearly = "HALFYEARLY",
    Yearly = "YEARLY",
}

export enum StatementPeriodicityEnum {
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    BIANNUALLY = "BIANNUALLY",
}

export enum FeatureFlag {
    INDIVIDUAL_PROFILE_ENABLED = "individual_profile_enabled",
    PENSION_DISPLAY_REDEEM = "pension_display_redeem",
    SK_SSO_LOGIN = "sk_sso_login",
    CZ_SSO_LOGIN = "cz_sso_login",
    CLAIM_REPORT_NEW = "claim_report_new",
    PAYMENT_CENTER_ONLINE_PAYMENTS = "payment_center_online_payments",
    DISPLAY_TOTAL_INSURANCE = "display_total_insurance",
    SHOW_ENDED_CONTRACTS_DETAIL = "show_ended_contracts_detail",
    SHOW_FUTURE_CONTRACTS_DETAIL = "show_future_contracts_detail",
    MUTUAL_FUNDS_ACCOUNT_OWNER_VERIFY = "mutual_funds_account_owner_verify",
    EDIT_COMPANY_NAME_SPECIAL_CHARACTERS = "edit_company_name_special_characters",
    ENABLE_DIGISIGN = "enable_digisign",
    ENABLE_SERVICE_HUB_SK = "enable_service_hub_sk",
    PENSION_DDS_ACTIVE_OPERATIONS_ENFORCEMENT = "pension_dds_active_operations_enforcement",
}

export enum GridItemVariant {
    INCLUDED,
    SEPARATED,
}

export enum ContractChangeType {
    VEHICLE_DATA_CHANGE = "vehicle_data_change",
    VEHICLE_SUBJECT_CHANGE = "vehicle_subject_change",
    CONTRACT_CANCELLATION = "contract_cancellation",
    INSURANCE_PAYMENTS = "insurance_payments",
    PARTICIPANT_PAYMENTS = "participant_payments",
    SAVER_PAYMENTS = "saver_payments",
    EMPLOYER_PAYMENTS = "employer_payments",
    TAX_OPTIMUM = "tax_optimum",
    CONTRACT_NAME = "contract_name",
    POLICY_HOLDER = "policy_holder",
    SHAREHOLDER = "shareholder",
    PARTICIPANT = "participant",
    SAVER = "saver",
    BENEFICIARIES = "beneficiaries",
    ELECTRONIC_COMMUNICATION = "electronic_communication",
    STATEMENTS_SETTINGS = "statements_settings",
    MUTUAL_FUNDS_SELL_SWITCH = "sell_switch",
    CONTRACT_ACTIVE_OPERATIONS = "contract_active_operations",
}

export enum VehicleCancellationReason {
    STOLEN_VEHICLE = "STOLEN_VEHICLE",
    BY_AGREEMENT = "BY_AGREEMENT",
    IN_TWO_MONTHS_AFTER_CONTRACT_CREATION = "IN_TWO_MONTHS_AFTER_CONTRACT_CREATION",
    THREE_MONTHS_AFTER_INSURANCE_CLAIM = "THREE_MONTHS_AFTER_INSURANCE_CLAIM",
    BY_THE_END_OF_THE_INSURANCE_PERIOD = "BY_THE_END_OF_THE_INSURANCE_PERIOD",
    REMOVED_FROM_REGISTER = "REMOVED_FROM_REGISTER",
    ONE_MONTH_AFTER_INSURANCE_CLAIM = "ONE_MONTH_AFTER_INSURANCE_CLAIM",
    INSURER_DEATH = "INSURER_DEATH",
    CHANGE_OF_OWNERSHIP = "CHANGE_OF_OWNERSHIP",
}

export enum ChangeSuccessTextType {
    CHANGE_PROCESSED,
    CHANGE_SUBMITTED_FOR_PROCESSING,
}

export enum SignatureType {
    PHYSICAL = "PHYSICAL",
    ELECTRONIC = "ELECTRONIC",
}

export enum ChangeStatus {
    CREATED = "CREATED",
    SUBMITTED = "SUBMITTED",
}

export enum CampaignsDataStatusEnum {
    OK = "OK",
    UPDATED = "UPDATED",
    ERROR = "ERROR",
}

export enum FundsTransferType {
    INSURANCE_PAYMENT_BANK_ACCOUNT = "INSURANCE_PAYMENT_BANK_ACCOUNT",
    OTHER_BANK_ACCOUNT = "OTHER_BANK_ACCOUNT",
}

export enum DataLoadingsStatusEnum {
    LOADING = "LOADING",
    LOADED = "LOADED",
    ERROR = "ERROR",
    ACCESS_DENIED = "ACCESS_DENIED",
}
