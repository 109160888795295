import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Loading, Typography } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { FileInfo, InfoPanel, LabelTextItem } from "ui-library/modules";

import fetching from "core/fetching";
import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";
import { fn } from "core/util";
import { documentsApi } from "serverApi";

import { getGreenCardHeaderDataList, getInsuredVehicleWithMtpl } from "../selectors";
import { ENTITY_GREEN_CARD_DATA } from "../constants";

const getValidTo = (greenCardHeaderDataList, vinNumber) => {
    const headerData = greenCardHeaderDataList[vinNumber];
    if (fn.isEmpty(headerData)) {
        return "...";
    }
    return dateTimeToLocalFullDate(headerData.validTo);
};

const hasHeaderData = (greenCardHeaderDataList, vinNumber) => !fn.isEmpty(greenCardHeaderDataList[vinNumber]);

const getIsAnyAvailable = (insuredVehiclesWithMtlp, greenCardHeaderDataList) =>
    insuredVehiclesWithMtlp.filter((vehicle) => vehicle.vinNumber && hasHeaderData(greenCardHeaderDataList, vehicle.vinNumber)).size > 0;

export const GreenCardGridItem = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();

    const downloadGreenCardUrl = (vinNumber) => documentsApi.getGreenCardDownloadUrl(idObject, vinNumber);

    const insuredVehiclesWithMtlp = useSelector(getInsuredVehicleWithMtpl);
    const greenCardHeaderDataList = useSelector(getGreenCardHeaderDataList);

    const isAnyLoading = useSelector(fetching.isAnyInMapFetching(ENTITY_GREEN_CARD_DATA));
    const isAnyAvailable = getIsAnyAvailable(insuredVehiclesWithMtlp, greenCardHeaderDataList);

    return (
        <GridItem xs={12}>
            <InfoPanel icon={"paper-bill"} title={t("vehicle.greenCard")}>
                <Loading loading={isAnyLoading}>
                    {isAnyAvailable ? (
                        insuredVehiclesWithMtlp.map(
                            (insuredVehicle) =>
                                hasHeaderData(greenCardHeaderDataList, insuredVehicle.vinNumber) && (
                                    <Fragment key={insuredVehicle.vinNumber}>
                                        <Box mt={2}>
                                            <LabelTextItem
                                                label={t("vehicle.insuredVehicle")}
                                                text={
                                                    !fn.isEmpty(insuredVehicle.licencePlateNumber)
                                                        ? insuredVehicle.licencePlateNumber
                                                        : insuredVehicle.vinNumber
                                                }
                                            />
                                            <LabelTextItem
                                                label={t("vehicle.greenCardValidity")}
                                                text={getValidTo(greenCardHeaderDataList, insuredVehicle.vinNumber)}
                                            />
                                        </Box>
                                        <FileInfo
                                            className="download-greencard"
                                            icon="download"
                                            fileName={t("common.downloadPdf")}
                                            onClick={() => window.open(downloadGreenCardUrl(insuredVehicle.vinNumber))}
                                        />
                                    </Fragment>
                                ),
                        )
                    ) : (
                        <Typography markDown={t("common.noData")} />
                    )}
                </Loading>
            </InfoPanel>
        </GridItem>
    );
};
