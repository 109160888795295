import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Box, Flexbox } from "ui-library/layouts";
import { Icon, Typography } from "ui-library/atoms";

import i18n, { TFunction } from "core/i18n";
import { oneLineNameWithTitles } from "core/formatters";
import { LegalEntity as LegalEntityType } from "types";

import { getIsAnyClientDataValueChanged } from "../selectors";

const getClientTitle = (t: TFunction, clientData: LegalEntityType) =>
    `${oneLineNameWithTitles(clientData)} (${
        clientData.isPerson
            ? `${t("common.birthNumberShort")}: ${clientData.birthNumber}`
            : `${t("common.companyIdShort")}: ${clientData.companyID}`
    })`;

export interface SectionTitleWithAlertProps {
    formName: string;
    clientData: LegalEntityType;
}

export const SectionTitleWithAlert: FC<SectionTitleWithAlertProps> = ({ formName, clientData }) => {
    const { t } = i18n.useTranslation();
    const isAnyClientDataValueChanged = useSelector(getIsAnyClientDataValueChanged(formName));

    return (
        <Flexbox justifyContent={"space-between"} style={{ height: "27px" }}>
            <Typography variant="h4" markDown={getClientTitle(t, clientData)} />
            {isAnyClientDataValueChanged && (
                <Flexbox>
                    <Box mt={0.2} mr={0.5}>
                        <Icon className="tooltip" size={16} icon={"alert"} color="orange" />
                    </Box>
                    <Typography variant="h4" color="orange" markDown={t("form.clientDataOnContracts.dataChangeAlert")} />
                </Flexbox>
            )}
        </Flexbox>
    );
};
