import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { FormWideDisabledProvider } from "core/form";

import { DdsStrategySection, DdsTransferSection } from "../sections";

const DdsFundsChangeComponent = ({ formName, isOtpVisible, actualStrategies }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox mb={4}>
                <Typography component="p" markDown={t("pension.fundsChange.text")} />
            </ContentBox>

            <FormWideDisabledProvider value={isOtpVisible}>
                <DdsTransferSection formName={formName} />
                <DdsStrategySection formName={formName} actualStrategies={actualStrategies} />
            </FormWideDisabledProvider>
        </>
    );
};

DdsFundsChangeComponent.propTypes = {
    formName: PropTypes.string.isRequired,
    isOtpVisible: PropTypes.bool.isRequired,
    actualStrategies: PropTypes.any.isRequired,
};

export default DdsFundsChangeComponent;
