import React, { FC, PropsWithChildren } from "react";

import { ContractCard } from "ui-library/modules/ContractCard/ContractCard";
import { Typography } from "ui-library/atoms";
import { ViewVariant } from "ui-library/modules/enums";

import i18n, { TFunction } from "core/i18n";
import userContracts from "core/userContracts";
import products from "core/products";
import pageContext from "core/pageContext";
import { useIsContractActiveOperationsEnabled } from "core/hooks";
import { ContractChangeType, ContractPolicy, FeatureFlag, ProductLine } from "types";

import { ContractCardActionButtons, DesktopTabletContractCard, MobileContractCard } from "../components";
import { ContractListContentType } from "../constants";
import { getDetailLink, getEditLink, getQuickAccessLinks } from "../util";

const getAlertMessage = (t: TFunction, contract: ContractPolicy, productDefinition: ProductLine) => {
    const isActiveOperationsEnabled = useIsContractActiveOperationsEnabled(contract.idObject);

    if (!isActiveOperationsEnabled) {
        const link = getEditLink(contract, productDefinition, ContractChangeType.CONTRACT_ACTIVE_OPERATIONS);
        return t("pension.activeOperations.restrictionMessageShort", { link });
    }
    return null;
};

export interface ContractBoxProps extends PropsWithChildren {
    idObject: number;
    viewType: ViewVariant;
    displayedContentType: any;
    isMobileView?: boolean;
}

export const ContractBox: FC<ContractBoxProps> = ({ idObject, viewType, displayedContentType, isMobileView }) => {
    const { t } = i18n.useTranslation();

    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated, isFutureContract } = userContracts.useGetContractDerivedData(contract);

    const productDefinition = products.useGetProductDefinition(contract?.idEnuProductGroupLevelTwo);

    const isAoEnforcement = pageContext.useIsFeatureFlagEnabled(FeatureFlag.PENSION_DDS_ACTIVE_OPERATIONS_ENFORCEMENT);
    const isDisplayEndedContractsEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.SHOW_ENDED_CONTRACTS_DETAIL);
    const isDisplayFutureContractsEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.SHOW_FUTURE_CONTRACTS_DETAIL);

    // Hide all unspecified idEnuProductGroupLevelTwo to not crash app. BE should log this situation.
    if (!productDefinition) {
        return null;
    }

    const detailLink = getDetailLink(productDefinition, contract);
    const quickAccessLinks = getQuickAccessLinks(productDefinition, contract);
    const displayingActiveContracts = displayedContentType === ContractListContentType.ACTIVE_CONTRACTS;
    const alertMessage = isAoEnforcement ? getAlertMessage(t, contract, productDefinition) : null;

    const getActionComponent = () => {
        if (displayingActiveContracts) {
            if (isFutureContract) {
                // todo: temporarily disabled https://uniqa-czsk.atlassian.net/browse/PORTALS-164
                // if (isDisplayFutureContractsEnabled) {
                //     return <ContractCardActionButtons detailLink={detailLink} quickAccessLinks={quickAccessLinks} />;
                // } else {
                return <Typography sx={{ width: "350px" }}>{t("contractList.futureContractInfoText")}</Typography>;
                // }
            } else {
                return <ContractCardActionButtons detailLink={detailLink} quickAccessLinks={quickAccessLinks} />;
            }
        } else if (isDisplayEndedContractsEnabled) {
            return <ContractCardActionButtons detailLink={detailLink} />;
        } else {
            return null;
        }
    };

    return (
        <ContractCard
            actionStretch
            contractNumber={contract.contractNumber}
            droppable={isMobileView}
            variant={viewType}
            title={t(productDefinition.msg)}
            description={contract.productCRM}
            chipLabel={contract.contractName || t("general.text.customContractNameInfo")}
            imageUrl={productDefinition.image}
            imageDesatured={isInFutureOrTerminated}
            action={getActionComponent()}
            alertMessage={alertMessage}
        >
            {isMobileView ? (
                <MobileContractCard contract={contract} />
            ) : (
                <DesktopTabletContractCard contract={contract} alertMessage={alertMessage} />
            )}
        </ContractCard>
    );
};
