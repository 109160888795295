import React, { FC } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { theme } from "ui-library/theme/theme";

import { sentry } from "core/util";
import { MainLayout } from "app/layout/MainLayout";
import { AppSnackbarProvider } from "app/layout/snackbar";
import LocaleProvider from "app/layout/helpers/LocaleProvider";

const App: FC = () => {
    sentry.init();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <LocaleProvider>
                        <AppSnackbarProvider>
                            <MainLayout />
                        </AppSnackbarProvider>
                    </LocaleProvider>
                </CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
