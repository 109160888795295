import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { DataLoadingsStatusEnum, LegalEntityV2 as LegalEntityV2Type } from "types";

import { NAME } from "./constants";

export const setClientData = app.createActionWithPayload<LegalEntityV2Type>(`${NAME}/SET_CLIENT_DATA`);
export const setClientDataState = app.createActionWithPayload<DataLoadingsStatusEnum>(`${NAME}/SET_CLIENT_DATA_STATE`);

export const clear = createAction(`${NAME}/CLEAR`);
