import contactPage from "app/pages/contact";
import loginPage from "app/pages/login";
import registrationPage from "app/pages/registration";
import resetPasswordPage from "app/pages/resetPassword";
import contractListPage from "app/pages/contractList";
import detailVehiclePage from "./pages/detailVehicle";
import detailHomePage from "./pages/detailHome";
import paymentPage from "./pages/payment";
import paymentNotificationPage from "./pages/onlinePaymentNotification";
import detailPensionPpPage from "./pages/detailPp";
import detailPensionDpsPage from "./pages/detailDps";
import detailPensionDssPage from "./pages/detailDss";
import detailPensionDdsPage from "./pages/detailDds";
import detailLifePage from "./pages/detailLife";
import detailMutualFundsDirectPage from "./pages/detailMutualFundsDirect";
import detailMutualFundsRegularPage from "./pages/detailMutualFundsRegular";
import detailTravel from "./pages/detailTravel";
import claimsPage from "./pages/claims";
import detailIncident from "./pages/detailIncident";
import impersonatePage from "./pages/impersonate";
import messagesPage from "./pages/messages";
import disclaimerPage from "./pages/disclaimer";
import userSettings from "./pages/userSettings";
import epinExternalPage from "./pages/epinExternal";
import notificationPage from "./pages/notification";
import callCenterPage from "./pages/callCenter";
import crmPage from "./pages/crm";
import garagePage from "app/pages/garage";
import useCase from "app/pages/useCase";
import campaignPage from "./pages/campaign";

import { pageRoutes } from "routeUrls";
import { Pages } from "routeConstants";

export type PageModule = {
    name: string;
    topRouteUrl: string;
    pageDefinition: PageDefinition;
    innerRoutes: { [tab: string]: string };
};

export interface PageDefinition {
    NAME: string;
    innerRoutes?: { [tab: string]: string };
    reducer?: any;
    saga?: any;
}

const setPage = (name: string, pageDefinition: PageDefinition): PageModule => ({
    name,
    topRouteUrl: pageRoutes[name],
    pageDefinition: pageDefinition,
    innerRoutes: pageDefinition.innerRoutes ? pageDefinition.innerRoutes : null,
});

export const activePages = {
    [Pages.CONTACT]: setPage(Pages.CONTACT, contactPage),
    [Pages.LOGIN]: setPage(Pages.LOGIN, loginPage),
    [Pages.RESET_PASSWORD]: setPage(Pages.RESET_PASSWORD, resetPasswordPage),
    [Pages.DISCLAIMER]: setPage(Pages.DISCLAIMER, disclaimerPage),
    [Pages.IMPERSONATE]: setPage(Pages.IMPERSONATE, impersonatePage),
    [Pages.CONTRACTS]: setPage(Pages.CONTRACTS, contractListPage),
    [Pages.CONTRACT_VEHICLE]: setPage(Pages.CONTRACT_VEHICLE, detailVehiclePage),
    [Pages.CONTRACT_HOME]: setPage(Pages.CONTRACT_HOME, detailHomePage),
    [Pages.CONTRACT_LIFE]: setPage(Pages.CONTRACT_LIFE, detailLifePage),
    [Pages.CONTRACT_PP]: setPage(Pages.CONTRACT_PP, detailPensionPpPage),
    [Pages.CONTRACT_DPS]: setPage(Pages.CONTRACT_DPS, detailPensionDpsPage),
    [Pages.CONTRACT_DSS]: setPage(Pages.CONTRACT_DSS, detailPensionDssPage),
    [Pages.CONTRACT_DDS]: setPage(Pages.CONTRACT_DDS, detailPensionDdsPage),
    [Pages.CONTRACT_DIRECT]: setPage(Pages.CONTRACT_DIRECT, detailMutualFundsDirectPage),
    [Pages.CONTRACT_REGULAR]: setPage(Pages.CONTRACT_REGULAR, detailMutualFundsRegularPage),
    [Pages.CONTRACT_TRAVEL]: setPage(Pages.CONTRACT_TRAVEL, detailTravel),
    [Pages.CLAIMS]: setPage(Pages.CLAIMS, claimsPage),
    [Pages.INCIDENT_DETAIL]: setPage(Pages.INCIDENT_DETAIL, detailIncident),
    [Pages.ONLINE_PAYMENT]: setPage(Pages.ONLINE_PAYMENT, paymentPage),
    [Pages.ONLINE_PAYMENT_NOTIFICATION]: setPage(Pages.ONLINE_PAYMENT_NOTIFICATION, paymentNotificationPage),
    [Pages.REGISTRATION]: setPage(Pages.REGISTRATION, registrationPage),
    [Pages.MESSAGES]: setPage(Pages.MESSAGES, messagesPage),
    [Pages.USER]: setPage(Pages.USER, userSettings),
    [Pages.EPIN_EXTERNAL]: setPage(Pages.EPIN_EXTERNAL, epinExternalPage),
    [Pages.NOTIFICATION]: setPage(Pages.NOTIFICATION, notificationPage),
    [Pages.CALL_CENTER]: setPage(Pages.CALL_CENTER, callCenterPage),
    [Pages.CRM]: setPage(Pages.CRM, crmPage),
    [Pages.GARAGE]: setPage(Pages.GARAGE, garagePage),
    [Pages.USE_CASE]: setPage(Pages.USE_CASE, useCase),
    [Pages.CAMPAIGN]: setPage(Pages.CAMPAIGN, campaignPage),
};

export default activePages;
