import superagent from "superagent";
import { isImmutable } from "immutable";
import { CANCEL } from "redux-saga";
import { call } from "redux-saga/effects";

import fn from "../fn";
import RequestError from "./RequestError";
import NotAuthenticatedError from "./NotAuthenticatedError";
import NotAuthorizedError from "./NotAuthorizedError";

export const BAD_REQUEST = 400;
export const ACCESS_DENIED = 403;
export const PRECONDITION_FAILED = 412;
export const TOO_MANY_REQUESTS = 429;

const execute = (request) => {
    const promise = request.then(
        (response) => response.body,
        (error) => {
            if (error.status === 401) {
                throw new NotAuthenticatedError(error);
            } else if (error.status === 403) {
                throw new NotAuthorizedError(error);
            } else if (error.status) {
                // Apparently, superagent returns (json?) parse error here. Original response is in field "rawResponse".
                throw new RequestError(error, request.method !== "GET");
            } else {
                throw new RequestError(
                    { status: 500, response: { body: "some weird error here. See console output before this" } },
                    request.method !== "GET",
                    error.response?.headers?.identifier,
                );
            }
        },
    );
    promise[CANCEL] = () => request.abort();
    return promise;
};

const wrapExecute = function* (request, resultProcessMethod) {
    const result = yield call(execute, request);
    return resultProcessMethod(result);
};

export const doGet = function* (url, queryParams, resultProcessMethod = fn.identity) {
    const request = superagent.get(url).query(queryParams).accept("json");
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doGetPlain = function (url) {
    return superagent.get(url).then(
        (response) => response.text,
        (error) => {
            throw new RequestError(error, false);
        },
    );
};

export const doGetWithHeader = function* (
    url,
    queryParams,
    resultProcessMethod = fn.identity,
    requestHeaderName = undefined,
    requestHeaderValue = undefined,
) {
    const request = superagent.get(url).query(queryParams).set(requestHeaderName, requestHeaderValue).accept("json");
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doPut = function* (url, body, requestProcessMethod = fn.identity, resultProcessMethod = fn.identity, queryParams = {}) {
    const processedBody = requestProcessMethod(body);
    const request = superagent
        .put(url)
        .send(isImmutable(processedBody) ? processedBody.toJS() : processedBody)
        .query(queryParams)
        .type("json")
        .accept("json");
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doPost = function* (url, body, requestProcessMethod = fn.identity, resultProcessMethod = fn.identity, queryParams = {}) {
    const processedBody = requestProcessMethod(body);
    const request = superagent
        .post(url)
        .send(isImmutable(processedBody) ? processedBody.toJS() : processedBody)
        .query(queryParams)
        .type("json")
        .accept("json");
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doDelete = function* (url, resultProcessMethod = fn.identity) {
    const request = superagent.delete(url).accept("json");
    return yield* wrapExecute(request, resultProcessMethod);
};

/**
 * utility for mocking delayed apis.
 * export const myCall = () => fetch.timeoutPromise("ok", 2000);
 */
export const timeoutPromise = (result, timeout) =>
    new Promise((accept) => {
        setTimeout(() => {
            accept(result);
        }, timeout);
    });
