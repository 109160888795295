import { ACCEPTED_FILE_SIZE_BYTES, ACCEPTED_FILE_TYPES } from "core/form";
import { List } from "immutable";
import { useState } from "react";
import { Accept } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { Dropzone } from "ui-library/modules";

type Props = {
    name: string;
    onChange?: (v?: any) => void;
    disabled?: boolean;
    maxUploadedFiles?: number;
    acceptedTypes?: Accept;
    displayAsButton?: boolean;
    acceptedFileSize?: number;
};

export default function HookFormFileUpload({
    name,
    onChange,
    disabled,
    maxUploadedFiles = 1,
    acceptedTypes = ACCEPTED_FILE_TYPES,
    displayAsButton = false,
    acceptedFileSize = ACCEPTED_FILE_SIZE_BYTES,
}: Props) {
    const { setValue, getValues } = useFormContext();
    const initialValue = getValues(name);
    const [files, setFiles] = useState(Array.isArray(initialValue) ? List(initialValue) : List());

    function onUpload(files: List<any>) {
        setFiles(files);
        setValue(name, files.toArray());
        onChange?.(files.toArray());
    }

    return (
        <Dropzone
            value={files}
            onChange={onUpload}
            disabled={disabled}
            maxUploadedFiles={maxUploadedFiles}
            acceptedTypes={acceptedTypes}
            acceptedFileSize={acceptedFileSize}
            displayAsButton={displayAsButton}
        />
    );
}
