export enum DataChangeType {
    ALL = "ALL",
    CHANGED = "CHANGED",
}

export enum SubmitType {
    CHANGE_CLIENT_DATA = "CHANGE_CLIENT_DATA",
    ENABLE_ON_ALL_CONTRACTS = "ENABLE_ON_ALL_CONTRACTS",
    ENABLE_ON_SELECTED_CONTRACTS = "ENABLE_ON_SELECTED_CONTRACTS",
    DISABLE_ON_ALL_CONTRACTS = "DISABLE_ON_ALL_CONTRACTS",
    DISABLE_ON_SELECTED_CONTRACTS = "DISABLE_ON_SELECTED_CONTRACTS",
}
