import React, { FC, useState } from "react";
import { InputAdornment, StandardTextFieldProps } from "@mui/material";

import { StyledTextField } from "ui-library/Styled";
import { ErrorContent, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";

import { Label } from "./Label";

export interface TextFieldProps extends StandardTextFieldProps {
    id: string;
    label?: string;
    labelOptional?: string;
    tooltip?: string;
    error?: boolean;
    errorContent?: string;
    labelDesc?: string;
    prefix?: string;
    sufix?: string;
    multiline?: boolean;
    isActivationCode?: boolean;
    rows?: number;
    reference?: React.ForwardedRef<any>;
    isValueChanged: boolean;
    colourWhenChanged?: boolean;
    displayErrorAsPopover?: boolean;
    size: "small" | "medium";
}

export const TextField: FC<TextFieldProps> = ({
    labelDesc,
    labelOptional,
    errorContent,
    id,
    label,
    tooltip,
    error,
    prefix,
    sufix,
    multiline,
    rows,
    size = "medium",
    isActivationCode,
    reference,
    isValueChanged,
    colourWhenChanged = false,
    displayErrorAsPopover = false,
    ...props
}) => {
    const [showPopoverError, setShowPopoverError] = useState(false);

    const onFieldClick = () => {
        if (displayErrorAsPopover && error) {
            setShowPopoverError(true);
        }
    };

    const handleBlur = (event) => {
        if (!displayErrorAsPopover || (event.relatedTarget && event.relatedTarget.id === "popup")) {
            return;
        }
        setShowPopoverError(false);
    };

    return (
        <div style={displayErrorAsPopover ? { position: "relative", display: "inline-block", width: "100%" } : {}}>
            <Box>
                {(label || tooltip || labelOptional) && (
                    <Flexbox alignItems={"flex-start"} gap={1}>
                        <Label label={label} id={id} labelOptional={labelOptional} fontSize={size === "small" ? 14 : null} />
                        <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
                    </Flexbox>
                )}
                {labelDesc && (
                    <Box mt={0.5} mb={1}>
                        <Typography color={"gray"} variant="caption">
                            {labelDesc}
                        </Typography>
                    </Box>
                )}
            </Box>

            <StyledTextField
                id={id}
                error={error}
                label={null}
                hiddenLabel={true}
                variant="outlined"
                multiline={multiline}
                rows={rows}
                $size={size}
                $colourWhenChanged={colourWhenChanged}
                sx={isActivationCode && { letterSpacing: "10px" }}
                InputProps={{
                    startAdornment: prefix && !multiline ? <InputAdornment position="start">{prefix}</InputAdornment> : "",
                    endAdornment: sufix && !multiline ? <InputAdornment position="end">{sufix}</InputAdornment> : "",
                }}
                className={colourWhenChanged && isValueChanged ? "value-changed" : ""}
                {...props}
                ref={reference}
                onClick={onFieldClick}
                onBlur={handleBlur}
            />

            {showPopoverError && error && (
                <div
                    id="popup"
                    tabIndex={-1}
                    style={{
                        position: "absolute",
                        top: "100%", // Position below the input
                        left: "0",
                        backgroundColor: "#f9f9f9",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "8px",
                        zIndex: "1000",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        marginTop: "8px", // Add some spacing below the input
                        width: "500px",
                    }}
                >
                    <ErrorContent content={errorContent} />
                </div>
            )}

            {!displayErrorAsPopover && error && <ErrorContent content={errorContent} />}
        </div>
    );
};
