import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Section } from "ui-library/layouts";

import i18n from "core/i18n";
import entity from "core/entity";
import { Field, ToggleableCheckboxGroup } from "core/form";
import { ContractMetadata } from "model/contract";
import { ContractMetadata as ContractMetadataType, DataLoadingsStatusEnum, LegalEntity as LegalEntityType } from "types";

import { SectionTitle } from "../../components";
import { SELECTED_CONTRACTS_IDS_FIELD } from "../../constants";

import { CheckboxLabelForContract } from "./CheckboxLabelForContract";
import { CheckboxToggleableContentForContract } from "./CheckboxToggleableContentForContract";

export interface ContractsPickerProps {
    contractsMetadataEntityName: string;
    contractsOverviewsEntityName: string;
    clientData: LegalEntityType;
}

export const ContractSelectionBox: FC<ContractsPickerProps> = ({
    contractsMetadataEntityName,
    contractsOverviewsEntityName,
    clientData,
}) => {
    const { t } = i18n.useTranslation();

    const contractsMetadata = useSelector(entity.getData(contractsMetadataEntityName, ContractMetadata.fromServerList()));
    const contractsOverviews = useSelector(entity.getData(contractsOverviewsEntityName, []));

    const items = contractsMetadata.map((contractMetadata: ContractMetadataType) => {
        const contractOverviewEntity = contractsOverviews[contractMetadata.idObject];
        const contractOverviewEntityState = contractOverviewEntity?.state || DataLoadingsStatusEnum.LOADING;

        return {
            id: contractMetadata.idObject,
            label: (
                <CheckboxLabelForContract
                    contractMetaData={contractMetadata}
                    contractOverview={contractOverviewEntity}
                    clientData={clientData}
                />
            ),
            isToggleVisible: contractOverviewEntityState !== DataLoadingsStatusEnum.ERROR,
            toggleableContent: (
                <CheckboxToggleableContentForContract
                    contractOverview={contractsOverviews[contractMetadata.idObject]}
                    clientData={clientData}
                />
            ),
            disabled:
                contractOverviewEntityState === DataLoadingsStatusEnum.LOADING ||
                contractOverviewEntityState === DataLoadingsStatusEnum.ERROR,
        };
    });

    return (
        <Section>
            <SectionTitle title={t("common.contracts")} />
            <Field
                name={SELECTED_CONTRACTS_IDS_FIELD}
                component={ToggleableCheckboxGroup}
                direction={"column"}
                items={items}
                displayCheckAll
                displayToggleAll
            />
        </Section>
    );
};
