export enum CompareState {
    SAME,
    DIFFERENT,
    CONTRACT_MISSING,
    CLIENT_MISSING,
    BOTH_MISSING,
}

export enum ElComState {
    SET,
    SET_DIFFERENT_EMAIL,
    UNSET,
}
