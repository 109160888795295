import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import pensionImage from "ui-library/assets/images/dochodok-pozadie.svg";
import { Container } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";

import fetching from "core/fetching";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import router from "core/router";
import userContracts from "core/userContracts";
import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import { AppSidemenuLink } from "containers/link";
import { PageBoundary } from "containers/PageBoundary";
import { Roles } from "enums";
import { Tabs } from "routeConstants";
import { FeatureFlag } from "types";

import { RestrictionMessage } from "./components";
import { ENTITY_FINANCIAL_STATEMENT } from "./constants";
import { getIsActiveOperationsSet, getPensionAccountDetail, getPensionDdsFinancialStatementEntity } from "./selectors";

export const DetailPensionDdsPage: FC = () => {
    const { t } = i18n.useTranslation();
    const policy = userContracts.useGetContractByUrlParam();
    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const statement = useSelector(getPensionDdsFinancialStatementEntity);

    const activePageStatus = useSelector(router.getActivePageStatus);

    const isAoEnforcement = pageContext.useIsFeatureFlagEnabled(FeatureFlag.PENSION_DDS_ACTIVE_OPERATIONS_ENFORCEMENT);
    const isActiveOperationsSet = useSelector(getIsActiveOperationsSet);
    const isAoRestrictionDisplayed = !activePageStatus.isLoading && !activePageStatus.isError && isAoEnforcement && !isActiveOperationsSet;

    const pensionBeingPaidText = pensionAccountDetail.isPassive ? `, ${t("common.pensionBeingPaid")}` : "";
    const lobData = {
        label: t("account.amountTotal"),
        value: `${localAmountWithCurrencyLegacy(statement.getIn(["actualInvestmentsValue"], null))}${pensionBeingPaidText}`,
    };

    return (
        <ProductDetailPageLayout>
            <Container>
                {isAoRestrictionDisplayed && <RestrictionMessage />}

                <ContractHeader backgroundImage={pensionImage} policy={policy} lobData={lobData} loading={isStatementLoading} />

                <PageBoundary>
                    <SubPageWrapper>
                        <Sidemenu>
                            <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink
                                tab={Tabs.HOLDER}
                                title={t("menu.tab.holder.participant")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink tab={Tabs.DETAIL} title={t("menu.tab.detail")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.CHANGES} title={t("menu.tab.changes")} />
                            <AppSidemenuLink
                                tab={Tabs.FINANCIAL_OPERATIONS}
                                title={t("menu.tab.statementsAndPayments")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink
                                tab={Tabs.FINANCIAL_STATEMENT}
                                title={t("menu.tab.accountStatement")}
                                enabledFor={[Roles.AUTHENTICATED]}
                            />
                            <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} enabledFor={[Roles.AUTHENTICATED]} />
                            <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} enabledFor={[Roles.AUTHENTICATED]} />
                        </Sidemenu>

                        <Outlet />
                    </SubPageWrapper>
                </PageBoundary>
            </Container>
        </ProductDetailPageLayout>
    );
};
