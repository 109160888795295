import { Set } from "immutable";

import fundsChangeDdsForm from "form/fullPage/pensionFundsChange";

import { ENTITY_FINANCIAL_STATEMENT, ENTITY_FUNDS_CHANGE_PERMITTED, FORM_EDIT_FUNDS_CHANGE } from "../constants";
import { RestrictionMessage } from "../components";
import { OtpModule } from "../modules";
import { getFundsInvestmentStrategies, getIdObject, getIsActiveOperationsSet } from "../selectors";

export const FullPageFundsChangeForm = fundsChangeDdsForm.createContainerDds(
    FORM_EDIT_FUNDS_CHANGE,
    getIdObject,
    getIsActiveOperationsSet,
    getFundsInvestmentStrategies,
    OtpModule,
    RestrictionMessage,
    Set([ENTITY_FINANCIAL_STATEMENT]),
    ENTITY_FUNDS_CHANGE_PERMITTED,
);
