import { TFunction as i18nTFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { Locales } from "enums";

import {
    createAllOptionsSelectorForEnum,
    createGetFormattedDate,
    createGetLocalizedCodeOptions,
    createGetLocalizedEnum,
    createGetLocalizedEnumMap,
    createGetLocalizedEnumOptions,
    createGetLocalizedName,
    getDateFormat,
    createGetLocalizedNameWithCode,
    translateDirectly,
} from "./selectors";
import { NAME, DefaultVariables } from "./constants";
import instance from "./instance";

/**
 * INTERNATIONALIZATION MODULE

 * There is no support for switching yet, but it should be quite simple to add.
 *
 */
const translate = {
    NAME,
    defaultVariables: DefaultVariables,
    addTranslations: (language, translations) => instance.addResourceBundle(language, "translation", translations, true, false),
    useTranslation,
    setDefaultInterpolationVariables: (defaultVariables: object) => (instance.options.interpolation.defaultVariables = defaultVariables),
    changeLanguage: (lng) => new Promise((resolve) => instance.changeLanguage(lng, resolve)),
    getPlainLanguage: () => instance.language,
    getLanguageCode: () => instance?.language?.toUpperCase() || Locales.cs_CZ.languageCodeUpperCase,
    translateDirectly,
    createGetFormattedDate,
    createGetLocalizedEnumOptions,
    createGetLocalizedEnumMap,
    createGetLocalizedEnum,
    createGetLocalizedCodeOptions,
    createGetLocalizedName,
    createGetLocalizedNameWithCode,
    getDateFormat,
    createAllOptionsSelectorForEnum,
};

export default translate;

export type TFunction = i18nTFunction;
