import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Selector } from "@reduxjs/toolkit";
import { Box } from "@mui/material";

import { Container, Loading, SimpleAccordion } from "ui-library/atoms";
import { GridItem, GridLayout } from "ui-library/layouts";

import { form, getFormFieldValue } from "core/form";
import i18n from "core/i18n";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { LegalEntity as LegalEntityType, State } from "types";

import { SectionEditClientData, SectionEditElectronicCommunication, SectionTitleWithAlert } from "./containers";
import { ContractSelectionBox } from "./containers/contractSelection";
import ChangeSummary from "./containers/changeSummary/ChangeSummary";
import { LOADING_E_COM_VIRTUAL_FIELD } from "./constants";
import { AttachmentsFormBox } from "./containers/formBox";

import { ModalOtp } from "./components";

type EditFormProps = {
    canSubmit: boolean;
    handleSubmit: () => void;
    submitting: boolean;

    formName: string;

    getClientData: Selector<State, LegalEntityType>;
    contractsMetadataEntityName: string;
    contractsOverviewsEntityName: string;
};

const EditForm: FC<EditFormProps> = ({
    canSubmit,
    handleSubmit,
    submitting,
    formName,
    getClientData,
    contractsMetadataEntityName,
    contractsOverviewsEntityName,
}) => {
    const { t } = i18n.useTranslation();
    const clientData = useSelector(getClientData);
    const isElComBeingSubmitted = useSelector(getFormFieldValue(formName, LOADING_E_COM_VIRTUAL_FIELD));
    const isFormBeingSubmitted = submitting || isElComBeingSubmitted;

    const isAgent = useHasRole(Roles.AGENT);

    return (
        <Container maxWidth={false} sx={{ maxWidth: "1500px", mx: "auto" }}>
            <Loading loading={isFormBeingSubmitted} fullPage />

            <Box
                sx={{
                    display: "flex",
                    height: "100vh",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        flex: 4,
                        overflowY: "auto",
                        padding: "6px",
                        borderRight: "1px solid #ccc",
                    }}
                >
                    <SectionTitleWithAlert clientData={clientData} formName={formName} />

                    <SimpleAccordion
                        data={[
                            {
                                title: t("form.clientDataOnContracts.section.editClientData"),
                                children: (
                                    <SectionEditClientData
                                        formName={formName}
                                        clientData={clientData}
                                        handleSubmit={handleSubmit}
                                        isFormBeingSubmitted={isFormBeingSubmitted}
                                    />
                                ),
                            },
                            {
                                title: t("form.clientDataOnContracts.section.editElectronicCommunication"),
                                children: (
                                    <SectionEditElectronicCommunication
                                        formName={formName}
                                        isFormBeingSubmitted={isFormBeingSubmitted}
                                        contractsOverviewsEntityName={contractsOverviewsEntityName}
                                    />
                                ),
                            },
                        ]}
                        id={"edit-sections"}
                    />
                </Box>

                <Box
                    sx={{
                        flex: 4,
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: "6px",
                    }}
                >
                    <ContractSelectionBox
                        contractsMetadataEntityName={contractsMetadataEntityName}
                        contractsOverviewsEntityName={contractsOverviewsEntityName}
                        clientData={clientData}
                    />
                    <ChangeSummary formName={formName} clientData={clientData} />

                    {isAgent && <AttachmentsFormBox formName={formName} />}
                </Box>
            </Box>

            <ModalOtp formName={formName} isFormBeingSubmitted={isFormBeingSubmitted} handleSubmit={handleSubmit} />
        </Container>
    );
};

const createContainer = (
    formName: string,
    getClientData: Selector<State, LegalEntityType>,
    clientObjectsEntityName: string,
    objectDetailsEntityName: string,
) =>
    form(formName, {
        asyncValidationFields: ["mobile", "identificationCard.cardId"],
    })((props) => (
        <EditForm
            {...props}
            formName={formName}
            getClientData={getClientData}
            contractsMetadataEntityName={clientObjectsEntityName}
            contractsOverviewsEntityName={objectDetailsEntityName}
        />
    ));

export default createContainer;
