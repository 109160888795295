import superagent from "superagent";

import { fetch } from "core/util";
import to from "core/util/fetch/awaitToJs";
import { API_PREFIX } from "app/constants";
import { PensionDds } from "model/pension/dds";
import { PensionDssDdsFinancialOperation, PensionDssDdsFinancialStatement, PensionFundsChangePermitted } from "model/pension";

const productUriPrefix = `${API_PREFIX}/product/pension-dds`;
const getPensionDdsContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, PensionDds.fromServer);
const getPensionDdsFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, PensionDssDdsFinancialOperation.fromServerList);
const getPensionDdsFinancialStatement = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-statement`, null, PensionDssDdsFinancialStatement.fromServer);
const getIsPensionDdsFundsChangePermitted = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/funds-change-permitted`, null, PensionFundsChangePermitted.fromServer);

const fundsChange = (values) => fetch.doPut(`${productUriPrefix}/funds-change`, values);
const updateBeneficiaries = (values) => fetch.doPut(`${productUriPrefix}/update-beneficiaries`, values);

const updateActiveOperations = async (body) => {
    return await to(superagent.put(`${productUriPrefix}/active-operations`).send(body).set("Content-Type", "application/json"));
};

const PensionDdsApi = {
    getPensionDdsContractDetail,
    getPensionDdsFinancialOperations,
    getPensionDdsFinancialStatement,
    getIsPensionDdsFundsChangePermitted,
    fundsChange,
    updateBeneficiaries,
    updateActiveOperations,
};

export default PensionDdsApi;
