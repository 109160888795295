import React, { FC } from "react";

import { Button, Typography } from "ui-library/atoms";

export interface SubmitButtonProps {
    title: string;
    color?: string;
    onClick?: () => void;
    disabled?: boolean;
    additionalStyle?: object;
}

export const SubmitButton: FC<SubmitButtonProps> = ({ title, color = "green", onClick, disabled = false, additionalStyle = {} }) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            variant="contained"
            color={color}
            size="small"
            style={{ width: "100%", ...additionalStyle }}
        >
            <Typography fontSize={14} markDown={title} />
        </Button>
    );
};
