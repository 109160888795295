import { FC } from "react";

import i18n from "core/i18n";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_SAVER_PAYMENTS } from "../constants";

const SaverPaymentsFormContainer = contractOwnerPaymentsForm.createInPageContainer(FORM_EDIT_SAVER_PAYMENTS, "change.title.SAVER_PAYMENTS");

export const SaverPaymentsForm: FC = () => {
    const { t } = i18n.useTranslation();

    return <SaverPaymentsFormContainer showFrequency={false} showPaymentType={false} amountLabel={t("pension.dss.payments.amount")} />;
};
