import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

export interface BlockTitleProps {
    title: string;
}

export const BlockTitle: FC<BlockTitleProps> = ({ title }) => (
    <Box mr={4}>
        <Typography variant="h4">{title}</Typography>
    </Box>
);
