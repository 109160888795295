import { model } from "core/util";
import { PensionDdsAccountDetail as PensionDdsAccountDetailType, PensionDdsAccountDetailDto } from "types";

import { CodeValue, PaymentsScheduler } from "../../common";
import { PensionDssDdsFundInvestmentStrategy } from "../PensionDssDdsFundInvestmentStrategy";
import { PensionDdsActiveOperations } from "./PensionDdsActiveOperations";

export const PensionDdsAccountDetail = model.createModel<PensionDdsAccountDetailDto, PensionDdsAccountDetailType>((json) => ({
    paymentsScheduler: PaymentsScheduler.fromServer(json.PaymentsScheduler),
    batchPlan: CodeValue.fromServer(json.BatchPlan),
    pensionAccountEstablishmentDate: json.PensionAccountEstablishmentDate,
    isTransferredContract: json.IsTransferredContract,
    contractStatus: CodeValue.fromServer(json.ContractStatus),
    fundsInvestmentStrategies: PensionDssDdsFundInvestmentStrategy.fromServerList(json.FundsInvestmentStrategies),
    statementPassword: json.StatementPassword,
    isPassive: json.IsPassive,
    activeOperations: PensionDdsActiveOperations.fromServer(json.ActiveOperations),
}));
