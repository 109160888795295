import React, { FC, ReactNode, useEffect } from "react";
import { AccordionProps as MUIAccordionProps } from "@mui/material";

import { Icon } from "ui-library/atoms/Icons/Icon";
import { Typography } from "ui-library/atoms/Typography/Typography";
import { Button } from "ui-library/atoms/Button/Button";
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from "ui-library/Styled";
import { StyledSimpleAccordion, StyledSimpleAccordionSummary, StyledSimpleAccordionDetails } from "ui-library/Styled";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

export interface AccordionProps extends Omit<MUIAccordionProps, "children"> {
    id: string;
    data: {
        title: string | ReactNode;
        children: ReactNode;
    }[];
    deleteAction?: any;
    compact?: boolean;
    defaultLastExpanded?: boolean;
}

export const SimpleAccordion: FC<AccordionProps> = ({ id, deleteAction, compact, data, defaultLastExpanded, ...props }) => {
    const [expanded, setExpanded] = React.useState(`${id}-1`);
    const [expandedList, setExpandedList] = React.useState(data.map(({}, i) => `${id}${i}`));

    const [latestDataSize, setLatestDataSize] = React.useState(0);

    if (defaultLastExpanded && latestDataSize !== data?.length) {
        setLatestDataSize(data.length);
        setExpanded(id + (data?.length - 1));
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const hangeChangeList = (panel) => (event, isExpanded) => {
        expandedList.includes(panel)
            ? setExpandedList(expandedList.filter((item) => item !== panel))
            : setExpandedList([...expandedList, panel]);
    };

    return (
        <>
            {data
                ? data.map(({ title, children }, i) => (
                      // <StyledAccordion {...props} expanded={expanded === id + i} onChange={handleChange(id + i)} key={i}>
                      <StyledSimpleAccordion
                          {...props}
                          expanded={expandedList.includes(`${id}${i}`)}
                          onChange={hangeChangeList(`${id}${i}`)}
                          key={i}
                      >
                          <StyledSimpleAccordionSummary
                              key={i}
                              // expandIcon={
                              //     expanded === id + i ? (
                              //         <Icon size={12} icon="minus" color="gray" />
                              //     ) : (
                              //         <Icon size={12} icon="plus" color="gray" />
                              //     )
                              // }

                              expandIcon={
                                  <Icon size={12} icon={expandedList.includes(`${id}${i}`) ? "chevron-down" : "chevron-up"} color="gray" />
                              }
                              //      ? (
                              //
                              //     ) : (
                              //         <Icon size={12} icon="chevron-down" color="gray" />
                              //     )
                              // }
                              aria-controls={`${id}${i}`}
                              id={`${id}${i}`}
                              // className={compact && "header-compact"}
                          >
                              {React.isValidElement(title) ? <>{title}</> : <Typography variant="p">{title}</Typography>}
                              {deleteAction && (
                                  <Button
                                      onClick={() => deleteAction(i)}
                                      endIcon={"trash-can"}
                                      variant="text"
                                      rounded
                                      color="gray"
                                      size="small"
                                  />
                              )}
                          </StyledSimpleAccordionSummary>
                          <StyledSimpleAccordionDetails>
                              <Typography variant="p">{children}</Typography>
                          </StyledSimpleAccordionDetails>
                      </StyledSimpleAccordion>
                  ))
                : ""}
        </>
    );
};
