import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import type { Location } from "@remix-run/router";

import init from "core/init";
import router from "core/router";
import { Loading } from "ui-library/atoms";

import { useCanAccessLocation } from "core/hooks";
import { AppRoutes } from "routes";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { LoadingLayout } from "./LoadingLayout";
import { ModalFormChooseContractForChange } from "./modal";
import { snackbarUtils, WrongRolesMessage } from "./components";
import { PAGES_WITHOUT_HEADER_AND_FOOTER } from "../constants";
import { useSnackbar } from "notistack";

const getIsHeaderFooterVisible = (location: Location) => {
    const { pathname, search } = location;
    const pageInformation = router.parseLocation(pathname, search);
    return !PAGES_WITHOUT_HEADER_AND_FOOTER.includes(pageInformation?.name);
};

export const MainLayout: FC = () => {
    const snackbar = useSnackbar();

    useEffect(() => {
        snackbarUtils.setUseSnackbarRef(snackbar);
    }, [snackbar]);

    const initialized = useSelector(init.isInitialized);
    const location = useLocation();

    const isHeaderFooterVisible = getIsHeaderFooterVisible(location);
    const canAccessLocation = useCanAccessLocation(location);

    const applicationContent = canAccessLocation ? <AppRoutes /> : <WrongRolesMessage />;

    return (
        <>
            <Loading fullPage loading={!initialized} />
            {isHeaderFooterVisible && <Header />}

            {initialized ? applicationContent : <LoadingLayout />}

            {isHeaderFooterVisible && <Footer />}

            {/* Modals */}
            <ModalFormChooseContractForChange />
        </>
    );
};
