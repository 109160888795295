import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { ContractChangesTab } from "containers/contract";
import { useChangeTypeGetParamForm, useGetUserChangeTypesByCountry } from "core/hooks";
import { ContractChangeType, FeatureFlag } from "types";

import { ContractNameForm, ElectronicCommunicationForm, FullPagePolicyHolderForm, InsurancePaymentsForm } from "../form";
import VehicleDataChangeForm from "../form/VehicleDataChangeForm/VehicleDataChangeForm";
import VehicleSubjectChangeForm from "../form/VehicleSubjectChangeForm/VehicleSubjectChangeForm";
import ContractCancellationForm from "../form/ContractCancellationForm";
import { changesDefinition } from "../changesDefinition";

export function ChangesTab() {
    const { t } = i18n.useTranslation();
    const { form, changeType, onChange } = useChangeTypeGetParamForm();
    const isServiceHubSkEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.ENABLE_SERVICE_HUB_SK);
    const changeOptions = useGetUserChangeTypesByCountry(changesDefinition(t, isServiceHubSkEnabled));

    return (
        <ContractChangesTab form={form} changeOptions={changeOptions} onChange={onChange}>
            {changeType === ContractChangeType.VEHICLE_DATA_CHANGE && <VehicleDataChangeForm />}
            {changeType === ContractChangeType.VEHICLE_SUBJECT_CHANGE && <VehicleSubjectChangeForm />}
            {changeType === ContractChangeType.CONTRACT_CANCELLATION && <ContractCancellationForm />}
            {changeType === ContractChangeType.INSURANCE_PAYMENTS && <InsurancePaymentsForm />}
            {changeType === ContractChangeType.CONTRACT_NAME && <ContractNameForm />}
            {changeType === ContractChangeType.POLICY_HOLDER && <FullPagePolicyHolderForm />}
            {changeType === ContractChangeType.ELECTRONIC_COMMUNICATION && <ElectronicCommunicationForm />}
        </ContractChangesTab>
    );
}
