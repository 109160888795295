import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { DataTable } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import { dateTimeToLocalFullDate, formatLocalizedValueMap } from "core/formatters";

import { getChangeType } from "../util";

import { DetailLinkAction } from "./DetailLinkAction";

const columns = (t, locale, changePropositionPensionTypeMap, changePropositionTypeMap, changePropositionStatusMap, actions) => [
    {
        sortable: false,
        flex: 1.5,
        field: "creationDateTime",
        headerName: t("changeProposition.date"),
        renderCell: (params) => dateTimeToLocalFullDate(params.value),
    },
    {
        sortable: false,
        flex: 2,
        field: "data-type",
        headerName: t("changeProposition.type"),
        renderCell: (params) => formatLocalizedValueMap(params.row.changeProposition?.type, changePropositionTypeMap),
    },
    {
        sortable: false,
        flex: 2,
        field: "data-proposition",
        headerName: t("changeProposition.proposition"),
        renderCell: (params) => getChangeType(params.row, locale, changePropositionTypeMap, changePropositionPensionTypeMap),
    },
    {
        sortable: false,
        flex: 2,
        field: "status",
        headerName: t("changeProposition.state"),
        renderCell: (params) => formatLocalizedValueMap(params.value, changePropositionStatusMap),
    },
    {
        sortable: false,
        flex: 1,
        field: "id",
        headerName: t("changeProposition.detail"),
        renderCell: (params) => <DetailLinkAction request={params.row} actions={actions} />,
    },
];

export const ChangePropositionsGeneralTable = ({ selectors, actions }) => {
    const { t } = i18n.useTranslation();
    const locale = i18n.getLanguageCode();
    const changePropositionPensionTypeMap = useSelector(localized.getLocalizedChangePropositionPensionTypeMap);
    const changePropositionTypeMap = useSelector(localized.getLocalizedChangePropositionTypeMap);
    const changePropositionStatusMap = useSelector(localized.getLocalizedChangePropositionStatusMap);

    const data = useSelector(selectors.getChangePropositionsData);

    return (
        <DataTable
            withWrapper={false}
            disableColumnReorder
            headerHeight={60}
            rowHeight={55}
            getRowId={(row) => row.id}
            rows={data && data.toArray()}
            columns={columns(t, locale, changePropositionPensionTypeMap, changePropositionTypeMap, changePropositionStatusMap, actions)}
        />
    );
};

ChangePropositionsGeneralTable.propTypes = {
    actions: PropTypes.object.isRequired,
    selectors: PropTypes.object.isRequired,
};
