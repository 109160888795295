import { withTheme } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styled from "styled-components";

export const StyledSimpleAccordion = withTheme(styled(Accordion)`
    && {
        background: transparent;
        margin: 0;
        border-bottom: 1px solid ${(props) => props.theme.palette.gray.line};
        border-radius: 0;
        &:before {
            display: none;
        }
        &.Mui-expanded {
            margin: 0;
        }
        &:last-child {
            border-bottom: none;
        }
    }
`);

export const StyledSimpleAccordionSummary = withTheme(styled(AccordionSummary)`
    min-height: unset;
    margin: 0;
    padding: 0;

    color: ${(props) => props.theme.palette.blue.main};

    transition: all ease 200ms;

    &.Mui-expanded {
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        border-bottom: 1px solid ${(props) => props.theme.palette.blue.main};
        transition: all ease 200ms;
        min-height: unset;
    }

    .Mui-expanded {
        margin: 5px 0;
    }
    svg {
        margin: 0 18px;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            margin: 0 9px;
        }
    }
`);

export const StyledSimpleAccordionDetails = withTheme(styled(AccordionDetails)`
    margin: 0;
    padding: 0;
`);
