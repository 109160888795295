import React, { FC } from "react";

import { ContentBox } from "ui-library/layouts";

import { IndividualPersonFields } from "./fields";

export interface IndividualParticipantFormBoxProps {
    formName: string;
}

export const IndividualParticipantFormBox: FC<IndividualParticipantFormBoxProps> = ({ formName }) => (
    <ContentBox>
        <IndividualPersonFields formName={formName} />
    </ContentBox>
);
