import React, { useState } from "react";
import { Set } from "immutable";
import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";
import { Button } from "@mui/material";

import { ErrorContent, Icon, Label, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledCheckbox, StyledCheckboxGroup, StyledCheckboxLabel } from "ui-library/Styled";

import i18n from "core/i18n";

import { Consumer } from "../FormWideOnEnterContext";
import { getErrorsAsString } from "../util";

const ToggleableCheckboxGroup = ({
    input,
    meta,
    disabled,
    label,
    labelOptional,
    labelDesc,
    tooltip,
    direction,
    items,
    displayCheckAll,
    displayToggleAll,
    props,
}) => {
    const error = meta.touched && meta.error && !meta.error.isEmpty();
    const errorContent = getErrorsAsString(meta);

    const { t } = i18n.useTranslation();

    const allCheckboxesIds = items.filter((item) => !item.disabled).map((item) => item.id);
    const checkedCheckboxesIds = input.value || Set();
    const [toggleOpenCheckboxesIds, setToggleOpenCheckboxesIds] = useState(Set());

    // Toggle
    const onToggleClick = (contractId) => {
        toggleOpenCheckboxesIds.includes(contractId)
            ? setToggleOpenCheckboxesIds(toggleOpenCheckboxesIds.delete(contractId))
            : setToggleOpenCheckboxesIds(toggleOpenCheckboxesIds.add(contractId));
    };
    const onToggleAllClick = () => {
        if (allCheckboxesIds.length === toggleOpenCheckboxesIds.size) {
            setToggleOpenCheckboxesIds(Set());
        } else {
            setToggleOpenCheckboxesIds(Set(allCheckboxesIds));
        }
    };

    // Check
    const onCheckClick = (contractId) => {
        checkedCheckboxesIds.includes(contractId)
            ? input.onChange(checkedCheckboxesIds.delete(contractId))
            : input.onChange(checkedCheckboxesIds.add(contractId));
    };
    const onCheckAllClick = () => {
        if (allCheckboxesIds.length === checkedCheckboxesIds.size) {
            input.onChange(Set());
        } else {
            input.onChange(Set(allCheckboxesIds));
        }
    };

    return (
        <Consumer>
            {(formWideOnEnter) => (
                <div style={{ display: "block" }}>
                    <Box>
                        <Flexbox alignItems={"flex-start"} gap={1}>
                            <Label label={label} id="" labelOptional={labelOptional} />
                            <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
                        </Flexbox>
                        {labelDesc && (
                            <Box mt={0.5} mb={1}>
                                <Typography color={"gray"} variant="caption">
                                    {labelDesc}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    {(displayCheckAll || displayToggleAll) && (
                        <Flexbox flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1} mb={1}>
                            {displayCheckAll && (
                                <StyledCheckboxLabel
                                    control={
                                        <StyledCheckbox
                                            onChange={onCheckAllClick}
                                            checked={allCheckboxesIds.length > 0 && allCheckboxesIds.length === checkedCheckboxesIds.size}
                                        />
                                    }
                                    label={<Typography component="p" fontSize={14} markDown={t("common.selectAll")} />}
                                    disabled={allCheckboxesIds.length === 0}
                                />
                            )}
                            {displayToggleAll && (
                                <StyledCheckboxLabel
                                    style={{ flexFlow: "row-reverse", gap: "8px" }}
                                    control={
                                        <StyledCheckbox
                                            onChange={onToggleAllClick}
                                            checked={
                                                allCheckboxesIds.length > 0 && allCheckboxesIds.length === toggleOpenCheckboxesIds.size
                                            }
                                            disabled={allCheckboxesIds.length === 0}
                                        />
                                    }
                                    label={<Typography component="p" fontSize={14} markDown={t("common.collapseAll")} />}
                                />
                            )}
                        </Flexbox>
                    )}

                    <StyledCheckboxGroup direction={direction} {...props}>
                        {items.map((item) => (
                            <ToggleableCheckbox
                                hasGroup
                                key={item.id}
                                label={item.label}
                                disabled={disabled || item.disabled}
                                isToggleVisible={item.isToggleVisible}
                                onCheckClick={() => onCheckClick(item.id)}
                                isChecked={checkedCheckboxesIds.includes(item.id)}
                                toggleableContent={item.toggleableContent}
                                onToggleClick={() => onToggleClick(item.id)}
                                isToggleOpened={toggleOpenCheckboxesIds.includes(item.id)}
                            />
                        ))}
                    </StyledCheckboxGroup>
                    {error ? <ErrorContent content={errorContent} /> : ""}
                </div>
            )}
        </Consumer>
    );
};

ToggleableCheckboxGroup.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelOptional: PropTypes.string,
    labelDesc: PropTypes.string,
    displayCheckAll: PropTypes.bool,
    displayToggleAll: PropTypes.bool,
};

ToggleableCheckboxGroup.defaultProps = {
    disabled: false,
    label: "",
    labelOptional: "",
    labelDesc: "",
    displayCheckAll: false,
    displayToggleAll: false,
};

const ToggleableCheckbox = ({
    id,
    label,
    error,
    isChecked,
    isToggleOpened,
    onToggleClick,
    isToggleVisible,
    toggleableContent,
    onCheckClick,
    disabled,
}) => {
    const labelContent = (
        <Flexbox direction="column" gap={1}>
            {label}
            {isToggleOpened && toggleableContent}
        </Flexbox>
    );

    return (
        <div className="checkbox-wrapper">
            <Flexbox alignItems={"center"}>
                <StyledCheckboxLabel
                    className={isChecked && !error ? "checked" : "unchecked"}
                    control={<StyledCheckbox checked={isChecked} onChange={onCheckClick} error={error} id={id} />}
                    label={labelContent}
                    disabled={disabled}
                />
                {isToggleVisible && (
                    <Button onClick={onToggleClick} sx={{ height: "37px", maxWidth: "40px" }}>
                        <Icon size={16} icon={isToggleOpened ? "chevron-up" : "chevron-down"} />
                    </Button>
                )}
            </Flexbox>
        </div>
    );
};

export default ToggleableCheckboxGroup;
