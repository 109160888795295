import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useVehicleDataChangeForm } from "./hooks/useVehicleDataChangeForm";
import VehicleDataForm from "./components/VehicleDataForm";
import Documents from "./components/Documents";
import { Form, FormErrorBox } from "core/hookForm";
import i18n from "core/i18n";
import { Loading, Typography } from "ui-library/atoms";
import { Box, ContentBox } from "ui-library/layouts";
import Confirmation from "./components/Confirmation";
import vehicleDataChange from "serverApi/vehicleDataChange";

export default function VehicleDataChangeForm() {
    const { form, onSubmit, vehicles, step, previousStep, isFormLoading, isSubmitting, contractNumber } = useVehicleDataChangeForm();
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();

    const [isFeasible, setIsFeasible] = useState<boolean>(undefined);

    useEffect(() => {
        async function fetchIsFeasible() {
            try {
                const [error, res] = await vehicleDataChange.isFeasible(idObject);

                setIsFeasible(error ? false : res.body.isFeasible);
            } catch (err) {
                console.error(err);
            }
        }

        fetchIsFeasible();
    });

    if (isFeasible === true) {
        return (
            <Loading loading={isFormLoading}>
                <Form form={form} onSubmit={onSubmit}>
                    <Box mb={3} fullWidth>
                        <Typography variant="h3">
                            {t("vehicle.change.vehicleData.title")} {contractNumber}
                        </Typography>
                    </Box>

                    <FormErrorBox />

                    {step === 1 && <VehicleDataForm onSubmit={onSubmit} vehicles={vehicles} isSubmitting={isSubmitting} />}
                    {step === 2 && <Documents previousStep={previousStep} />}
                    {step === 3 && <Confirmation />}
                </Form>
            </Loading>
        );
    } else if (isFeasible === false) {
        return (
            <ContentBox mt={3}>
                <Typography variant={"b"}>{t("vehicle.change.notFeasible.edit")}</Typography>
            </ContentBox>
        );
    }

    return <span>...</span>;
}
