import router from "core/router";
import { ContractChangeType, ContractPolicy, ProductLine, ProductLineLink } from "types";
import { Tabs } from "routeConstants";

import { ContractCardActionButtonsQuickLink } from "./components/ContractCardActionButtons";
import { CHANGE_TYPE_PARAM_NAME } from "../../constants";

const ELCOM_LINK_MSG = "quicklink.elcom";

export const getQuickAccessLinks = (productDefinition: ProductLine, contract: ContractPolicy): ContractCardActionButtonsQuickLink[] =>
    productDefinition.links
        .filter((link) => keepElcomEditLink(link, contract.isUniqaContract))
        .filter((link) => keepEditLinkByCondition(link, contract))
        .map((link) => getQuickAccessLink(link, contract));

export const getDetailLink = (productDefinition: ProductLine, contract: ContractPolicy): string => {
    if (!productDefinition || !productDefinition.detailRoute || !contract) {
        return null;
    }
    return router.getStaticUrl(productDefinition.detailRoute[0], productDefinition.detailRoute[1], { idObject: contract.idObject });
};

export const getEditLink = (contract: ContractPolicy, productDefinition: ProductLine, changeType: ContractChangeType): string => {
    if (!productDefinition || !productDefinition.detailRoute || !contract) {
        return null;
    }
    return router.getStaticUrl(
        productDefinition.detailRoute[0],
        Tabs.CHANGES,
        { idObject: contract.idObject },
        { [CHANGE_TYPE_PARAM_NAME]: changeType },
    );
};

const keepElcomEditLink = (link: ProductLineLink, isUniqaContract: boolean): boolean =>
    !isUniqaContract || (isUniqaContract && link.msg !== ELCOM_LINK_MSG);

// dynamically creating links for dss depending on investmentStrategy value
const keepEditLinkByCondition = (link: ProductLineLink, contract: ContractPolicy): boolean =>
    !link.displayCondition || link.displayCondition === contract?.overview?.pensionInvestmentStrategy;

const getIdObjectParamObj = (contract: ContractPolicy) => ({ idObject: contract.idObject });

const getQuickAccessLink = (link: ProductLineLink, contract: ContractPolicy): ContractCardActionButtonsQuickLink => {
    const url = router.getStaticUrl(
        link.route[0],
        link.route[1],
        link.params ? { ...getIdObjectParamObj(contract), ...link.params } : getIdObjectParamObj(contract),
        link.getQuery ? link.getQuery(contract.idObject + "") : {},
    );
    return {
        msg: link.msg,
        url,
    };
};
