import { model } from "core/util";
import { LegalEntityV2 as LegalEntityV2Type } from "types";
import { AddressV2 } from "./AddressV2";
import { IdentityCardV2 } from "./IdentityCardV2";

export const LegalEntityV2 = model.createModel<LegalEntityV2Type, LegalEntityV2Type>((json) => ({
    idSubject: json.idSubject,
    countryCode: json.countryCode,
    description: json.description,

    firstName: json.firstName,
    lastName: json.lastName,
    birthLastName: json.birthLastName,
    titleBefore: json.titleBefore,
    titleAfter: json.titleAfter,
    sexCode: json.sexCode,

    isPerson: json.isPerson,

    companyName: json.companyName,
    companyID: json.companyID,

    birthDate: json.birthDate,
    birthPlace: json.birthPlace,
    birthNumber: json.birthNumber,

    citizenship: json.citizenship,
    email: json.email,
    phone: json.phone,

    ids: IdentityCardV2.fromServerList(json.ids),
    addresses: AddressV2.fromServerList(json.addresses),
    addressesIdentical: json.addressesIdentical,

    showPersonInContactAddressForm: json.showPersonInContactAddressForm,
}));
