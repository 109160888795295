import React, { FC } from "react";

import { IconWithPopover } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";

import i18n from "core/i18n";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { AddressType } from "enums";
import { ContractOverview, LegalEntity } from "types";

import { CompareState, ElComState } from "./types";
import {
    compareAddress,
    compareEmailPlain,
    comparePlain,
    getAddressByTypeCode,
    getElCommunicationState,
    getElComValueBasedOnState,
} from "./util";

const getColorByState = (compareState: CompareState) => {
    switch (compareState) {
        case CompareState.SAME:
            return "green";
        case CompareState.DIFFERENT:
            return "orange";
        case CompareState.CONTRACT_MISSING:
            return "red";
        case CompareState.BOTH_MISSING:
            return "gray";
        default:
            return "black";
    }
};

const getElComColorByState = (elComState: ElComState) => {
    switch (elComState) {
        case ElComState.SET:
            return "green";
        case ElComState.SET_DIFFERENT_EMAIL:
            return "orange";
        default:
            return "red";
    }
};

export interface CheckboxLabelOverviewContentForContractProps {
    contractOverview: ContractOverview;
    clientData: LegalEntity;
}

export const CheckboxLabelOverviewContentForContract: FC<CheckboxLabelOverviewContentForContractProps> = ({
    contractOverview,
    clientData,
}) => {
    const { t } = i18n.useTranslation();

    const contractOwner = contractOverview?.contractOwner;

    const contractPermanentAddress = getAddressByTypeCode(contractOwner?.addresses, AddressType.PER);
    const contractContactAddress = getAddressByTypeCode(contractOwner?.addresses, AddressType.CON);
    const clientDataPermanentAddress = getAddressByTypeCode(clientData?.addresses, AddressType.PER);
    const clientDataContactAddress = getAddressByTypeCode(clientData?.addresses, AddressType.CON);

    const elComState = getElCommunicationState(
        comparePlain(clientData.email, contractOwner.email),
        contractOverview.electronicCommunication,
    );

    return (
        <div style={{ width: "620px" }}>
            <Flexbox flexDirection={"row"} justifyContent={"space-between"} gap={1}>
                <Flexbox flexDirection={"row"} gap={1}>
                    <IconWithPopover
                        size={16}
                        icon={"address-card"}
                        color={getColorByState(compareAddress(clientDataPermanentAddress, contractPermanentAddress))}
                        popoverText={`${
                            clientData.isPerson ? t("holder.permanentAddress") : t("holder.companyAddress")
                        }: ${getLocalizedOneLineAddressFull(t, contractPermanentAddress)}`}
                        popoverLeft={"-10%"}
                    />
                    <IconWithPopover
                        size={16}
                        icon={"contract"}
                        color={getColorByState(compareAddress(clientDataContactAddress, contractContactAddress))}
                        popoverText={`${t("holder.contactAddress")}: ${getLocalizedOneLineAddressFull(t, contractContactAddress)}`}
                        popoverLeft={"-15%"}
                    />
                    <Box />
                    <IconWithPopover
                        size={16}
                        icon={"phone"}
                        color={getColorByState(comparePlain(clientData.phone, contractOwner.phone))}
                        popoverText={`${t("holder.phone")}: ${contractOwner.phone}`}
                    />
                    <IconWithPopover
                        size={16}
                        icon={"e-mail"}
                        color={getColorByState(compareEmailPlain(clientData.email, contractOwner.email))}
                        popoverText={`${t("holder.email")}: ${contractOwner.email}`}
                    />
                </Flexbox>

                <Flexbox flexDirection={"row"} gap={1}>
                    <IconWithPopover
                        size={16}
                        icon={"computer"}
                        color={getElComColorByState(elComState)}
                        popoverText={`${t("holder.eCommunication")}: ${getElComValueBasedOnState(t, elComState)}`}
                        popoverLeft={"-80%"}
                    />
                </Flexbox>
            </Flexbox>
        </div>
    );
};
