import { List } from "immutable";
import { KeyValue } from "model/common";
import createModel from "../createModel";

export const Incident = createModel("Incident", (json) => ({
    isUniqaClaim: json.isUniqaClaim,
    incidentId: json.incidentId,
    lineOfBusinesses: List(json.lineOfBusinesses),
    incidentCause: KeyValue.fromServer(json.incidentCause),
    insuranceRisk: KeyValue.fromServer(json.insuranceRisk),
    policyNumber: json.contractNumber,
    claimNumber: json.claimNumber,
    claimType: json.claimType,
    claimSubType: json.claimSubType,
    claimStatus: json.claimStatus,
    categoryClaim: json.categoryClaim,
    insuredFirstName: json.insuredFirstName,
    insuredLastName: json.insuredLastName,
    lastStateChange: json.lastStateChange,
    eventDate: json.eventDate,
    registrationDate: json.registrationDate,
    paid: json.paid,
    isClaimantOwnerContract: json.isClaimantOwnerContract,
    externalNo: json.externalNo,
}));
