import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import { dateTimeToLocalFullDate, formatIsEnabled } from "core/formatters";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import userContracts from "core/userContracts";

import { getIsActiveOperationsSet, getPensionAccountDetail } from "../selectors";
import { getBatchPlan } from "../util";

export const ContractDataGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const isActiveOperationsSet = useSelector(getIsActiveOperationsSet);
    const locale = useSelector(pageContext.getLocale);

    return (
        <GridItem display="flex" xs={12} md={6}>
            <InfoPanel icon="contract" title={t("common.contract")}>
                <ContentBox>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    {contract?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(contract.endDate)} />}
                    <LabelTextItem
                        label={t("pension.batchPlan")}
                        text={getBatchPlan(pensionAccountDetail.batchPlan, locale.languageCode, t("pension.byLaw"))}
                    />
                    <LabelTextItem label={t("pension.activeOperations")} text={formatIsEnabled(t, isActiveOperationsSet)} />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
