import React, { FC } from "react";

import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, FileUpload } from "core/form";
import { FieldSize } from "enums";

import { SectionTitle } from "../../components";
import { FILES_FIELD } from "../../constants";

export interface AttachmentsFormBoxProps {
    formName: string;
}

export const AttachmentsFormBox: FC<AttachmentsFormBoxProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <SectionTitle title={t("common.attachments")} />

            <ContentBox>
                <Field name={FILES_FIELD} component={FileUpload} size={FieldSize.SMALL} />
            </ContentBox>
        </Section>
    );
};
