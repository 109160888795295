import React, { FC, PropsWithChildren } from "react";
import { IconButton } from "@mui/material";
import { closeSnackbar, SnackbarProvider } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

export const AppSnackbarProvider: FC<PropsWithChildren> = ({ children }) => (
    <SnackbarProvider
        anchorOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        action={(snackbarId) => (
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(snackbarId)}>
                <CloseIcon fontSize="small" />
            </IconButton>
        )}
        persist
    >
        {children}
    </SnackbarProvider>
);
