import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import i18n from "core/i18n";
import { Field, getFormFieldValue, SegmentedControl } from "core/form";
import { FieldSize } from "enums";

import { ARE_ADDRESSES_IDENTICAL_FIELD, CONTACT_ADDRESS_SECTION, STATE_FORM_FIELD } from "../../constants";
import { createToggleCopyAction } from "../../createActions";
import { getPermanentAddressCallback } from "../../selectors";
import { BlockTitle, SectionDescription } from "../../components";

import { AddressFields } from "./fields";

export interface ContactAddressFormBoxProps {
    formName: string;
    isCompany: boolean;
    isUniqaContract: boolean;
    contractCountry: string;
}

export const ContactAddressFormBox: FC<ContactAddressFormBoxProps> = ({ formName, isCompany, isUniqaContract, contractCountry }) => {
    const { t } = i18n.useTranslation();
    // const copyingEnabled = useSelector(getFormFieldValue(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`));
    const copyingEnabled = useSelector(getFormFieldValue(formName, ARE_ADDRESSES_IDENTICAL_FIELD));
    const dispatch = useDispatch();

    const onToggle = () => {
        dispatch(createToggleCopyAction(formName));
    };

    const permanentAddress = useSelector(getPermanentAddressCallback(formName));
    const isPermAddressCountryOtherThanDomestic = permanentAddress?.get(STATE_FORM_FIELD) !== contractCountry;

    return (
        <ContentBox>
            <GridLayout spacing={1}>
                <GridItem xs={3}>
                    <BlockTitle title={t("holder.contactAddress")} />
                </GridItem>
                <GridItem xs={9}>
                    {isUniqaContract && isPermAddressCountryOtherThanDomestic ? (
                        <SectionDescription content={t("holder.toggleAddressNotPossible")} />
                    ) : (
                        <Field
                            // name={`${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`}
                            name={ARE_ADDRESSES_IDENTICAL_FIELD}
                            label={t("holder.contactAddressSameAsPermanent")}
                            component={SegmentedControl}
                            onChange={onToggle}
                            size={FieldSize.SMALL}
                        />
                    )}
                </GridItem>
            </GridLayout>

            {!copyingEnabled && <AddressFields formName={formName} sectionName={CONTACT_ADDRESS_SECTION} disabled={copyingEnabled} />}
        </ContentBox>
    );
};
