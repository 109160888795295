import React, { FC, ReactNode } from "react";
import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { StyledLabelTextItem, StyledLabelTextItemCell } from "ui-library/Styled";

export interface SimpleLabelTextItemProps {
    label?: ReactNode;
    text: ReactNode;
    fontVariant?: string;
    fullWidth?: boolean;
    color?: string;
    small?: boolean;
    style?: object;
}

export const SimpleLabelTextItem: FC<SimpleLabelTextItemProps> = ({
    fullWidth,
    fontVariant = "p",
    label,
    text,
    color,
    small = false,
    style,
}) => {
    return (
        <StyledLabelTextItem $fullWidth={fullWidth} alignItems={"center"}>
            <StyledLabelTextItemCell pr={2} style={style}>
                <Typography
                    markDown={label}
                    variant={fontVariant === "p" ? "caption" : fontVariant}
                    fontSize={small ? 14 : null}
                    color="gray"
                />
            </StyledLabelTextItemCell>
            <StyledLabelTextItemCell>
                <Typography markDown={text} variant={fontVariant} color={color} component="p" fontSize={small ? 14 : null} />
            </StyledLabelTextItemCell>
        </StyledLabelTextItem>
    );
};
