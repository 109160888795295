import callCenterEditClientForm from "form/fullPage/clientDataOnContracts";

import { ENTITY_CLIENT_CONTRACTS_OVERVIEW, ENTITY_CLIENT_CONTRACTS_METADATA, FORM_EDIT_CLIENT } from "../constants";
import { getClientData } from "../selectors";

export const FullPagePolicyHolderForm = callCenterEditClientForm.createContainer(
    FORM_EDIT_CLIENT,
    getClientData,
    ENTITY_CLIENT_CONTRACTS_METADATA,
    ENTITY_CLIENT_CONTRACTS_OVERVIEW,
);
