import { model } from "core/util";
import { ContractOverview as ContractOverviewType } from "types";

import { LegalEntityV2 } from "../common/v2";
import { ElectronicCommunicationV2 } from "../electronicCommunication/v2";

export const ContractOverview = model.createModel<ContractOverviewType, ContractOverviewType>((json) => ({
    contractOwner: LegalEntityV2.fromServer(json.contractOwner),
    electronicCommunication: ElectronicCommunicationV2.fromServer(json.electronicCommunication),
}));
