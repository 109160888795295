import { RouterPrompt } from "core/router/prompt";
import { FormProvider, UseFormReturn } from "react-hook-form";

type FormProps<T> = {
    form: UseFormReturn<T>;
    onSubmit: (data: T) => void;
    children: React.ReactNode;
    prompt?: boolean;
};

export default function Form<T>({ form, onSubmit, children, prompt = true }: FormProps<T>) {
    return (
        <FormProvider {...form}>
            {/*{prompt && <RouterPrompt when={form.formState.isDirty} />}*/}
            <form onSubmit={form.handleSubmit(onSubmit)} style={{ width: "100%" }}>
                {children}
            </form>
        </FormProvider>
    );
}
