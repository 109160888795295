import { List, Map } from "immutable";
import { all, call } from "redux-saga/effects";

import { fetch, iban, date } from "core/util";
import { Countries } from "enums";

// Regex defined in HTML 5.2 spec - https://www.w3.org/TR/html5/sec-forms.html#email-state-typeemail
// eslint disable because of "no-useless-escape", and i dont want to change regex copied from spec
// eslint-disable-next-line no-useless-escape
export const emailRegex = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
const statementsPasswordRegex = /^[_A-Za-z0-9?]+$/;
const zipRegex = /^[0-9]{3}[ ]?[0-9]{2}$/;
const isMinEightLetters = (value) => (value ? value.length >= 8 : true);

export const TO_TRANSLATE_PREFIX = "TRANSLATE:"; // Will be used to distinguish that this error should be translated
export const ACCEPTED_FILE_TYPES = {
    "application/pdf": ["pdf"],
    "image/jpeg": ["jpg", "jpeg"],
};
const ACCEPTED_FILE_TYPES_ARRAY = ["application/pdf", "image/jpeg"];
const ACCEPTED_FILE_EXTENSION = [".pdf", ".jpg", ".jpeg"];

export const ACCEPTED_FILE_SIZE_BYTES = 5242880;

// if decimal sign present, must contain digit before and after
export const decimalNumberDotOrCommaRegex = /^[0-9]+(([.,][0-9]+)|[0-9]?)?$/;

const createValidator = (test, errorMessage) => (value) => value && !test(value) ? errorMessage : undefined;

export const required = (value) => (value ? undefined : `${TO_TRANSLATE_PREFIX}error.required`);

export const birthNumber = (value) => {
    if (!value) {
        return undefined;
    }
    if (value.length < 9) {
        return `${TO_TRANSLATE_PREFIX}error.notValid`;
    }

    const birthNumberParsed = parseInt(value);
    if (value.length === 10) {
        return birthNumberParsed % 11 === 0 ? undefined : `${TO_TRANSLATE_PREFIX}error.notValid`;
    }
    const yearOfBirth = parseInt(value.substring(0, 2));
    return yearOfBirth < 54 ? undefined : `${TO_TRANSLATE_PREFIX}error.notValid`;
};

export const requiredBasedOnOtherFieldValue = (otherFieldValue, pathToOtherField) => (value, values) =>
    values.getIn(pathToOtherField) === otherFieldValue && !value ? `${TO_TRANSLATE_PREFIX}error.required` : undefined;

export const personalDataConsentRequired = (value) => (value ? undefined : `${TO_TRANSLATE_PREFIX}error.personalDataConsentRequired`);

export const isBeforeOrSame = (notLaterThanDate) =>
    createValidator((value) => date.isBeforeOrSame(value, notLaterThanDate), `${TO_TRANSLATE_PREFIX}error.dateNotInAllowedRange`);

export const isAfterOrSame = (notSoonerThanDate) =>
    createValidator((value) => date.isAfterOrSame(value, notSoonerThanDate), `${TO_TRANSLATE_PREFIX}error.dateNotInAllowedRange`);

export const greaterThanZero = createValidator(
    (value) => Number(value.replace(",", ".")) > 0,
    `${TO_TRANSLATE_PREFIX}error.greaterThanZero`,
);
export const minTwoLetters = createValidator((value) => value.length >= 2, `${TO_TRANSLATE_PREFIX}error.minTwoLettersRequired`);

export const maxValue = (maxVal) =>
    createValidator((value) => {
        return Number(value) <= Number(maxVal);
    }, `${TO_TRANSLATE_PREFIX}error.notValid`);

export const minValue = (minValue) =>
    createValidator((value) => {
        return Number(value) >= Number(minValue);
    }, `${TO_TRANSLATE_PREFIX}error.notValid`);

export const equalsHundred = createValidator((value) => Number(value) === 100, `${TO_TRANSLATE_PREFIX}error.sumNotHundredPercent`);

export const email = createValidator((value) => emailRegex.test(value), `${TO_TRANSLATE_PREFIX}error.email.notValid`);
export const meetPasswordCriteria = (value) =>
    passwordRegex.test(value) ? undefined : `${TO_TRANSLATE_PREFIX}error.password.formatingError`;
export const meetStatementsPasswordCriteria = (value) =>
    statementsPasswordRegex.test(value) && isMinEightLetters(value)
        ? undefined
        : `${TO_TRANSLATE_PREFIX}error.statementsPassword.formatingError`;

export const zip = createValidator((value) => zipRegex.test(value), `${TO_TRANSLATE_PREFIX}error.zip.notValid`);

export const acceptedFileTypesValidator = (files = List()) => {
    const allOkFormat = files.filter((file) => !ACCEPTED_FILE_TYPES_ARRAY.includes(file.get("fileFormat"))).isEmpty();
    const allOkExtension = files
        .filter((file) => {
            const fileName = file.get("fileName");
            const fileExtension = fileName.slice(fileName.lastIndexOf("."));
            return !ACCEPTED_FILE_EXTENSION.includes(fileExtension.toLowerCase());
        })
        .isEmpty();

    return allOkFormat && allOkExtension ? undefined : `${TO_TRANSLATE_PREFIX}error.attachement.formatNotValid`;
};

export const acceptedFileSizeValidator = (files = List()) => {
    const allOkSize = files.filter((file) => file.get("size") > ACCEPTED_FILE_SIZE_BYTES).isEmpty();
    return allOkSize ? undefined : `${TO_TRANSLATE_PREFIX}error.attachement.sizeNotValid`;
};

export const validAccountNumberPrefix = (prefix) => {
    if (!prefix) {
        return undefined;
    }

    const weights = [10, 5, 8, 4, 2, 1];
    const paddedPrefix = prefix.padStart(6, "0");

    // We need to multiply every digit from prefix with it's defined height. After
    // the multiplication we need to sum all multiplied digits and check modulo 11 to be 0.
    const weightedSum = paddedPrefix
        .split("")
        .map((digit, index) => digit * weights[index])
        .reduce((sum, value) => sum + value);

    if (weightedSum > 0 && weightedSum % 11 === 0) {
        return undefined;
    }
    return `${TO_TRANSLATE_PREFIX}error.notValid`;
};

export const validAccountNumber = (number) => {
    if (!number) {
        return undefined;
    }

    const weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
    const paddedNumber = number.padStart(10, "0");

    // We need to multiply every digit from account number with it's defined height. After
    // the multiplication we need to sum all multiplied digits and check modulo 11 to be 0.
    const weightedSum = paddedNumber
        .split("")
        .map((digit, index) => digit * weights[index])
        .reduce((sum, value) => sum + value);

    if (weightedSum > 0 && weightedSum % 11 === 0) {
        return undefined;
    }
    return `${TO_TRANSLATE_PREFIX}error.notValid`;
};

export const validIban = createValidator((value) => {
    try {
        return iban.validateIBAN(value);
    } catch (e) {
        return false;
    }
}, `${TO_TRANSLATE_PREFIX}error.notValid`);

export const validateSkIban = createValidator((value) => {
    try {
        iban.validateIBAN(value);
        return value.startsWith("SK");
    } catch (e) {
        // if iban is not valid, validation of sk iban is skipped
        return true;
    }
}, `${TO_TRANSLATE_PREFIX}error.notValidSkIban`);

const amountRegex = new RegExp("^[0-9]{1,10}([,.][0-9]{1,2})?$");
export const validAmount = createValidator((value) => amountRegex.test(value), `${TO_TRANSLATE_PREFIX}error.notValid`);

export const asyncFieldValidator = (fieldPath, validators) =>
    function* (values, fieldName) {
        if (fieldName === fieldPath.join(".") || !fieldName) {
            const validatorsQueue = [...validators];
            while (validatorsQueue.length > 0) {
                try {
                    const error = yield call(validatorsQueue.shift(), values.getIn(fieldPath), values);
                    // handling frontend validations
                    if (error) {
                        return Map()
                            .setIn(fieldPath, List([error]))
                            .toJS();
                    }
                } catch (e) {
                    if (e && e.status === fetch.PRECONDITION_FAILED) {
                        const { errors } = e.response;
                        if (errors && errors.length > 0) {
                            return Map()
                                .setIn(fieldPath, Array.isArray(errors) ? List(errors) : List([errors]))
                                .toJS();
                        }
                    } else {
                        throw e;
                    }
                }
            }
        }
        return null;
    };

export const asyncValidateFields = (...validators) =>
    function* (field, values) {
        const result = yield all(validators.map((validator) => call(validator, values, field)));
        return result.find(Boolean);
    };

// By site
export const getValidateAmountBySite = (siteId) =>
    siteId === Countries.SK.id ? [required, validAmount, greaterThanZero] : [greaterThanZero, required];
