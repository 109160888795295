export enum FormState {
    EDIT = "EDIT",
    OTP_CHALLENGE = "OTP_CHALLENGE",
    SUBMITTED = "SUBMITTED",
}

export enum ActiveOperationsOperationTypeEnum {
    ACTIVE_OPERATIONS_SET = "ACTIVE_OPERATIONS_SET",
    ACTIVE_OPERATIONS_UNSET = "ACTIVE_OPERATIONS_UNSET",
}
