import React, { FC } from "react";
import { useSelector } from "react-redux";

import { LegalEntity as LegalEntityType } from "types";

import {
    CompanyParticipantFormBox,
    ContactAddressFormBox,
    IdentityCardTypeFormBox,
    IndividualParticipantFormBox,
    PermanentAddressFormBox,
    PhoneEmailFormBox,
    SelectChangeSubmitFormBox,
} from "./formBox";

import { isIdentificationCardIdDirtyCallback } from "../selectors";

export interface SectionEditClientDataProps {
    formName: string;
    clientData: LegalEntityType;
    handleSubmit: any;
    isFormBeingSubmitted: boolean;
}

export const SectionEditClientData: FC<SectionEditClientDataProps> = ({ formName, clientData, handleSubmit, isFormBeingSubmitted }) => {
    const idCardDirty = useSelector(isIdentificationCardIdDirtyCallback(formName));

    return (
        <>
            {clientData.isPerson ? <IndividualParticipantFormBox formName={formName} /> : <CompanyParticipantFormBox formName={formName} />}

            <hr />

            <SelectChangeSubmitFormBox formName={formName} handleSubmit={handleSubmit} isFormBeingSubmitted={isFormBeingSubmitted} />

            <PermanentAddressFormBox isCompany={!clientData.isPerson} formName={formName} />

            <PhoneEmailFormBox formName={formName} />

            <ContactAddressFormBox
                isCompany={!clientData.isPerson}
                formName={formName}
                isUniqaContract={false}
                contractCountry={clientData.citizenship}
            />

            {clientData.isPerson && (
                <>
                    <hr />
                    <IdentityCardTypeFormBox idCardDirty={idCardDirty} />
                </>
            )}
        </>
    );
};
