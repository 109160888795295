import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flexbox, GridItem } from "ui-library/layouts";
import { Loading, Option, Select, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import entity from "core/entity";
import fetching from "core/fetching";
import { ClaimCategory } from "enums";
import { IncidentEvents } from "model/incident";
import { IncidentDtoIncidentStateEnum } from "types/generated";

import { IncidentsList } from "./IncidentsList";

export const IncidentsSection = ({ incidentsEntity, displayedIncidentStateEntity, shouldDisplayContractNumber }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const displayedIncidentState = useSelector(entity.getData(displayedIncidentStateEntity, IncidentDtoIncidentStateEnum.Opened));

    const isIncidentsLoading = useSelector(fetching.isFetching(incidentsEntity, true));
    const isIncidentsError = useSelector(entity.isError(incidentsEntity));
    const incidentsData = useSelector(entity.getData(incidentsEntity, IncidentEvents.fromServer()));

    const openedIncidents = incidentsData.incidents.filter((incident) => incident.categoryClaim !== ClaimCategory.FIN.id);
    const isOpenedBiggerThanThreshold = openedIncidents.size > 3;

    return (
        <>
            <GridItem xs={12} mb={1}>
                <Flexbox direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"}>
                    <Typography variant="h2">{t("claim.overview")}</Typography>
                    {isOpenedBiggerThanThreshold && (
                        <Box>
                            <Flexbox alignItems={"center"} gap={1}>
                                <Select
                                    value={displayedIncidentState}
                                    onChange={(event) => dispatch(entity.setData(displayedIncidentStateEntity, event.target.value))}
                                    fullWidth
                                    placeholder={t("common.choose")}
                                    id="selectIncidentState"
                                >
                                    <Option value={IncidentDtoIncidentStateEnum.Opened}>{t("incident.openIncidents")}</Option>
                                    <Option value={IncidentDtoIncidentStateEnum.Closed}>{t("incident.closedIncidents")}</Option>
                                </Select>
                            </Flexbox>
                        </Box>
                    )}
                </Flexbox>
            </GridItem>

            {isIncidentsError && <Typography>{t("error.sectionUnavailable")}</Typography>}

            {!isIncidentsError && (
                <Loading loading={isIncidentsLoading}>
                    {!isOpenedBiggerThanThreshold ? (
                        <>
                            <IncidentsList
                                incidentsData={incidentsData}
                                incidentType={IncidentDtoIncidentStateEnum.Opened}
                                shouldDisplayContractNumber={shouldDisplayContractNumber}
                            />
                            <IncidentsList
                                incidentsData={incidentsData}
                                incidentType={IncidentDtoIncidentStateEnum.Closed}
                                shouldDisplayContractNumber={shouldDisplayContractNumber}
                            />
                        </>
                    ) : (
                        <IncidentsList
                            incidentsData={incidentsData}
                            incidentType={displayedIncidentState}
                            shouldDisplayContractNumber={shouldDisplayContractNumber}
                        />
                    )}
                </Loading>
            )}
        </>
    );
};

IncidentsSection.propTypes = {
    incidentsEntity: PropTypes.string.isRequired,
    displayedIncidentStateEntity: PropTypes.string.isRequired,
    shouldDisplayContractNumber: PropTypes.bool,
};

IncidentsSection.defaultProps = {
    shouldDisplayContractNumber: false,
};
