import { API_PREFIX } from "app/constants";
import superagent from "superagent";
import to from "core/util/fetch/awaitToJs";

export type VehicleDataSubmitDto = {
    newLicencePlateNumber: string;
    newVehicleLicenceNumber: string;
    oldLicencePlateNumber: string;
    oldVehicleLicenceNumber: string;
    vinNumber: string;
    validityStartDate: Date;
    idObject: number;
};

const uriPrefix = `${API_PREFIX}/vehicle-data-change`;

const isFeasible = async (idObject: string) => {
    return await to(superagent.get(`${uriPrefix}/${idObject}/is-feasible`));
};

const createChange = async (idObject: string) => {
    return await to(superagent.get(`${uriPrefix}/${idObject}/create`));
};

const getLicencePlateNumber = async (idChange: string, vin: string) => {
    return await to(superagent.get(`${uriPrefix}/ckpskp/${idChange}/licenceplatenumber/${vin}`));
};

const getVehicleLicenceNumber = async (idChange: string, vin: string) => {
    return await to(superagent.get(`${uriPrefix}/ckpskp/${idChange}/vehiclelicencenumber/${vin}`));
};

const submitUnauthenticated = async (body: VehicleDataSubmitDto) => {
    return await to(superagent.post(`${uriPrefix}/submit`).send(body).set("Content-Type", "application/json"));
};

const submitAuthenticated = async (body: VehicleDataSubmitDto, idChange: string) => {
    return await to(superagent.post(`${uriPrefix}/${idChange}/submit`).send(body).set("Content-Type", "application/json"));
};

export default {
    isFeasible,
    createChange,
    getLicencePlateNumber,
    getVehicleLicenceNumber,
    submitUnauthenticated,
    submitAuthenticated,
};
