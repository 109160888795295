import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { ClaimsEvents } from "model/claim";
import { HarmonizedContract, HarmonizedFinancialOperation } from "model/harmonized";
import { GreenCardHeader } from "model/nonLife";

const productUriPrefix = `${API_PREFIX}/product/vehicle`;
const getVehicleContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, HarmonizedContract.fromServer);
const getVehicleFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, HarmonizedFinancialOperation.fromServerList);
const getVehicleClaims = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/claims`, null, ClaimsEvents.fromServer);
const getVehicleGreenCardHeaderData = (idObject, vinNumber) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/green-card-header`, { vinNumber }, GreenCardHeader.fromServer);

export default {
    getVehicleContractDetail,
    getVehicleFinancialOperations,
    getVehicleClaims,
    getVehicleGreenCardHeaderData,
};
