import React, { FC } from "react";
import { Field, Form } from "core/hookForm";
import { Grid } from "@mui/material";

import { Box, ContentBox, GridItem } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { useChangeTypeGetParamForm, useGetSiteValue, useGetUserChangeTypesWithFormComponent, useHasRole } from "core/hooks";
import { fn } from "core/util";
import { ExternalUrl, Roles } from "enums";
import { ChangesWithFormComponentDefinition, ContractChangeType } from "types";

export interface ContractChangesWithRestrictionTabProps {
    changesDefinition: ChangesWithFormComponentDefinition;
    nonRestrictedChanges?: ContractChangeType[];
    isRestricted: boolean;
    RestrictionComponent: FC;
}

export const ContractChangesWithRestrictionTab: FC<ContractChangesWithRestrictionTabProps> = ({
    changesDefinition,
    nonRestrictedChanges = [],
    isRestricted,
    RestrictionComponent,
}) => {
    const { form, changeType, onChange } = useChangeTypeGetParamForm();

    const { t } = i18n.useTranslation();
    const getSiteValue = useGetSiteValue();

    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);
    const introductionText = isAuthenticated
        ? t("change.tab.loggedUserNote", { link: getSiteValue(ExternalUrl.E_REGISTRY_DISPATCH) })
        : t("change.tab.oneTimeUserNote", { link: getSiteValue(ExternalUrl.E_REGISTRY_DISPATCH) });

    const changeOptions = useGetUserChangeTypesWithFormComponent(changesDefinition);
    const selectedChange = fn.arrayFirst(changeOptions.filter((element) => element.code === changeType));

    const displayRestriction =
        isRestricted && (!selectedChange || (selectedChange && !nonRestrictedChanges.includes(selectedChange?.code)));

    const FormComponent = selectedChange ? selectedChange?.formComponent : () => null;

    return (
        <>
            <Grid
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "flex-start",
                }}
            >
                <GridItem xs={12}>
                    <ContentBox fullWidth>
                        <Typography markDown={introductionText} />
                    </ContentBox>
                </GridItem>

                <GridItem xs={12}>
                    <Form form={form} onSubmit={null} prompt={false}>
                        <Box fullWidth mb={3}>
                            <Field
                                input="select"
                                name="changeType"
                                placeholder={t("vehicle.change.type")}
                                label={t("vehicle.change.type")}
                                options={changeOptions}
                                onChange={onChange}
                            />
                        </Box>
                    </Form>
                    {displayRestriction ? <RestrictionComponent /> : <FormComponent />}
                </GridItem>
            </Grid>
        </>
    );
};
