export const NAME = "core-form";

export const ERROR_COMPONENT_CLASS = "error_component_class";

export const ENTER_KEY = "Enter";

// State change values.
export const STATE_CHANGE_NO_CHANGE = "NO_CHANGE";
export const STATE_CHANGE_ENABLE = "ENABLE";
export const STATE_CHANGE_DISABLE = "DISABLE";
