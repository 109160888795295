import { Garage } from "types/garages";
import { Modal, ModalHeader } from "ui-library/modules";

// TODO: Add garage details
export default function ModalGarageDetail({ garage, onClose }: { garage?: Garage; onClose: any }) {
    return (
        <>
            <Modal size={"small"} open={true} onClose={onClose}>
                <ModalHeader closeAction={onClose} icon={null} title={garage?.companyName} />
            </Modal>
        </>
    );
}
