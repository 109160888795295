import { ClientType } from "enums";

// form fields
export const ID_CLIENT = "idClient";
export const TYPE_OF_DATA_CHANGE_FIELD = "typeOfDataChange";

export const SELECTED_CONTRACTS_IDS_FIELD = "selectedContractsIds";

export const IDENTIFICATION_CARD_SECTION = "identificationCard";
export const IDENTIFICATION_FORM_FIELD = `${IDENTIFICATION_CARD_SECTION}.identification`;
export const CARD_ID_FORM_FIELD = `${IDENTIFICATION_CARD_SECTION}.cardId`;
export const RELEASE_DATE_FORM_FIELD = `${IDENTIFICATION_CARD_SECTION}.releaseDate`;
export const DATE_OF_EXPIRY_FORM_FIELD = `${IDENTIFICATION_CARD_SECTION}.dateOfExpiry`;
export const AUTHORITY_FORM_FIELD = `${IDENTIFICATION_CARD_SECTION}.authority`;

export const PERM_ADDRESS_AUTOCOMPLETE = "autocomplete.permanentAddress";
export const CONTACT_ADDRESS_AUTOCOMPLETE = "autocomplete.contactAddress";

export const ARE_ADDRESSES_IDENTICAL_FIELD = "areAddressesIdentical";

export const CONTACT_ADDRESS_SECTION = "contactAddress";
export const PERMANENT_ADDRESS_SECTION = "permanentAddress";
export const STREET_FORM_FIELD = "street";
export const DESCRIPTION_NUMBER_FORM_FIELD = "descriptionNumber";
export const ORIENTATION_NUMBER_FORM_FIELD = "orientationNumber";
export const CITY_FORM_FIELD = "city";
export const ZIP_FORM_FIELD = "zip";
export const STATE_FORM_FIELD = "state";

export const COMPANY_NAME_FIELD = "companyName";
export const PERSON_SECTION = "person";
export const TITLE_BEFORE_FIELD = "titleBefore";
export const FIRST_NAME_FIELD = "firstName";
export const LAST_NAME_FIELD = "lastName";
export const TITLE_AFTER_FIELD = "titleAfter";

export const EMAIL_FIELD = "email";
export const MOBILE_FIELD = "mobile";

export const FILES_FIELD = "files";

// SUBMIT TYPE
export const SUBMIT_TYPE_FIELD = "submitType";

// OTP
export const OTP_CODE_FIELD = "otpCode";
export const OTP_ID_FIELD = "otpId";

// Loading
export const LOADING_E_COM_VIRTUAL_FIELD = "loadingElectronicCommunication";

// Changes
export const VISIBLE_CHANGED_FIELDS = [
    PERSON_SECTION,
    COMPANY_NAME_FIELD,
    PERMANENT_ADDRESS_SECTION,
    CONTACT_ADDRESS_SECTION,
    "identificationCard",
    MOBILE_FIELD,
    EMAIL_FIELD,
];

export const ATTACHMENT_REQUIRED_FIELDS = [PERSON_SECTION, COMPANY_NAME_FIELD, PERMANENT_ADDRESS_SECTION, "identificationCard"];

// enum for backend
export const CLIENT_TYPE_INDIVIDUAL = ClientType.INDIVIDUAL.id;
export const CLIENT_TYPE_COMPANY = ClientType.COMPANY.id;
