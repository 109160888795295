import { ProductGroupLvl2 } from "enums";

import userContracts from "../../userContracts";
import pageContext from "../../pageContext";

export const useIsContractActiveOperationsEnabled: (idObject: number) => boolean = (idObject) => {
    if (!idObject) {
        return false;
    }

    const { isSlovakSite } = pageContext.useGetPageLocalization();
    const contract = userContracts.useGetContractById(idObject);

    if (isSlovakSite && contract.idEnuProductGroupLevelTwo == ProductGroupLvl2.PENSION_DPS_DDS && contract.overview) {
        return contract.overview.isActiveOperationsEnabled;
    }

    // Be default contracts have active operations enabled.
    return true;
};
