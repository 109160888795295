import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import { IncidentsSection } from "containers/incidentDeprecated";
import { ReportClaimGridItem } from "containers/contract";
import fetching from "core/fetching";
import { ClaimReportCategory } from "enums";

import { ENTITY_CONTRACT_INCIDENTS, ENTITY_CONTRACT_INCIDENTS_OPEN_STATE, ENTITY_CONTRACT_OVERVIEW } from "../constants";
import { createGetCompliantLineOfBusinessClasses } from "../selectors";
import userContracts from "core/userContracts";

export const ClaimsTab: FC = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isFutureContract } = userContracts.useGetContractDerivedData(contract);

    const compliantLineOfBusinessClasses = useSelector(createGetCompliantLineOfBusinessClasses(idObject));
    const isContractOverviewLoading = useSelector(fetching.isFetching(ENTITY_CONTRACT_OVERVIEW, false));

    if (isFutureContract) {
        return null;
    }
    return (
        <GridLayout defaultSpacing>
            <ReportClaimGridItem
                idObject={idObject}
                claimCategoryId={ClaimReportCategory.VEHICLE.id}
                compliantLineOfBusinessClasses={compliantLineOfBusinessClasses}
                isContractOverviewLoading={isContractOverviewLoading}
            />
            <IncidentsSection
                incidentsEntity={ENTITY_CONTRACT_INCIDENTS}
                displayedIncidentStateEntity={ENTITY_CONTRACT_INCIDENTS_OPEN_STATE}
            />
        </GridLayout>
    );
};
