import { all, call, fork, select } from "redux-saga/effects";

import clientDataOnContractsForm from "form/fullPage/clientDataOnContracts";

import entity from "core/entity";
import router from "core/router";
import { fetch } from "core/util";
import { clientApi } from "serverApi";
import { ContractMetadata } from "model/contract";
import { Tabs } from "routeConstants";
import { DataLoadingsStatusEnum } from "types";

import { clear, setClientData, setClientDataState } from "./actions";
import { ENTITY_CLIENT_CONTRACTS_METADATA, ENTITY_CLIENT_CONTRACTS_OVERVIEW, FORM_EDIT_CLIENT } from "./constants";
import { getClientData } from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ idClient }) {
        try {
            const clientData = yield call(clientApi.getClientData, idClient);
            return [setClientData(clientData), setClientDataState(DataLoadingsStatusEnum.LOADED)];
        } catch (e) {
            if (e.status === fetch.ACCESS_DENIED) {
                return [setClientDataState(DataLoadingsStatusEnum.ACCESS_DENIED)];
            }
            return [setClientDataState(DataLoadingsStatusEnum.ERROR)];
        }
    },
    *onPageNonAuthenticatedEnter({ idClient }) {
        yield fork(loadObjects, idClient);
    },
    *onInnerRouteChange(name, params, query) {
        yield call(routeChangeSaga, name, query);
    },
    clearDataForPage() {
        return [clear(), entity.clearData(ENTITY_CLIENT_CONTRACTS_METADATA)];
    },
});

function* routeChangeSaga(name, query) {
    switch (name) {
        case Tabs.FORM_EDIT_CLIENT:
            yield call(contractEditHolderFormSaga);
            break;

        default:
            break;
    }
}

const contractEditHolderFormSaga = clientDataOnContractsForm.createSaga(FORM_EDIT_CLIENT, getClientData, ENTITY_CLIENT_CONTRACTS_METADATA);

export function* loadObjects(idClient) {
    yield call(entity.load, ENTITY_CLIENT_CONTRACTS_METADATA, () => clientApi.getClientActiveContractsMetadata(idClient));

    const clientContractsMetadata = yield select(entity.getData(ENTITY_CLIENT_CONTRACTS_METADATA, ContractMetadata.fromServerList()));

    yield all([
        ...clientContractsMetadata.map((contractMetadata) =>
            fork(entity.loadIntoMapWithState, ENTITY_CLIENT_CONTRACTS_OVERVIEW, contractMetadata.idObject, () =>
                clientApi.getClientContractOverview(idClient, contractMetadata.idObject),
            ),
        ),
    ]);
}
