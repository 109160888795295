import React, { FC, SVGAttributes } from "react";
import { IconsPath } from "./IconsPath";
import { StyledPath, StyledSVG } from "ui-library/Styled";
import { StyledSVGWithPopover } from "../../Styled/Atoms/Icons";

export interface IconProps extends SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
    popoverText?: string;
    icon: string;
    popoverLeft?: string;
}

export const IconWithPopover: FC<IconProps> = ({ color = "black", size = 12, icon, popoverText, popoverLeft, ...props }) => {
    const iconPath = IconsPath(icon);

    return (
        <StyledSVGWithPopover $popoverLeft={popoverLeft}>
            <div className="tooltip" data-tooltip={popoverText}>
                <svg {...props} viewBox={iconPath.viewBox} width={`${size}px`} height={`${size}px`} xmlns="http://www.w3.org/2000/svg">
                    <StyledPath color={color} d={iconPath.d} />
                </svg>
            </div>
        </StyledSVGWithPopover>
    );
};
