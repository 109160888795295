import React from "react";
import PropTypes from "prop-types";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, maxValue100, numberCharacters, ReduxFormSection } from "core/form";
import { formatPercentageNoDecimal } from "core/formatters";
import { fn } from "core/util";
import { DdsSavingStrategyFund } from "enums";

import { DDS_CONTRIBUTORY_FIELD_NAME, DDS_GLOBAL_FIELD_NAME, DDS_INDEX_FIELD_NAME } from "../constants";

const PLACEHOLDER = "%";

const getFundLabelWithPercentage = (t, actualStrategies, fundDefinition) => {
    const fundData = fn.getFirstFromArray(
        actualStrategies?.filter((strategy) => Number(strategy.investmentFund?.code) === Number(fundDefinition.fundId)),
    );

    if (!fundData) {
        return t(fundDefinition.msg);
    }
    return `${t(fundDefinition?.msg)} (${t("pension.currentInvestmentPercentage")} ${formatPercentageNoDecimal(
        fundData.investmentPercentage,
    )})`;
};

const PercentageSelectDds = ({ sectionName, actualStrategies }) => {
    const { t } = i18n.useTranslation();

    return (
        <ReduxFormSection name={sectionName}>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DDS_GLOBAL_FIELD_NAME}
                    label={getFundLabelWithPercentage(t, actualStrategies, DdsSavingStrategyFund.GLOBAL)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DDS_CONTRIBUTORY_FIELD_NAME}
                    label={getFundLabelWithPercentage(t, actualStrategies, DdsSavingStrategyFund.CONTRIBUTORY)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DDS_INDEX_FIELD_NAME}
                    label={getFundLabelWithPercentage(t, actualStrategies, DdsSavingStrategyFund.INDEX)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
        </ReduxFormSection>
    );
};

PercentageSelectDds.propTypes = {
    sectionName: PropTypes.string.isRequired,
    actualStrategies: PropTypes.any.isRequired,
};

export default PercentageSelectDds;
