import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { Attachment, CancellationReason, ChangeSheet } from "model/change";
import to from "core/util/fetch/awaitToJs";
import superagent from "superagent";

const cancellationUriPrefix = `${API_PREFIX}/vehicle-cancellation`;

const isFeasible = async (idObject) => {
    return await to(superagent.get(`${cancellationUriPrefix}/${idObject}/is-feasible`));
};

const createCancellation = (idObject, data) =>
    fetch.doPost(`${cancellationUriPrefix}/${idObject}/create`, data, undefined, ChangeSheet.fromServer);

const getCancellationReasons = (idObject, changeSheetId) =>
    fetch.doGet(`${cancellationUriPrefix}/${idObject}/cancellation-reason/${changeSheetId}`, null, CancellationReason.fromServerList);

const getCancellationAttachments = (idObject, changeSheetId, vehicleCancellationReason) =>
    fetch.doGet(
        `${cancellationUriPrefix}/${idObject}/cancellation-attachments/${changeSheetId}/${vehicleCancellationReason}`,
        null,
        Attachment.fromServerList,
    );

const submitCancellation = (idObject, idChange, data) =>
    fetch.doPost(`${cancellationUriPrefix}/${idObject}/submit/${idChange}`, data, undefined, ChangeSheet.fromServer);

const completeCancellation = (idObject, idChange, data) =>
    fetch.doPost(`${cancellationUriPrefix}/${idObject}/complete/${idChange}`, data, undefined, ChangeSheet.fromServer);

const getDocumentToSignDownloadUrl = (idChange) => `${API_PREFIX}/vehicle-change/${idChange}/document-to-sign`;

export default {
    isFeasible,
    createCancellation,
    getCancellationReasons,
    getCancellationAttachments,
    submitCancellation,
    completeCancellation,
    getDocumentToSignDownloadUrl,
};
