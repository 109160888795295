import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { formatPercentage } from "core/formatters";
import { model } from "core/util";
import { PensionFundsChange } from "model/changePropositions";

export const PensionFundsChangeDetail = ({ pensionFundsChange }) => {
    const { t } = i18n.useTranslation();
    const locale = i18n.getLanguageCode();

    return (
        <>
            <Box mb={1}>
                <Typography component="p" variant="b">
                    {t("changeProposition.transactionDetail")}
                </Typography>
            </Box>

            {!pensionFundsChange.newPensionFunds.isEmpty() && (
                <Box>
                    <Typography variant="caption">{t("changeProposition.pensionFundChangeList")}</Typography>

                    {pensionFundsChange.newPensionFunds
                        .map((newPensionFund) => (
                            <LabelTextItem
                                key={newPensionFund.fund.code}
                                fontVariant="caption"
                                text={`${model.getLocaleCodeName(newPensionFund.fund, locale)} - ${formatPercentage(
                                    newPensionFund.percentage,
                                )}`}
                            />
                        ))
                        .toArray()}
                </Box>
            )}

            {!pensionFundsChange.newInvestmentStrategyFunds.isEmpty() && (
                <Box>
                    <Typography variant="caption">{t("changeProposition.pensionInvestmentStrategyChangeList")}</Typography>

                    {pensionFundsChange.newInvestmentStrategyFunds
                        .map((newInvestmentStrategyFund) => (
                            <LabelTextItem
                                key={newInvestmentStrategyFund.fund.code}
                                fontVariant="caption"
                                text={`${model.getLocaleCodeName(newInvestmentStrategyFund.fund, locale)} - ${formatPercentage(
                                    newInvestmentStrategyFund.percentage,
                                )}`}
                            />
                        ))
                        .toArray()}
                </Box>
            )}
        </>
    );
};

PensionFundsChangeDetail.propTypes = {
    pensionFundsChange: PropTypes.instanceOf(PensionFundsChange).isRequired,
};
