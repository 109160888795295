import React, { FC, PropsWithChildren } from "react";
import { ButtonTypeMap, ExtendButtonBase } from "@mui/material";

import { StyledButton } from "ui-library/Styled";
import { Icon } from "ui-library/atoms/Icons/Icon";
import useBreakpoints from "ui-library/theme/breakpoints";

export interface ButtonProps extends PropsWithChildren, Partial<ExtendButtonBase<ButtonTypeMap>> {
    onClick?: any;
    disabled?: boolean;
    color?: string;
    variant?: string;
    startIcon?: string;
    endIcon?: string;
    borderRadius?: number;
    border?: boolean;
    size?: string;
    externalLink?: string;
    newTab?: boolean;
    rounded?: boolean;
    style?: object;
}

export const Button: FC<ButtonProps> = ({
    children,
    startIcon,
    rounded,
    borderRadius = 4,
    border = true,
    endIcon,
    externalLink,
    newTab,
    size = "medium",
    variant = "contained",
    color = "base",
    style = null,
    ...props
}) => {
    const isMd = useBreakpoints("md");
    const redirect = () => {
        if (externalLink) {
            newTab ? window.open(externalLink) : window.location.assign(externalLink);
        }
    };

    return (
        <StyledButton
            size={isMd ? size : "medium"}
            startIcon={startIcon ? <Icon size={16} icon={startIcon} /> : null}
            endIcon={endIcon ? <Icon size={16} icon={endIcon} /> : null}
            $borderRadius={rounded ? 99 : borderRadius}
            $border={border}
            onClick={externalLink ? redirect : props.onClick}
            color={color}
            variant={variant}
            style={style}
            {...props}
        >
            {children}
        </StyledButton>
    );
};
