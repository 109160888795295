import React, { FC } from "react";

import i18n from "core/i18n";
import { Field, InputText, lettersNumbersBasicInterpunctionCharacters, max50Characters } from "core/form";
import { FieldSize } from "enums";

import { COMPANY_NAME_FIELD } from "../../../constants";

export interface CompanyNameFieldProps {
    formName: string;
}

export const CompanyNameField: FC<CompanyNameFieldProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <Field
            name={COMPANY_NAME_FIELD}
            component={InputText}
            label={t("holder.companyName")}
            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
            size={FieldSize.SMALL}
            colourWhenChanged
            displayErrorAsPopover
        />
    );
};
