import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Container, Hamburger, Image } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledHeader } from "ui-library/Styled";
import { LogoWrapper } from "ui-library/Styled/Modules/Header";
import useBreakpoints from "ui-library/theme/breakpoints";
import logo from "ui-library/assets/images/logo.svg";

import auth from "core/auth";
import router from "core/router";
import userInfo from "core/userInfo";
import { ChangeLanguageButton } from "containers/buttons";
import { Pages } from "routeConstants";

import { LoggedInMenu, LoggedOutMenu } from "./menu";

export const Header: FC = () => {
    const location = useLocation();

    const isLogged = useSelector(auth.isLoggedIn);
    const isDisclaimerAccepted = useSelector(userInfo.getCurrentDisclaimerAccepted);

    // Hamburger menu.
    useEffect(() => setIsMenuOpened(false), [location]);
    const [isMenuOpened = false, setIsMenuOpened] = React.useState<boolean>();
    const handleMenuToggle = () => setIsMenuOpened(!isMenuOpened);

    const handleLogoClick = () => {
        // We are using location.href here because it forces the browser to refresh.
        window.location.href = isLogged ? router.getStaticUrl(Pages.CONTRACTS) : router.getStaticUrl(Pages.LOGIN);
    };

    const breakpointMd = useBreakpoints("md");
    return (
        <StyledHeader className={isMenuOpened ? "opened-header-menu" : ""}>
            <Container>
                <Flexbox
                    className={"header"}
                    flexDirection={{ xs: "row", md: "row" }}
                    alignItems={"center"}
                    justifyContent={{ xs: "unset", md: "space-between" }}
                >
                    <LogoWrapper>
                        <Image shadow={false} src={logo} onClick={handleLogoClick} alt={"Logo"} />
                    </LogoWrapper>

                    {isLogged ? <LoggedInMenu isMenuOpened={isMenuOpened} /> : <LoggedOutMenu isMenuOpened={isMenuOpened} />}

                    {!breakpointMd && (!isLogged || (isLogged && isDisclaimerAccepted)) && (
                        <>
                            {!isLogged && (
                                <Box>
                                    <ChangeLanguageButton />
                                </Box>
                            )}
                            <Box>
                                <Hamburger onClick={handleMenuToggle} isOpenState={isMenuOpened} />
                            </Box>
                        </>
                    )}
                </Flexbox>
            </Container>
        </StyledHeader>
    );
};
