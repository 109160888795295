import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { ContractChangeType } from "types";

const TYPE_PARAM: string = "type";

type VehicleChangesForm = {
    changeType: ContractChangeType | string;
};

export function useChangeTypeGetParamForm() {
    const [searchParams, setSearchParams] = useSearchParams();

    const form = useForm<VehicleChangesForm>({
        defaultValues: {
            changeType: searchParams.get(TYPE_PARAM) || null,
        },
    });

    const changeType = form.watch("changeType");

    const currentUrlParam = searchParams.get(TYPE_PARAM) || null;
    const formChangeType = form.watch("changeType");

    // Sync FORM from URL ONLY when URL changes externally.
    useEffect(() => {
        if (currentUrlParam !== formChangeType) {
            form.setValue("changeType", currentUrlParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUrlParam]); // ⚠️ only depends on currentUrlParam!

    // On user form interaction explicitly update URL.
    function onChange(type: ContractChangeType | null) {
        setSearchParams((prevParams) => {
            const params = new URLSearchParams(prevParams);
            if (type) {
                params.set(TYPE_PARAM, type);
            } else {
                params.delete(TYPE_PARAM);
            }
            return params;
        });
    }

    return {
        form,
        changeType,
        fieldName: "changeType",
        onChange,
    };
}
