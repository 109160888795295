import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { Form, FormErrorBox } from "core/hookForm";
import i18n from "core/i18n";
import useVehicleSubjectChangeForm from "./hooks/useVehicleSubjectChangeForm";

import StepSubjects from "./components/StepSubjects";
import StepSignature from "./components/StepSignature";
import StepDocuments from "./components/StepDocuments";
import StepConfirmation from "./components/StepConfirmation";
import vehicleSubjectChange from "serverApi/vehicleSubjectChange";

export default function VehicleSubjectChangeForm() {
    const { form, contractNumber, step, vehicles, nextStep, previousStep } = useVehicleSubjectChangeForm();
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();

    const [isFeasible, setIsFeasible] = useState<boolean>(undefined);

    useEffect(() => {
        async function fetchIsFeasible() {
            try {
                const [error, res] = await vehicleSubjectChange.isFeasible(idObject);

                setIsFeasible(error ? false : res.body.isFeasible);
            } catch (err) {
                console.error(err);
            }
        }

        fetchIsFeasible();
    });

    if (isFeasible === true) {
        return (
            <Form form={form} onSubmit={null}>
                <Box mb={3} fullWidth>
                    <Typography variant="h3">
                        {t("vehicle.change.subject.title")} {contractNumber}
                    </Typography>
                </Box>

                <FormErrorBox />

                {step === 1 && <StepSubjects vehicles={vehicles} nextStep={nextStep} />}
                {step === 2 && <StepSignature nextStep={nextStep} previousStep={previousStep} />}
                {step === 3 && <StepDocuments nextStep={nextStep} />}
                {step === 4 && <StepConfirmation />}
            </Form>
        );
    } else if (isFeasible === false) {
        return (
            <ContentBox mt={3}>
                <Typography variant={"b"}>{t("vehicle.change.notFeasible.edit")}</Typography>
            </ContentBox>
        );
    }

    return <span>...</span>;
}
