import { model } from "core/util";
import { ContractMetadata as ContractMetadataType } from "types";

export const ContractMetadata = model.createModel<ContractMetadataType, ContractMetadataType>((json) => ({
    idObject: json.idObject,

    contractNumber: json.contractNumber,
    countryCode: json.countryCode,

    idEnuProduct: json.idEnuProduct,
    idEnuProductGroup: json.idEnuProductGroup,
    idEnuProductGroupLvl2: json.idEnuProductGroupLvl2,

    productGroup: json.productGroup,
    productGroupLvl2: json.productGroupLvl2,
    productCrm: json.productCrm,

    isUniqaContract: json.isUniqaContract,
    isActive: json.isActive,

    beginDate: json.beginDate,
    endDate: json.endDate,
}));
