import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AgentOverviewGridItem, ContractDocumentsOverviewGridItem, HolderOverviewGridItem } from "containers/contract";
import router from "core/router";
import userContracts from "core/userContracts";

import { IndividualPersonType } from "enums";
import { Pages, Tabs } from "routeConstants";
import { GridLayout } from "ui-library/layouts";

import { InvestmentsOverviewGridItem, PensionContractOverviewGridItem } from "../containers";
import { ENTITY_CONTRACT_DOCUMENTS } from "../constants";
import { getPensionAccountDetail, getPolicyHolder } from "../selectors";

export const OverviewTab: FC = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const holder = useSelector(getPolicyHolder);

    return (
        <GridLayout defaultSpacing>
            {/*<PensionFinancialOperationsOverviewGridItem*/}
            {/*    idObject={idObject}*/}
            {/*    pensionType={PensionTypeEnum.DSS}*/}
            {/*    financialOperationsEntityName={ENTITY_FINANCIAL_OPERATIONS}*/}
            {/*    paymentDataEntityName={ENTITY_PAYMENT_INFORMATION}*/}
            {/*    detailLink={router.getStaticUrl(Pages.CONTRACT_DSS, FINANCIAL_OPERATIONS_TAB, {idObject})}*/}
            {/*/>*/}

            <InvestmentsOverviewGridItem detailLink={router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.FINANCIAL_STATEMENT, { idObject })} />

            <PensionContractOverviewGridItem
                contract={contract}
                pensionAccountDetail={pensionAccountDetail}
                detailLink={router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.DETAIL, { idObject })}
            />

            <HolderOverviewGridItem
                holder={holder}
                individualPersonType={IndividualPersonType.SAVER}
                detailLink={router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.HOLDER, { idObject })}
            />

            <ContractDocumentsOverviewGridItem
                variant={"LONG"}
                idObject={Number(idObject)}
                entityName={ENTITY_CONTRACT_DOCUMENTS}
                detailLink={router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.DOCUMENTS, { idObject })}
            />

            <AgentOverviewGridItem
                idObject={Number(idObject)}
                detailLink={router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.AGENT, { idObject })}
            />
        </GridLayout>
    );
};
