import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form/immutable";

import { GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { emailCharacters, emailRegex, Field, getFormFieldValue, InputText, max50Characters } from "core/form";
import { FieldSize } from "enums";

import { SubmitButton } from "../components";
import { EMAIL_FIELD, SUBMIT_TYPE_FIELD } from "../constants";
import { SubmitType } from "../types";
import { getIsAnyContractWithDisabledElComSelected, getIsAnyContractWithEnabledElComSelected } from "../selectors";
import { createSubmitElComChangeAction } from "../createActions";

export interface SectionEditElectronicCommunicationProps {
    formName: string;
    isFormBeingSubmitted: boolean;
    contractsOverviewsEntityName: string;
}

export const SectionEditElectronicCommunication: FC<SectionEditElectronicCommunicationProps> = ({
    formName,
    isFormBeingSubmitted,
    contractsOverviewsEntityName,
}) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const formEmail = useSelector(getFormFieldValue(formName, EMAIL_FIELD));
    const isEmailCorrect = emailRegex.test(formEmail);

    const isAnyContractWithDisabledElComSelected = useSelector(
        getIsAnyContractWithDisabledElComSelected(formName, contractsOverviewsEntityName),
    );
    const isAnyContractWithEnabledElComSelected = useSelector(
        getIsAnyContractWithEnabledElComSelected(formName, contractsOverviewsEntityName),
    );

    const onSubmitClick = (submitType: SubmitType) => () => {
        dispatch(change(formName, SUBMIT_TYPE_FIELD, submitType));
        dispatch(createSubmitElComChangeAction(formName, submitType));
    };

    return (
        <GridLayout spacing={1}>
            <GridItem xs={8}>
                <GridLayout spacing={1} direction="column">
                    <GridItem>
                        <Field
                            name={EMAIL_FIELD}
                            component={InputText}
                            tooltip={t("form.clientDataOnContracts.editElectronicCommunication.info")}
                            label={t("holder.emailAddress")}
                            normalize={[emailCharacters, max50Characters]}
                            preventDefaultNormalization
                            size={FieldSize.SMALL}
                            colourWhenChanged
                            displayErrorAsPopover
                        />
                    </GridItem>
                    <GridItem>
                        <GridLayout spacing={1}>
                            <GridItem xs={6}>
                                <SubmitButton
                                    onClick={onSubmitClick(SubmitType.DISABLE_ON_ALL_CONTRACTS)}
                                    title={t("form.clientDataOnContracts.editElectronicCommunication.disableOnAllContracts")}
                                    color="red"
                                    disabled={!isEmailCorrect || isFormBeingSubmitted}
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <SubmitButton
                                    onClick={onSubmitClick(SubmitType.DISABLE_ON_SELECTED_CONTRACTS)}
                                    title={t("form.clientDataOnContracts.editElectronicCommunication.disableOnSelectedContracts")}
                                    color="orange"
                                    disabled={!isEmailCorrect || !isAnyContractWithEnabledElComSelected || isFormBeingSubmitted}
                                />
                            </GridItem>
                        </GridLayout>
                    </GridItem>
                </GridLayout>
            </GridItem>
            <GridItem xs={4}>
                <GridLayout spacing={1} direction="column">
                    <GridItem>
                        <SubmitButton
                            onClick={onSubmitClick(SubmitType.ENABLE_ON_ALL_CONTRACTS)}
                            title={t("form.clientDataOnContracts.editElectronicCommunication.enableOnAllContracts")}
                            disabled={!isEmailCorrect || isFormBeingSubmitted}
                            additionalStyle={{ marginTop: 15 }}
                        />
                    </GridItem>
                    <GridItem>
                        <SubmitButton
                            onClick={onSubmitClick(SubmitType.ENABLE_ON_SELECTED_CONTRACTS)}
                            title={t("form.clientDataOnContracts.editElectronicCommunication.enableOnSelectedContracts")}
                            disabled={!isEmailCorrect || !isAnyContractWithDisabledElComSelected || isFormBeingSubmitted}
                        />
                    </GridItem>
                </GridLayout>
            </GridItem>
        </GridLayout>
    );
};
