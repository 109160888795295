import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";
import { EditClientDataTab } from "./tabs";

export { CrmPage } from "./Container";

export const CrmTabs = {
    EditClientDataTab,
};

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
