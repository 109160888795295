import React, { FC, ReactNode } from "react";
import { Field, Form } from "core/hookForm";
import { Grid } from "@mui/material";

import { Box, ContentBox, GridItem } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { useGetSiteValue, useHasRole } from "core/hooks";
import { Option } from "core/hookForm/components/Field";
import { ExternalUrl, Roles } from "enums";

export interface ChangesTabProps {
    form: any;
    changeOptions: Option[];
    children: ReactNode;
    onChange?: (type: string) => void;
}

export const ContractChangesTab: FC<ChangesTabProps> = ({ form, changeOptions, children, onChange }) => {
    const { t } = i18n.useTranslation();
    const getSiteValue = useGetSiteValue();
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const introductionText = isAuthenticated
        ? t("change.tab.loggedUserNote", { link: getSiteValue(ExternalUrl.E_REGISTRY_DISPATCH) })
        : t("change.tab.oneTimeUserNote", { link: getSiteValue(ExternalUrl.E_REGISTRY_DISPATCH) });

    return (
        <>
            <Grid
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "flex-start",
                }}
            >
                <GridItem xs={12}>
                    <ContentBox fullWidth>
                        <Typography markDown={introductionText} />
                    </ContentBox>
                </GridItem>

                <GridItem xs={12}>
                    <Form form={form} onSubmit={null} prompt={false}>
                        <Box fullWidth mb={3}>
                            <Field
                                input="select"
                                name="changeType"
                                placeholder={t("vehicle.change.type")}
                                label={t("vehicle.change.type")}
                                options={changeOptions}
                                onChange={onChange}
                            />
                        </Box>
                    </Form>
                    {children}
                </GridItem>
            </Grid>
        </>
    );
};
