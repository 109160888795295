import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { DatePicker, Field, getFormFieldValue, isAfterOrSame, isBeforeOrSame, required } from "core/form";
import { localFullDate } from "core/formatters";
import { VehicleCancellationReason } from "types";
import { date } from "core/util";

import { FIELD_CANCELLATION_DATE, FIELD_CANCELLATION_REASON, SECTION_MARGIN } from "../../constants";
import { getCancellationDateTitle } from "../../util";

const EDITABLE_DATE_REASONS_CZ = [
    VehicleCancellationReason.BY_AGREEMENT,
    VehicleCancellationReason.REMOVED_FROM_REGISTER,
    VehicleCancellationReason.STOLEN_VEHICLE,
    VehicleCancellationReason.INSURER_DEATH,
];

const EDITABLE_DATE_REASONS_SK = [
    VehicleCancellationReason.BY_AGREEMENT,
    VehicleCancellationReason.REMOVED_FROM_REGISTER,
    VehicleCancellationReason.STOLEN_VEHICLE,
    VehicleCancellationReason.INSURER_DEATH,
    VehicleCancellationReason.CHANGE_OF_OWNERSHIP,
];

type ChangeDateFieldDefinition = {
    minDate?: Date | number;
    maxDate?: Date | number;
    validateStatic: Array<(value: any) => boolean | string>;
};

const getChangeDateFieldDefinition = (reason: VehicleCancellationReason): ChangeDateFieldDefinition => {
    switch (reason) {
        case VehicleCancellationReason.BY_AGREEMENT:
            return {
                minDate: date.plusFiveDays,
                maxDate: undefined,
                validateStatic: [required, isAfterOrSame(date.plusFiveDays)],
            };
        default:
            return {
                minDate: undefined,
                maxDate: Date.now(),
                validateStatic: [required, isBeforeOrSame(Date.now())],
            };
    }
};

type ContactPersonSectionProps = {
    formName: string;
};

export const ChangeDateSection: FC<ContactPersonSectionProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();
    const selectedCancellationReason = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_REASON));

    const cancellationDate = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_DATE));
    const title = getCancellationDateTitle(t, selectedCancellationReason);

    const isSlovakSite = useSelector(pageContext.getIsSlovakSite);
    const reasonsWithEditableDate = isSlovakSite ? EDITABLE_DATE_REASONS_SK : EDITABLE_DATE_REASONS_CZ;

    const { minDate, maxDate, validateStatic } = getChangeDateFieldDefinition(selectedCancellationReason);

    if (reasonsWithEditableDate.includes(selectedCancellationReason)) {
        return (
            <ContentBox fullWidth mb={SECTION_MARGIN}>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={FIELD_CANCELLATION_DATE}
                            component={DatePicker}
                            label={title}
                            validateStatic={validateStatic}
                            minDate={minDate}
                            maxDate={maxDate}
                            defaultCalendarMonth={minDate}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
        );
    }

    return (
        <>
            <ContentBox fullWidth mb={SECTION_MARGIN}>
                <Typography variant="h4" markDown={title} />
                <Typography markDown={localFullDate(cancellationDate)} />
            </ContentBox>
        </>
    );
};
