import { HASH_PLACEHOLDER } from "app/constants";

import { Pages, Tabs } from "routeConstants";

export const pageRoutes = {
    [Pages.LOGIN]: "/login",
    [Pages.REGISTRATION]: "/registration",
    [Pages.RESET_PASSWORD]: "/reset-password",
    [Pages.IMPERSONATE]: "/impersonate",
    [Pages.DISCLAIMER]: "/disclaimer",
    [Pages.CONTACT]: "/contact",
    [Pages.CONTRACTS]: "/contracts",
    [Pages.CONTRACT_HOME]: "/contract/home/:idObject",
    [Pages.CONTRACT_LIFE]: "/contract/life/:idObject",
    [Pages.CONTRACT_DIRECT]: "/contract/mutual-funds-direct/:idObject",
    [Pages.CONTRACT_REGULAR]: "/contract/mutual-funds-regular/:idObject",
    [Pages.CONTRACT_DDS]: "/contract/dds/:idObject",
    [Pages.CONTRACT_DPS]: "/contract/dps/:idObject",
    [Pages.CONTRACT_DSS]: "/contract/dss/:idObject",
    [Pages.CONTRACT_PP]: "/contract/pp/:idObject",
    [Pages.CONTRACT_VEHICLE]: "/contract/vehicle/:idObject",
    [Pages.CONTRACT_TRAVEL]: "/contract/travel/:idObject",
    [Pages.CLAIMS]: "/claims",
    [Pages.INCIDENT_DETAIL]: "/incident/:incidentId",
    [Pages.ONLINE_PAYMENT]: "/payment",
    [Pages.MESSAGES]: "/messages",
    // TODO fix problem with router (startsWith match)
    [Pages.ONLINE_PAYMENT_NOTIFICATION]: "/online-payment-notification/:requestId",
    [Pages.USER]: "/user",
    [Pages.EPIN_EXTERNAL]: "/resendpin",
    [Pages.NOTIFICATION]: "/notification",
    [Pages.CALL_CENTER]: "/call-center",
    [Pages.CRM]: "/crm",
    [Pages.GARAGE]: "/garage",
    [Pages.USE_CASE]: "/use-case",
    [Pages.CAMPAIGN]: "/campaign",
};

export const innerRoutes = {
    [Tabs.OVERVIEW]: "overview",
    [Tabs.HOLDER]: "policy-holder",
    [Tabs.DETAIL]: "detail",
    [Tabs.CHANGES]: "changes",
    [Tabs.CLAIMS]: "claims",
    [Tabs.PAYMENTS]: "payments",
    [Tabs.FINANCIAL_OPERATIONS]: "financial-operations",
    [Tabs.FINANCIAL_STATEMENT]: "financial-statement",
    [Tabs.DOCUMENTS]: "documents",
    [Tabs.REDEEM_SAVINGS]: "redeem-savings",
    [Tabs.AGENT]: "agent",
    [Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM]: "edit-electronic-communication",
    [Tabs.PAYMENTS_PAY]: "pay",
    [Tabs.PENSION_BENEFICIARIES_FORM]: "edit-pension-beneficiaries",
    [Tabs.REPORT_CLAIM]: "report-new-claim",
    [Tabs.REPORT_CLAIM_OLD]: "report-claim",
    [Tabs.CLAIMS_LIST]: "claims-list",
    [Tabs.PORTFOLIO]: "portfolio",
    [Tabs.ANNOUNCEMENTS_TAB]: "announcements",
    [Tabs.REQUESTS]: "requests",
    [Tabs.PIS]: "pis",
    [Tabs.DIRECT_PROFIT_DETAIL]: "profit-detail/:fund",
    [Tabs.REGULAR_PROFIT_DETAIL]: "profit-detail/:fund",
    [Tabs.SELL_SWITCH_FORM]: "funds-sell-switch",
    [Tabs.SELL_SWITCH_FORM_WITH_ACTION]: "sell-switch/:requestId/:actionType",
    [Tabs.INVESTMENT_PROFILE_FORM]: "investment-profile",
    [Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_BUY]: "statements-financial-operation-detail-buy/:operationId",
    [Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_SELL]: "statements-financial-operation-detail-sell/:operationId",
    [Tabs.EDIT_POLICY_HOLDER_FORM]: "edit-policy-holder",
    [Tabs.PENSION_DSS_FUNDS_CHANGE_FORM]: "change-funds/:changeType",
    [Tabs.PENSION_DDS_FUNDS_CHANGE_FORM]: "change-funds",
    [Tabs.PENSION_REDEEM_SAVINGS_FORM]: "redeem-savings-application",
    [Tabs.USER_ACCOUNT]: "user-account",
    [Tabs.VERIFICATION]: "verification",
    [Tabs.UNVERIFIED_USER]: "unverified",
    [Tabs.USER_VERIFICATION_FORM]: "edit-verify",
    [Tabs.DATA_PROCESSING]: "data-processing",
    [Tabs.EPIN_EXTERNAL_HASH]: `${HASH_PLACEHOLDER}`,
    [Tabs.IMPERSONATE_UUID]: ":uuid",
    [Tabs.NOTIFICATION_CHANGE_PASSWORD_SUCCESS]: "change-password-success",
    [Tabs.NOTIFICATION_CHANGE_USERNAME_SUCCESS]: "change-username-success",
    [Tabs.UC_CHANGE]: "change",
    [Tabs.UC_CLAIM]: "claim",
    [Tabs.ELECTRONIC_COMMUNICATION]: "electronic-communication/:guid/:idObject",
    [Tabs.ELECTRONIC_COMMUNICATION_SUCCESS]: "electronic-communication/success",
    [Tabs.FORM_EDIT_CLIENT]: "edit-client/:idClient",
};
