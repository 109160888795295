import React, { FC } from "react";

import { Box } from "ui-library/layouts";

import i18n from "core/i18n";

import { BlockTitle } from "../../components";
import { PERMANENT_ADDRESS_SECTION } from "../../constants";

import { AddressFields } from "./fields";

export interface PermanentAddressFormBoxProps {
    formName: string;
    isCompany: boolean;
}

export const PermanentAddressFormBox: FC<PermanentAddressFormBoxProps> = ({ formName, isCompany }) => {
    const { t } = i18n.useTranslation();

    return (
        <Box mb={0.5}>
            <BlockTitle title={isCompany ? t("holder.companyAddress") : t("holder.permanentAddress")} />
            <AddressFields formName={formName} sectionName={PERMANENT_ADDRESS_SECTION} />
        </Box>
    );
};
