import { createSelector } from "reselect";

import entity from "core/entity";
import i18n from "core/i18n";
import { createGetChangedFieldsList, getFormFieldValue, getFormFieldValueWithState, isFieldDirty } from "core/form";
import { IdType } from "enums";
import { DataLoadingsStatusEnum } from "types";

import {
    ARE_ADDRESSES_IDENTICAL_FIELD,
    CONTACT_ADDRESS_SECTION,
    PERMANENT_ADDRESS_SECTION,
    SELECTED_CONTRACTS_IDS_FIELD,
    TYPE_OF_DATA_CHANGE_FIELD,
    VISIBLE_CHANGED_FIELDS,
} from "./constants";
import { DataChangeType } from "./types";

export const isIdentificationCardIdDirty = (state, form) => isFieldDirty(state, form, "identificationCard.cardId");
export const isIdentificationCardIdDirtyCallback = (form) => (state) => isFieldDirty(state, form, "identificationCard.cardId");

export const isIdentificationTypeDirty = (state, form) => isFieldDirty(state, form, "identificationCard.identification");

export const getIsAnyClientDataValueChanged = (formName) => (state) => {
    const changeClientDataFields = createGetChangedFieldsList(VISIBLE_CHANGED_FIELDS, formName)(state);
    return changeClientDataFields && changeClientDataFields.length > 0;
};

export const getIsAnyContractSelected = (formName) => (state) => {
    const selectedContractIds = getFormFieldValueWithState(state, formName, SELECTED_CONTRACTS_IDS_FIELD);
    return selectedContractIds && selectedContractIds.size > 0;
};

export const getIsAnyContractWithEnabledElComSelected = (formName, contractsOverviewsEntityName) => (state) => {
    const selectedContractIds = getFormFieldValueWithState(state, formName, SELECTED_CONTRACTS_IDS_FIELD);
    const contractsMetadataWithState = entity.getDataSelector(state, contractsOverviewsEntityName, {});

    if (!selectedContractIds) {
        return false;
    }

    const contractWithEnabledElCom = selectedContractIds
        .map((id) => contractsMetadataWithState[id])
        .filter((dataWithState) => dataWithState?.state === DataLoadingsStatusEnum.LOADED)
        .filter((dataWithState) => dataWithState.data.electronicCommunication?.electronicCommunicationSet);

    return contractWithEnabledElCom && contractWithEnabledElCom.size > 0;
};

export const getIsAnyContractWithDisabledElComSelected = (formName, contractsOverviewsEntityName) => (state) => {
    const selectedContractIds = getFormFieldValueWithState(state, formName, SELECTED_CONTRACTS_IDS_FIELD);
    const contractsMetadataWithState = entity.getDataSelector(state, contractsOverviewsEntityName, {});

    if (!selectedContractIds) {
        return false;
    }

    const contractWithDisabledElCom = selectedContractIds
        .map((id) => contractsMetadataWithState[id])
        .filter((dataWithState) => dataWithState?.state === DataLoadingsStatusEnum.LOADED)
        .filter((dataWithState) => !dataWithState.data.electronicCommunication?.electronicCommunicationSet);

    return contractWithDisabledElCom && contractWithDisabledElCom.size > 0;
};

// export const getAddressesIdentical = (state, formName) =>
//     getFormFieldValue(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`)(state);
export const getAddressesIdentical = (state, formName) => getFormFieldValue(formName, ARE_ADDRESSES_IDENTICAL_FIELD)(state);

export const getPermanentAddressCallback = (formName) => (state) => getFormFieldValueWithState(state, formName, PERMANENT_ADDRESS_SECTION);

export const getShouldValidateSection = (formName, sectionName) =>
    createSelector(
        getFormFieldValue(formName, TYPE_OF_DATA_CHANGE_FIELD),
        createGetChangedFieldsList(VISIBLE_CHANGED_FIELDS, formName),
        (typeOfDataChange, changedFieldsList) =>
            typeOfDataChange === DataChangeType.ALL || changedFieldsList.map((item) => item?.field).includes(sectionName),
    );

export const getTranslatedIdTypeOptions = i18n.createGetLocalizedEnumOptions(IdType);
