import fn from "core/util/fn";
import { emailRegex } from "core/form";
import { TFunction } from "core/i18n";
import { Address, ElectronicCommunication } from "types";

import { CompareState, ElComState } from "./types";

export const getIsElectronicCommunicationEnabled = (electronicCommunication: ElectronicCommunication) => {
    return (
        !fn.isEmpty(electronicCommunication) &&
        !fn.isEmpty(electronicCommunication.electronicCommunicationSet) &&
        electronicCommunication.electronicCommunicationSet === true
    );
};

export const getAddressByTypeCode = (addresses: Address[], typeCode: string): Address => {
    const addressItem = addresses && addresses.find((item) => item.addressTypeCode === typeCode);
    if (!addressItem) {
        return null;
    }
    return {
        addressTypeCode: addressItem.addressTypeCode,
        street: addressItem.street,
        orientationNumber: addressItem.orientationNumber,
        descriptionNumber: addressItem.descriptionNumber,
        city: addressItem.city,
        postalCode: addressItem.postalCode,
        countryCode: addressItem.countryCode,
    };
};

export const isAddressEmpty = (address: Address) => {
    return fn.isEmpty(address) || fn.isEmpty(address.city || fn.isEmpty(address.street));
};

export const isAddressEqual = (address1: Address, address2: Address) => {
    return (
        compareSafeWithoutWhitespaces(address1.countryCode, address2.countryCode) &&
        compareSafeWithoutWhitespaces(address1.city, address2.city) &&
        compareSafeWithoutWhitespaces(address1.street, address2.street) &&
        compareSafeWithoutWhitespaces(address1.descriptionNumber, address2.descriptionNumber) &&
        compareSafeWithoutWhitespaces(address1.orientationNumber, address2.orientationNumber) &&
        compareSafeWithoutWhitespaces(address1.postalCode, address2.postalCode)
    );
};

export const compareAddress = (clientAddress: Address, contractAddress: Address) => {
    if (isAddressEmpty(clientAddress) && isAddressEmpty(contractAddress)) {
        return CompareState.BOTH_MISSING;
    } else if (isAddressEmpty(clientAddress)) {
        return CompareState.CLIENT_MISSING;
    } else if (isAddressEmpty(contractAddress)) {
        return CompareState.CONTRACT_MISSING;
    } else {
        return isAddressEqual(clientAddress, contractAddress) ? CompareState.SAME : CompareState.DIFFERENT;
    }
};

export const compareEmailPlain = (valueOnClient: string, valueOnContract: string) => {
    if (fn.isEmpty(valueOnClient) && fn.isEmpty(valueOnContract)) {
        return CompareState.BOTH_MISSING;
    } else if (fn.isEmpty(valueOnClient)) {
        return CompareState.CLIENT_MISSING;
    } else if (fn.isEmpty(valueOnContract) || !emailRegex.test(valueOnContract)) {
        return CompareState.CONTRACT_MISSING;
    } else {
        return compareSafeWithoutWhitespaces(valueOnClient, valueOnContract) ? CompareState.SAME : CompareState.DIFFERENT;
    }
};


export const comparePlain = (valueOnClient: string, valueOnContract: string) => {
    if (fn.isEmpty(valueOnClient) && fn.isEmpty(valueOnContract)) {
        return CompareState.BOTH_MISSING;
    } else if (fn.isEmpty(valueOnClient)) {
        return CompareState.CLIENT_MISSING;
    } else if (fn.isEmpty(valueOnContract)) {
        return CompareState.CONTRACT_MISSING;
    } else {
        return compareSafeWithoutWhitespaces(valueOnClient, valueOnContract) ? CompareState.SAME : CompareState.DIFFERENT;
    }
};

export const getElCommunicationState = (emailComparation: CompareState, electronicCommunication: ElectronicCommunication): ElComState => {
    const isElComEnabled = getIsElectronicCommunicationEnabled(electronicCommunication);
    if (!isElComEnabled) {
        return ElComState.UNSET;
    }
    return emailComparation === CompareState.SAME ? ElComState.SET : ElComState.SET_DIFFERENT_EMAIL;
};

export const getElComValueBasedOnState = (t: TFunction, elComState: ElComState) => {
    switch (elComState) {
        case ElComState.SET:
            return t("common.yes");
        case ElComState.SET_DIFFERENT_EMAIL:
            return t("common.yesDifferentEmail");
        default:
            return t("common.no");
    }
};

const compareSafeWithoutWhitespaces = (value1: string, value2: string): boolean => {
    if (fn.isEmpty(value1) || fn.isEmpty(value2)) {
        return value1 === value2;
    }

    return removeWhitespacesAndCapitalization(value1) === removeWhitespacesAndCapitalization(value2);
};

const removeWhitespacesAndCapitalization = (value: string): string => {
    if (fn.isEmpty(value)) {
    }

    return value.replace(/\s/g, "").toLowerCase();
};
