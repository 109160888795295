import { useSelector } from "react-redux";
import type { Location } from "@remix-run/router";

import router from "core/router";
import user from "core/user";
import { AGENT_ACCESSIBLE_PAGES, EMPLOYEE_ACCESSIBLE_PAGES } from "app/constants";
import { Roles } from "enums";

export const useCanAccessLocation: (location: Location) => boolean = (location: Location) => {
    const { pathname, search } = location;
    const pageInformation = router.parseLocation(pathname, search);

    const { roles } = useSelector(user.getUserAccount);

    if (roles?.includes(Roles.AGENT)) {
        return AGENT_ACCESSIBLE_PAGES.includes(pageInformation?.name);
    } else if (roles?.includes(Roles.EMPLOYEE)) {
        return EMPLOYEE_ACCESSIBLE_PAGES.includes(pageInformation?.name);
    }

    return true;
};
