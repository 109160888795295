import { FC, PropsWithChildren } from "react";
import { Chip, Image, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledContractCard, StyledContractWrapper } from "ui-library/Styled";
import useBreakpoints from "ui-library/theme/breakpoints";
import { DroppableContractCard } from "./DroppableContractCard";
import { ADAPTIVE, ViewType, ViewVariant } from "ui-library/modules/enums";

export interface ContractCardProps extends PropsWithChildren {
    title: string;
    alertMessage?: string;
    description?: string;
    chipLabel?: string;
    action: any;
    actionStretch?: boolean;
    imageUrl?: string;
    imageDesatured?: boolean;
    variant?: ViewVariant;
    padding?: number;
    subTitleSection?: any;
    contractNumber?: string;
    droppable?: boolean;
}

export const ContractCard: FC<ContractCardProps> = ({
    contractNumber,
    variant = ADAPTIVE,
    title,
    alertMessage,
    description,
    children,
    actionStretch,
    action,
    chipLabel,
    imageUrl,
    imageDesatured,
    padding = 24,
    subTitleSection,
    droppable,
    ...props
}) => {
    const isBreakMD = useBreakpoints("md");

    //if card is adaptive it will change variant based on screen
    if (variant === ADAPTIVE) {
        variant = isBreakMD ? ViewType.BASE : ViewType.TILES;
    }

    if (droppable) {
        return (
            <DroppableContractCard
                contractNumber={contractNumber}
                title={title}
                chipLabel={chipLabel}
                description={description}
                action={action}
                alertMessage={alertMessage}
            >
                {children}
            </DroppableContractCard>
        );
    }

    if (variant === ViewType.TILES) {
        return (
            <StyledContractCard minWidth={"277px"} flex={"1"} variant="white" shadow padding {...props}>
                <StyledContractWrapper display={actionStretch && "flex"} flexDirection={actionStretch && "column"}>
                    <Flexbox flexDirection={"column"} alignItems="flex-start" pb={{ xs: 3 }}>
                        <Box mb={1.5}>{chipLabel && <Chip label={chipLabel} bgColor="blue" />}</Box>
                        <Box pb={{ xs: 1, md: 0 }} pr={{ xs: 0, md: 3 }}>
                            {title && <Typography variant="h3">{title}</Typography>}
                            {description && (
                                <Typography color={"gray"} variant="p">
                                    {description}
                                </Typography>
                            )}
                        </Box>
                    </Flexbox>
                    {children && <Box pb={4}>{children}</Box>}

                    {action && (
                        <Box flex={actionStretch && 1} display={actionStretch && "flex"} alignItems={actionStretch && "end"}>
                            {action}
                        </Box>
                    )}
                </StyledContractWrapper>
            </StyledContractCard>
        );
    }

    if (!isBreakMD) {
        return null;
    }

    return (
        <StyledContractCard width={"100%"} variant="white" $padding={padding} shadow {...props}>
            <StyledContractWrapper>
                <Flexbox
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={"space-between"}
                    alignItems="flex-start"
                    pb={{ xs: 3 }}
                >
                    <Box pb={{ xs: 1, md: 0 }} pr={{ xs: 0, md: 3 }}>
                        {title && <Typography variant="h3">{title}</Typography>}
                        {description && (
                            <Typography color={"gray"} variant="p">
                                {description}
                            </Typography>
                        )}
                        {subTitleSection && <Box>{subTitleSection}</Box>}
                    </Box>
                    <Box mb={1.5}>{chipLabel && <Chip label={chipLabel} bgColor="blue" />}</Box>
                </Flexbox>
                {children && <Box pb={{ xs: 2, md: 3 }}>{children}</Box>}
                {action && action}
            </StyledContractWrapper>
            {imageUrl && (
                <Image
                    desatured={imageDesatured}
                    objectFit={"fill"}
                    shadow={false}
                    src={imageUrl}
                    srcSet={""}
                    alt={title}
                    borderRadius={0}
                />
            )}
        </StyledContractCard>
    );
};

export default ContractCard;
