import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import errorHandling from "core/errorHandling";
import useFormRequest from "core/hookForm/hooks/useFormRequest";
import userContracts from "core/userContracts";
import { HttpStatusCode } from "enums";
import { pensionDdsApi } from "serverApi";

import { triggerReloadContractDetail } from "../../../actions";
import { FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS } from "../../../constants";
import { getIsActiveOperationsSet } from "../../../selectors";

import { ActiveOperationsOperationTypeEnum, FormState } from "../enums";

export type ActiveOperationsFormValues = {
    errors?: string[];
    consentGiven?: boolean;
    operationType?: string;
    otpCode?: string;
    otpId?: string;
    otpPhone?: string;
};

export function useActiveOperationsForm() {
    const isActiveOperationsSet = useSelector(getIsActiveOperationsSet);

    const initialValues: ActiveOperationsFormValues = {
        consentGiven: false,
        operationType: isActiveOperationsSet
            ? ActiveOperationsOperationTypeEnum.ACTIVE_OPERATIONS_UNSET
            : ActiveOperationsOperationTypeEnum.ACTIVE_OPERATIONS_SET,
    };

    const validationSchema = yup
        .object({
            consentGiven: yup.boolean().required(),
            operationType: yup.string().required(),
        })
        .required();

    const form = useForm<ActiveOperationsFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const { setValue, handleSubmit } = form;

    const dispatch = useDispatch();
    const { idObject } = useParams();
    const { contractNumber } = userContracts.useGetContractByUrlParam();

    // On form initialization we need to reset service error.
    useEffect(() => {
        dispatch(errorHandling.removeServiceErrors(FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS));
    }, []);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isOtpResent, setIsOtpResent] = useState(false);
    const [formState, setFormState] = useState(FormState.EDIT);

    const handleRequest = useFormRequest(form);

    async function onSubmit(data: ActiveOperationsFormValues) {
        const onError = () => {
            console.log("onError");
            // dispatch(errorHandling.addServiceError(FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS, null));
            setIsSubmitting(false);
        };

        const onSuccess = (response) => {
            if (response.statusCode === HttpStatusCode.ACCEPTED) {
                setValue("otpId", response.body.otp_id);
                setValue("otpPhone", response.body.phone_number);
                setFormState(FormState.OTP_CHALLENGE);
            }

            if (response.statusCode === HttpStatusCode.OK) {
                // Refresh contract data
                dispatch(triggerReloadContractDetail());
                setFormState(FormState.SUBMITTED);
            }

            setIsSubmitting(false);
        };

        setIsSubmitting(true);
        const dto = getSubmitDataDto(data);
        await handleRequest(() => pensionDdsApi.updateActiveOperations(dto), onError, onSuccess);
    }

    function handleOptResend() {
        setIsOtpResent(true);
        setValue("otpId", null);
        setValue("otpPhone", null);
        setValue("otpCode", null);
        // Call form submission after clearing otpId = new OTP submission.
        handleSubmit(onSubmit)();
    }

    function getSubmitDataDto(data: ActiveOperationsFormValues) {
        return {
            idObject: Number(idObject),
            operationType: data.operationType,
            consentGiven: data.consentGiven,
            otpId: data.otpId,
            otpCode: data.otpCode,
            isSet: true,
        };
    }

    return {
        form,
        onSubmit,
        contractNumber,
        handleSubmit,
        isSubmitting,
        isOtpResent,
        formState,
        handleOptResend,
    };
}
