import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { List, Set } from "immutable";
import { change, untouch } from "redux-form/immutable";
import { initialize, stopSubmit } from "redux-form";
import { Selector } from "@reduxjs/toolkit";

import i18n from "core/i18n";
import modal from "core/modal";
import { snackbarUtils } from "app/layout/components";
import { AddressType } from "enums";
import { autocompleteApi, contractDataChangeApi } from "serverApi";
import { fetch, model } from "core/util";
import {
    emailRegex,
    fieldChangeMatcher,
    formWrapper,
    getFormFieldValue,
    getFormFieldValueWithState,
    scrollToFirstError,
    sectionChangeMatcher,
} from "core/form";
import autocomplete from "core/autocomplete";
import { PlaceDetail } from "model/autocomplete";
import { LegalEntity, State } from "types";

import {
    ARE_ADDRESSES_IDENTICAL_FIELD,
    CITY_FORM_FIELD,
    CLIENT_TYPE_COMPANY,
    CLIENT_TYPE_INDIVIDUAL,
    COMPANY_NAME_FIELD,
    CONTACT_ADDRESS_AUTOCOMPLETE,
    CONTACT_ADDRESS_SECTION,
    DESCRIPTION_NUMBER_FORM_FIELD,
    EMAIL_FIELD,
    FILES_FIELD,
    FIRST_NAME_FIELD,
    ID_CLIENT,
    LAST_NAME_FIELD,
    LOADING_E_COM_VIRTUAL_FIELD,
    MOBILE_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    OTP_CODE_FIELD,
    OTP_ID_FIELD,
    PERM_ADDRESS_AUTOCOMPLETE,
    PERMANENT_ADDRESS_SECTION,
    PERSON_SECTION,
    SELECTED_CONTRACTS_IDS_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    SUBMIT_TYPE_FIELD,
    TITLE_AFTER_FIELD,
    TITLE_BEFORE_FIELD,
    TYPE_OF_DATA_CHANGE_FIELD,
    ZIP_FORM_FIELD,
} from "./constants";
import { getAddressesIdentical, getShouldValidateSection } from "./selectors";
import { SubmitType } from "./types";
import { createSubmitElComChangeActionType, createToggleCopyActionType } from "./createActions";

const createSaga = (formName: string, getClientDataSelector: Selector<State, LegalEntity>, clientObjectsEntityName: string) => {
    return function* holderSaga() {
        yield call(formSaga(formName, getClientDataSelector, clientObjectsEntityName));
    };
};

const formSaga = (formName: string, getClientDataSelector: Selector<State, LegalEntity>, clientObjectsEntityName: string) =>
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, TYPE_OF_DATA_CHANGE_FIELD), untouchUnchangedFieldsForPartialDataChange, formName);

            // Auto complete data filling to fields.
            // @ts-ignore we won't type sagas
            yield takeEvery(autocomplete.selectMatcher(PERM_ADDRESS_AUTOCOMPLETE), placeSelectedSaga, formName, PERMANENT_ADDRESS_SECTION);
            // @ts-ignore we won't type sagas
            yield takeEvery(autocomplete.selectMatcher(CONTACT_ADDRESS_AUTOCOMPLETE), placeSelectedSaga, formName, CONTACT_ADDRESS_SECTION);

            // Submit of electronic communication change.
            // @ts-ignore we won't type sagas
            yield takeLatest(createSubmitElComChangeActionType(formName), submitElComChange, formName);

            yield takeLatest(createToggleCopyActionType(formName), toggleAddressesIdentitySaga, formName);

            // Copy perm address fields to contact address fields.
            // @ts-ignore we won't type sagas
            yield takeLatest(sectionChangeMatcher(formName, PERMANENT_ADDRESS_SECTION), copyPerAddressIfNeededSaga, formName);
        },
        *initialize() {
            const clientData: LegalEntity = yield select(getClientDataSelector);

            if (clientData) {
                const isCompany = !clientData.isPerson;
                const contactAddress = model.getPlainObjAddressByTypeCode(clientData.addresses, AddressType.CON);

                return {
                    [LOADING_E_COM_VIRTUAL_FIELD]: false,
                    [ID_CLIENT]: clientData.idSubject,
                    [FILES_FIELD]: List(),
                    clientType: isCompany ? CLIENT_TYPE_COMPANY : CLIENT_TYPE_INDIVIDUAL,
                    [PERSON_SECTION]: isCompany
                        ? undefined
                        : {
                              [FIRST_NAME_FIELD]: clientData.firstName,
                              [LAST_NAME_FIELD]: clientData.lastName,
                              [TITLE_BEFORE_FIELD]: clientData.titleBefore,
                              [TITLE_AFTER_FIELD]: clientData.titleAfter,
                          },
                    [COMPANY_NAME_FIELD]: isCompany ? clientData.companyName : undefined,
                    [EMAIL_FIELD]: clientData.email,
                    [MOBILE_FIELD]: clientData.phone,
                    // [IDENTIFICATION_CARD_SECTION]: isCompany ? undefined : getIdentificationCard(clientData.ids),
                    [PERMANENT_ADDRESS_SECTION]: model.getPlainObjAddressByTypeCode(clientData.addresses, AddressType.PER),
                    [CONTACT_ADDRESS_SECTION]: model.getPlainObjAddressByTypeCode(clientData.addresses, AddressType.CON),
                    [ARE_ADDRESSES_IDENTICAL_FIELD]: clientData.addressesIdentical,
                    //
                    // [CONTACT_ADDRESS_SECTION]: Object.assign(contactAddress, {
                    //     [ARE_ADDRESSES_IDENTICAL_FIELD]: clientData.addressesIdentical,
                    // }),
                    //
                    [SELECTED_CONTRACTS_IDS_FIELD]: Set(),
                };
            }
            return {};
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values) {
            const changeType: SubmitType = yield select(getFormFieldValue(formName, SUBMIT_TYPE_FIELD));
            if (changeType) {
                try {
                    const areAddressesMarkedAsIdentical = yield select(getAddressesIdentical, formName);
                    const data = values.set("addressesMarkedAsIdentical", areAddressesMarkedAsIdentical);
                    const idSubject = yield select(getFormFieldValue(formName, ID_CLIENT));

                    const result = yield call(contractDataChangeApi.updateClientData, idSubject, data);
                    return result ? { otpId: result.otp_id, phone: result.phone_number, formValues: values } : { formValues: values };
                } catch (e) {
                    if (e.status === fetch.PRECONDITION_FAILED) {
                        throw e;
                    } else {
                        yield put(change(formName, SUBMIT_TYPE_FIELD, null));
                        yield call(snackbarUtils.error, i18n.translateDirectly("form.clientDataOnContracts.error"));
                        yield put(modal.closeModalForm(formName));
                    }
                }
            }
        },
        *success(data) {
            const changeType: SubmitType = yield select(getFormFieldValue(formName, SUBMIT_TYPE_FIELD));

            if (changeType) {
                if (data?.otpId) {
                    yield put(change(formName, OTP_ID_FIELD, data?.otpId));
                    yield put(modal.openModalForm(formName));
                } else {
                    const submitType: SubmitType = yield select(getFormFieldValue(formName, SUBMIT_TYPE_FIELD));
                    yield put(change(formName, SUBMIT_TYPE_FIELD, null));
                    yield call(snackbarUtils.success, getSuccessMessage(submitType));

                    // Reset form
                    if (data?.formValues) {
                        const newData = data.formValues.delete(OTP_ID_FIELD).delete(OTP_CODE_FIELD);
                        yield put(initialize(formName, newData));
                    }

                    yield put(modal.closeModalForm(formName));
                }
            }
        },
    });

function* submitElComChange(formName: string) {
    yield put(change(formName, LOADING_E_COM_VIRTUAL_FIELD, true));
    try {
        const changeType: SubmitType = yield select(getFormFieldValue(formName, SUBMIT_TYPE_FIELD));
        const idSubject = yield select(getFormFieldValue(formName, ID_CLIENT));
        const selectedContractsIds = yield select(getFormFieldValue(formName, SELECTED_CONTRACTS_IDS_FIELD));
        const formEmail = yield select(getFormFieldValue(formName, EMAIL_FIELD));
        const otpId = yield select(getFormFieldValue(formName, OTP_ID_FIELD));
        const otpCode = yield select(getFormFieldValue(formName, OTP_CODE_FIELD));

        const data = {
            isChangeOnAllContracts: changeType === SubmitType.ENABLE_ON_ALL_CONTRACTS || changeType === SubmitType.DISABLE_ON_ALL_CONTRACTS,
            idObjects: selectedContractsIds,
            enabled: changeType === SubmitType.ENABLE_ON_ALL_CONTRACTS || changeType === SubmitType.ENABLE_ON_SELECTED_CONTRACTS,
            email: emailRegex.test(formEmail) ? formEmail : null,
            otpId,
            otpCode,
        };

        const result = yield call(contractDataChangeApi.updateClientContractElCom, idSubject, data);

        if (result) {
            yield put(change(formName, OTP_ID_FIELD, result.otp_id));
            yield put(modal.openModalForm(formName));
        } else {
            yield call(snackbarUtils.success, getSuccessMessage(changeType));
            yield put(change(formName, OTP_ID_FIELD, null));
            yield put(change(formName, OTP_CODE_FIELD, null));
            yield put(modal.closeModalForm(formName));
        }
    } catch (e) {
        if (e.status === fetch.PRECONDITION_FAILED) {
            yield call(handleResponseError, formName, e.response);
        } else {
            yield put(change(formName, SUBMIT_TYPE_FIELD, null));
            yield call(snackbarUtils.error, i18n.translateDirectly("form.clientDataOnContracts.error"));
            yield put(modal.closeModalForm(formName));
        }
    }
    yield put(change(formName, LOADING_E_COM_VIRTUAL_FIELD, false));
}

function* handleResponseError(formName: string, response) {
    const fieldErrors = response?.fieldErrors;
    const fieldError = fieldErrors && fieldErrors[0];
    const message = fieldError?.message;

    if (message) {
        yield put(
            stopSubmit(formName, {
                [OTP_CODE_FIELD]: [message],
            }),
        );
    }
}

function getSuccessMessage(changeType: SubmitType) {
    switch (changeType) {
        case SubmitType.CHANGE_CLIENT_DATA:
            return i18n.translateDirectly("form.clientDataOnContracts.success.dataChange");
        case SubmitType.ENABLE_ON_ALL_CONTRACTS:
        case SubmitType.ENABLE_ON_SELECTED_CONTRACTS:
            return i18n.translateDirectly("form.clientDataOnContracts.success.elComEnable");
        case SubmitType.DISABLE_ON_SELECTED_CONTRACTS:
        case SubmitType.DISABLE_ON_ALL_CONTRACTS:
            return i18n.translateDirectly("form.clientDataOnContracts.success.elComDisable");
    }
}

function* untouchUnchangedFieldsForPartialDataChange(formName: string) {
    yield delay(0);

    // Person section
    const shouldValidatePersonSection = yield select(getShouldValidateSection(formName, PERSON_SECTION));
    if (!shouldValidatePersonSection) {
        yield put(
            untouch(
                formName,
                `${PERSON_SECTION}.${TITLE_BEFORE_FIELD}`,
                `${PERSON_SECTION}.${FIRST_NAME_FIELD}`,
                `${PERSON_SECTION}.${LAST_NAME_FIELD}`,
                `${PERSON_SECTION}.${TITLE_AFTER_FIELD}`,
            ),
        );
    }

    // Company name
    const shouldValidateCompanyName = yield select(getShouldValidateSection(formName, COMPANY_NAME_FIELD));
    if (!shouldValidateCompanyName) {
        yield put(untouch(formName, `${COMPANY_NAME_FIELD}`));
    }

    // Permanent address
    const shouldValidatePermanentAddress = yield select(getShouldValidateSection(formName, PERMANENT_ADDRESS_SECTION));
    if (!shouldValidatePermanentAddress) {
        yield call(untouchAddressByType, formName, PERMANENT_ADDRESS_SECTION);
    }

    // Contact address
    const shouldValidateContactAddress = yield select(getShouldValidateSection(formName, CONTACT_ADDRESS_SECTION));
    if (!shouldValidateContactAddress) {
        yield call(untouchAddressByType, formName, CONTACT_ADDRESS_SECTION);
    }

    // Email
    const shouldValidateEmail = yield select(getShouldValidateSection(formName, EMAIL_FIELD));
    if (!shouldValidateEmail) {
        yield put(untouch(formName, `${EMAIL_FIELD}`));
    }

    // Phone
    const shouldValidatePhone = yield select(getShouldValidateSection(formName, MOBILE_FIELD));
    if (!shouldValidatePhone) {
        yield put(untouch(formName, `${MOBILE_FIELD}`));
    }

    // Files
    yield put(untouch(formName, FILES_FIELD));
}

function* untouchAddressByType(formName: string, addressType: string) {
    yield put(
        untouch(
            formName,
            `${addressType}.${STREET_FORM_FIELD}`,
            `${addressType}.${DESCRIPTION_NUMBER_FORM_FIELD}`,
            `${addressType}.${ORIENTATION_NUMBER_FORM_FIELD}`,
            `${addressType}.${ZIP_FORM_FIELD}`,
            `${addressType}.${CITY_FORM_FIELD}`,
            `${addressType}.${STATE_FORM_FIELD}`,
        ),
    );
}

function* placeSelectedSaga(formName, addressType, { payload }) {
    const detail = yield call(autocompleteApi.getPlaceDetail, payload);
    // @ts-ignore we won't type sagas
    const placeDetailModel = PlaceDetail.fromServer(detail);

    yield all([
        put(change(formName, `${addressType}.${STREET_FORM_FIELD}`, placeDetailModel.route)),
        put(change(formName, `${addressType}.${DESCRIPTION_NUMBER_FORM_FIELD}`, placeDetailModel.premise)),
        put(change(formName, `${addressType}.${ORIENTATION_NUMBER_FORM_FIELD}`, placeDetailModel.street_number)),
        put(change(formName, `${addressType}.${CITY_FORM_FIELD}`, placeDetailModel.locality)),
        put(change(formName, `${addressType}.${ZIP_FORM_FIELD}`, placeDetailModel.postal_code)),
        put(change(formName, `${addressType}.${STATE_FORM_FIELD}`, placeDetailModel.country)),
    ]);
}

function* copyPerAddressIfNeededSaga(formName) {
    const addressesIdentical = yield select(getAddressesIdentical, formName);

    // TODO !!!

    if (addressesIdentical) {
        const perAddress = yield select(getFormFieldValueWithState, formName, `${PERMANENT_ADDRESS_SECTION}`);

        // // On INAS contacts country other domestic is prohibited on contact address.
        // if (addressesIdentical && policy.isUniqaContract && perAddress.get(STATE_FORM_FIELD) !== policy.countryCode) {
        //     yield put(change(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`, !addressesIdentical));
        // } else {
        yield all([
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${STREET_FORM_FIELD}`, perAddress.get(STREET_FORM_FIELD))),
            put(
                change(
                    formName,
                    `${CONTACT_ADDRESS_SECTION}.${DESCRIPTION_NUMBER_FORM_FIELD}`,
                    perAddress.get(DESCRIPTION_NUMBER_FORM_FIELD),
                ),
            ),
            put(
                change(
                    formName,
                    `${CONTACT_ADDRESS_SECTION}.${ORIENTATION_NUMBER_FORM_FIELD}`,
                    perAddress.get(ORIENTATION_NUMBER_FORM_FIELD),
                ),
            ),
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${CITY_FORM_FIELD}`, perAddress.get(CITY_FORM_FIELD))),
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${ZIP_FORM_FIELD}`, perAddress.get(ZIP_FORM_FIELD))),
            put(change(formName, `${CONTACT_ADDRESS_SECTION}.${STATE_FORM_FIELD}`, perAddress.get(STATE_FORM_FIELD))),
        ]);
        // }
    }
}

function* toggleAddressesIdentitySaga(formName) {
    const addressesIdentical = yield select(getAddressesIdentical, formName);
    // yield put(change(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`, !addressesIdentical));
    yield put(change(formName, ARE_ADDRESSES_IDENTICAL_FIELD, !addressesIdentical));
    yield all([call(copyPerAddressIfNeededSaga, formName)]);
}

export default createSaga;
