import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field } from "core/hookForm";

import { getIsActiveOperationsSet } from "../../../selectors";

export interface FormConsentBoxProps {
    disabled: boolean;
}

export const FormConsentBox: FC<FormConsentBoxProps> = ({ disabled }) => {
    const { t } = i18n.useTranslation();
    const isActiveOperationsSet = useSelector(getIsActiveOperationsSet);

    return (
        <>
            <Box mb={2}>
                <Typography
                    markDown={
                        isActiveOperationsSet
                            ? t("form.contractActiveOperations.revokeConsentDescription")
                            : t("form.contractActiveOperations.consentDescription")
                    }
                />
            </Box>

            <Box mb={4}>
                <Field
                    input="switch"
                    name="consentGiven"
                    label={
                        isActiveOperationsSet
                            ? t("form.contractActiveOperations.revokeConsentLabel")
                            : t("form.contractActiveOperations.consentLabel")
                    }
                    disabled={disabled}
                />
            </Box>
        </>
    );
};
