import { withTheme } from "@mui/styles";
import styled from "styled-components";

export const StyledSVGWithPopover = withTheme(styled("div")`
    .tooltip {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    /* Tooltip text */
    .tooltip::after {
        content: attr(data-tooltip); /* Use the data-tooltip attribute */
        position: absolute;
        top: 100%; /* Position below the element */
        left: 50%;
        transform: translateX(${(props) => (props.$popoverLeft ? props.$popoverLeft : "-50%")});
        background-color: black;
        color: white;
        padding: 5px;
        border-radius: 4px;
        white-space: nowrap;
        font-size: 12px;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 10;
        overflow: visible;
    }

    /* Show the tooltip on hover */
    .tooltip:hover::after {
        opacity: 1;
        transition: opacity 0.3s;
    }
`);

export const StyledSVG = withTheme(styled("svg")`
    flex-shrink: 0;
`);
export const StyledPath = withTheme(styled("path")`
    fill: ${(props) => (props.color ? props.theme.palette.colors[props.color] : "")};
`);
