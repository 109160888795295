import React, { FC } from "react";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import { FullPageMessage } from "containers";
import { DataLoadingsStatusEnum } from "types";

import { FullPagePolicyHolderForm } from "../form";
import { getClientDataState } from "../selectors";

export const EditClientDataTab: FC = () => {
    const { t } = i18n.useTranslation();
    const clientDataState = useSelector(getClientDataState);

    if (clientDataState === DataLoadingsStatusEnum.LOADED) {
        return <FullPagePolicyHolderForm />;
    } else {
        return (
            <FullPageMessage
                title={
                    clientDataState === DataLoadingsStatusEnum.ACCESS_DENIED
                        ? t("error.page.editClientForm.noAccessTitle")
                        : t("error.page.editClientForm.errorTitle")
                }
                message={
                    clientDataState === DataLoadingsStatusEnum.ACCESS_DENIED
                        ? t("error.page.editClientForm.noAccessText")
                        : t("error.page.editClientForm.errorText")
                }
            />
        );
    }
};
