import React from "react";
import { useParams } from "react-router-dom";

import { Button, Loading, Typography } from "ui-library/atoms";
import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { InPageHookFormLayout } from "containers/inPageForm";
import { Form, FormOtpBox } from "core/hookForm";
import { ChangeSuccessTextType } from "types";

import { ContractContent, FormConsentBox } from "./components";
import { useActiveOperationsForm } from "./hooks";
import { ActiveOperationsOperationTypeEnum, FormState } from "./enums";

import { FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS } from "../../constants";

export const ActiveOperationsForm = () => {
    const { form, onSubmit, handleSubmit, isSubmitting, isOtpResent, formState, handleOptResend } = useActiveOperationsForm();
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    const { consentGiven, otpCode, operationType } = form.watch();
    const isSubmittable = consentGiven && (formState !== FormState.OTP_CHALLENGE || (formState === FormState.OTP_CHALLENGE && otpCode));

    const formTitleKey = "change.title.CONTRACT_ACTIVE_OPERATIONS_SET";

    return (
        <Form form={form} onSubmit={onSubmit}>
            <InPageHookFormLayout
                formName={FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS}
                formTitle={t(formTitleKey, { contractNumber: contract.contractNumber })}
                isFormSubmitted={formState === FormState.SUBMITTED}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_PROCESSED}
            >
                {/* TODO: When final texts for revert action will be defined - return to original version.  */}
                {operationType == ActiveOperationsOperationTypeEnum.ACTIVE_OPERATIONS_SET && (
                    <>
                        {operationType == ActiveOperationsOperationTypeEnum.ACTIVE_OPERATIONS_SET && <ContractContent />}

                        <FormConsentBox disabled={formState === FormState.OTP_CHALLENGE} />

                        {formState === FormState.OTP_CHALLENGE && (
                            <FormOtpBox handleOtpResend={handleOptResend} isOtpResent={isOtpResent} />
                        )}

                        <Box display="flex" justifyContent="end">
                            <Button
                                variant="contained"
                                color="blue"
                                onClick={handleSubmit(onSubmit)}
                                disabled={isSubmitting || !isSubmittable}
                            >
                                {isSubmitting ? <Loading loading={true} /> : t("common.send")}
                            </Button>
                        </Box>
                    </>
                )}

                {/* TMP static text when unset */}
                {operationType == ActiveOperationsOperationTypeEnum.ACTIVE_OPERATIONS_UNSET && (
                    <ContentBox fullWidth>
                        <Typography variant="p" markDown={t("form.contractActiveOperations.alreadyConsent")} />
                    </ContentBox>
                )}
            </InPageHookFormLayout>
        </Form>
    );
};
