import React, { FC } from "react";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { SimpleLabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { createGetChangedFieldsList, createGetFieldsValuesList, getFormFieldValue } from "core/form";
import { getLocalizedIdentityCard } from "core/localized";
import { model } from "core/util";
import { AddressType } from "enums";
import { LegalEntity as LegalEntityType } from "types";

import {
    COMPANY_NAME_FIELD,
    IDENTIFICATION_CARD_SECTION,
    PERMANENT_ADDRESS_SECTION,
    PERSON_SECTION,
    TYPE_OF_DATA_CHANGE_FIELD,
    VISIBLE_CHANGED_FIELDS,
} from "../../constants";
import { formatBoldIfDifferent, formatOneLineNameWithTitlesBoldDifferent, getLocalizedOneLineAddressFullBoldDifferent } from "./formatters";
import { DataChangeType } from "../../types";

const getHolderPerAddressIdChangeInfoMsg = (t: TFunction, changeFieldsList) => {
    const getMessageForField = (field: string, translationKey: string) =>
        changeFieldsList.some((obj) => obj.field === field) ? t(translationKey) : null;

    const personMessage = getMessageForField(PERSON_SECTION, "holder.bulkChangeInfo.ofHolder");
    const companyMessage = getMessageForField(COMPANY_NAME_FIELD, "holder.bulkChangeInfo.ofHolder");
    const permanentAddressMessage = getMessageForField(PERMANENT_ADDRESS_SECTION, "holder.bulkChangeInfo.ofPermanentAddress");
    const idCardMessage = getMessageForField(IDENTIFICATION_CARD_SECTION, "holder.bulkChangeInfo.ofIdentificationCard");

    return [personMessage, companyMessage, permanentAddressMessage, idCardMessage].filter(Boolean).join("/");
};

const createFieldMessages = (t: TFunction, isCompany: boolean) => ({
    email: t("holder.emailAddress"),
    attachments: t("common.attachments"),
    companyName: t("holder.companyName"),
    contactAddress: t("holder.contactAddress"),
    identificationCard: t("holder.identityCard"),
    mobile: t("holder.mobile"),
    permanentAddress: isCompany ? t("holder.companyAddress") : t("holder.permanentAddress"),
    person: t("common.client"),
    newValue: t("common.newValue"),
});

export interface ChangeSummaryProps {
    formName: string;
    clientData: LegalEntityType;
}

export const ChangeSummary: FC<ChangeSummaryProps> = ({ formName, clientData }) => {
    const { t } = i18n.useTranslation();

    const fieldMessages = createFieldMessages(t, !clientData.isPerson);

    const typeOfDataChange = useSelector(getFormFieldValue(formName, TYPE_OF_DATA_CHANGE_FIELD));
    const changedFieldsListValues = useSelector(createGetChangedFieldsList(VISIBLE_CHANGED_FIELDS, formName));
    const allFieldsListValues = useSelector(createGetFieldsValuesList(VISIBLE_CHANGED_FIELDS, formName));
    const changedFieldsList = typeOfDataChange === DataChangeType.ALL ? allFieldsListValues : changedFieldsListValues;

    const clientPermAddress = model.getPlainObjAddressByTypeCode(clientData.addresses, AddressType.PER);
    const clientContAddress = model.getPlainObjAddressByTypeCode(clientData.addresses, AddressType.CON);

    const perAddressFormatter = (address) => getLocalizedOneLineAddressFullBoldDifferent(t, address, clientPermAddress);
    const conAddressFormatter = (address) => getLocalizedOneLineAddressFullBoldDifferent(t, address, clientContAddress);
    const personFormatter = (person) => formatOneLineNameWithTitlesBoldDifferent(person, clientData);
    const identityCardFormatter = (values) => getLocalizedIdentityCard(t, values);

    const formatters = {
        person: personFormatter,
        companyName: (companyName) => formatBoldIfDifferent(companyName, clientData.companyName),
        identificationCard: identityCardFormatter,
        permanentAddress: perAddressFormatter,
        contactAddress: conAddressFormatter,
        mobile: (mobile) => formatBoldIfDifferent(mobile, clientData.phone),
        email: (email) => formatBoldIfDifferent(email, clientData.email),
    };

    const getFormatter = (field) => formatters[field] || ((item) => (item ? `**${item}**` : ""));
    const convertValue = (value) => (value && typeof value === "object" ? value.toJS() : value);
    const formatValue = (field, value) => getFormatter(field)(convertValue(value));

    const changeOnAllContractsInfo = getHolderPerAddressIdChangeInfoMsg(t, changedFieldsList);
    const changeOnAllContractsInfoMsg = changeOnAllContractsInfo
        ? `${t("holder.bulkChangeInfo.change")} ${changeOnAllContractsInfo} ${t("holder.bulkChangeInfo.onAllContractsWithTheSameId")}`
        : "";

    return (
        <ContentBox mt={4}>
            <Typography component="p" fontSize={14} markDown={t("form.clientDataOnContracts.summary")} />

            <ContentBox>
                {changedFieldsList.length === 0 ? <Typography fontSize={14} markDown={t("form.bulkChange.noChanges")} /> : null}
                {changedFieldsList.map(({ field, value }) => (
                    <SimpleLabelTextItem key={field} label={fieldMessages[field]} text={`${formatValue(field, value)}`} small />
                ))}
            </ContentBox>

            {changeOnAllContractsInfo && <Typography component="p" fontSize={14} markDown={changeOnAllContractsInfoMsg} />}
        </ContentBox>
    );
};

export default ChangeSummary;
