import React from "react";
import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";

import { TextField } from "ui-library/atoms";

import { FieldSize } from "enums";

import { ENTER_KEY } from "../constants";
import { Consumer } from "../FormWideOnEnterContext";
import { getErrorsAsString } from "../util";

const InputText = ({
    input,
    meta,
    tabIndex,
    disabled,
    id,
    onEnter,
    label,
    labelOptional,
    labelDesc,
    tooltip,
    placeholder,
    autoFocus,
    prefix,
    sufix,
    multiline,
    rows,
    size,
    colourWhenChanged,
    displayErrorAsPopover,
}) => {
    // TODO handle error content.
    const errorContent = getErrorsAsString(meta);

    return (
        <Consumer>
            {(formWideOnEnter) => (
                <TextField
                    label={label}
                    labelOptional={labelOptional}
                    labelDesc={labelDesc}
                    value={input.value}
                    isValueChanged={meta.dirty}
                    colourWhenChanged={colourWhenChanged}
                    displayErrorAsPopover={displayErrorAsPopover}
                    tooltip={tooltip}
                    error={meta.touched && meta.error && !meta.error.isEmpty()}
                    errorContent={errorContent}
                    placeholder={placeholder}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    id={id}
                    onKeyPress={handleKeyPress(onEnter || formWideOnEnter)}
                    prefix={prefix}
                    sufix={sufix}
                    autoFocus={autoFocus}
                    multiline={multiline}
                    rows={rows}
                    size={size}
                />
            )}
        </Consumer>
    );
};

const handleKeyPress = (onEnterHandler) => (ev) => {
    if (ev.key === ENTER_KEY && onEnterHandler) {
        onEnterHandler();
    }
};

InputText.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    error: PropTypes.bool,
    errorContent: PropTypes.string,
    labelDesc: PropTypes.string,
    prefix: PropTypes.string,
    sufix: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    size: PropTypes.string,
    colourWhenChanged: PropTypes.bool,
    displayErrorAsPopover: PropTypes.bool,
};

InputText.defaultProps = {
    placeholder: null,
    tabIndex: null,
    type: null,
    disabled: false,
    onEnter: null,
    autoFocus: false,
    size: FieldSize.MEDIUM,
    colourWhenChanged: false,
    displayErrorAsPopover: false,
};

export default InputText;
