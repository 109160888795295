import React, { FC } from "react";

import { Loading } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import { SimpleLabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { AddressType } from "enums";
import { ContractOverview, DataLoadingsStatusEnum, LegalEntity } from "types";

import { comparePlain, getAddressByTypeCode, getElCommunicationState, getElComValueBasedOnState } from "./util";
import { ElComState } from "./types";

const getElComColorByState = (elComState: ElComState) => {
    switch (elComState) {
        case ElComState.SET:
            return "green";
        case ElComState.SET_DIFFERENT_EMAIL:
            return "orange";
        default:
            return "red";
    }
};

export interface CheckboxToggleableContentForContractProps {
    contractOverview: { state: DataLoadingsStatusEnum; data: ContractOverview };
    clientData: LegalEntity;
}

export const CheckboxToggleableContentForContract: FC<CheckboxToggleableContentForContractProps> = ({ contractOverview, clientData }) => {
    const { t } = i18n.useTranslation();

    const contractOverviewDataState = contractOverview?.state || DataLoadingsStatusEnum.LOADING;

    // Loading
    if (contractOverviewDataState === DataLoadingsStatusEnum.LOADING) {
        return <Loading loading />;
    }

    // Error
    if (contractOverviewDataState === DataLoadingsStatusEnum.ERROR) {
        return <></>;
    }

    const contractOwner = contractOverview?.data?.contractOwner;

    const labelStyle = { width: "104px" };
    const permanentAddress = getLocalizedOneLineAddressFull(t, getAddressByTypeCode(contractOwner?.addresses, AddressType.PER));
    const contactAddress = getLocalizedOneLineAddressFull(t, getAddressByTypeCode(contractOwner?.addresses, AddressType.CON));

    const elComState = getElCommunicationState(
        comparePlain(clientData.email, contractOwner.email),
        contractOverview?.data?.electronicCommunication,
    );

    return (
        <Flexbox flexDirection="column" gap={0.2}>
            <SimpleLabelTextItem label={t("holder.permanentAddress")} text={permanentAddress} small style={labelStyle} />
            <SimpleLabelTextItem label={t("holder.contactAddressShort")} text={contactAddress} small style={labelStyle} />
            <SimpleLabelTextItem label={t("holder.phone")} text={contractOwner.phone} small style={labelStyle} />
            <SimpleLabelTextItem label={t("holder.email")} text={contractOwner.email} small style={labelStyle} />
            <SimpleLabelTextItem
                label={t("holder.eComm")}
                text={getElComValueBasedOnState(t, elComState)}
                color={getElComColorByState(elComState)}
                small
                style={labelStyle}
            />
        </Flexbox>
    );
};
