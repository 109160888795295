import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

export interface SectionTitleProps {
    title: string;
}

export const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
    <Box mb={1}>
        <Typography variant="h4">{title}</Typography>
    </Box>
);
