import {
    getLocalizedAmountFilterOperatorOptions,
    getLocalizedAmountWithOperationTypeFilterOperatorOptions,
    getLocalizedChangePropositionPensionTypeMap,
    getLocalizedChangePropositionStatusMap,
    getLocalizedChangePropositionTypeMap,
    getLocalizedClientTypeCodes,
    getLocalizedFrequencyMap,
    getLocalizedFrequencyNonLifeOptions,
    getLocalizedFrequencyOptions,
    getLocalizedFundSellSwitchMap,
    getLocalizedFundTypeDdsDssMap,
    getLocalizedLegalPersonCode,
    getLocalizedLifeFrequencyMap,
    getLocalizedMutualFundsPeriodicityMap,
    getLocalizedPaymentContributionMap,
    getLocalizedPaymentContributionOptions,
    getLocalizedPensionInvestmentStrategyOptions,
    getLocalizedSchedulerPaidState,
    getLocalizedSchedulerPaymentAmount,
    getLocalizedSchedulerPaymentFrequency,
    getLocalizedSchedulerPaymentFull,
    getLocalizedSchedulerPaymentFullLegacy,
    getLocalizedSchedulerPaymentMode,
    getLocalizedSubjectType,
    getLocalizedUniqaInsuranceRiskMap,
    getLocalizedUniqaLineOfBusinessMap,
} from "./selectors";

const localized = {
    getLocalizedClientTypeCodes,
    getLocalizedFrequencyMap,
    getLocalizedPaymentContributionMap,
    getLocalizedFrequencyOptions,
    getLocalizedLifeFrequencyMap,
    getLocalizedFrequencyNonLifeOptions,
    getLocalizedPaymentContributionOptions,
    getLocalizedSubjectType,
    getLocalizedFundTypeDdsDssMap,
    getLocalizedSchedulerPaymentFull,
    getLocalizedSchedulerPaymentFullLegacy,
    getLocalizedSchedulerPaymentAmount,
    getLocalizedSchedulerPaymentFrequency,
    getLocalizedSchedulerPaymentMode,
    getLocalizedSchedulerPaidState,
    getLocalizedUniqaInsuranceRiskMap,
    getLocalizedUniqaLineOfBusinessMap,
    getLocalizedFundSellSwitchMap,
    getLocalizedMutualFundsPeriodicityMap,
    getLocalizedChangePropositionStatusMap,
    getLocalizedChangePropositionTypeMap,
    getLocalizedChangePropositionPensionTypeMap,
    getLocalizedAmountFilterOperatorOptions,
    getLocalizedAmountWithOperationTypeFilterOperatorOptions,
    getLocalizedPensionInvestmentStrategyOptions,
    getLocalizedLegalPersonCode,
};

export default localized;

export { getLocalizedAddressAsList } from "./getLocalizedAddressAsList";
export { getLocalizedCountryCode } from "./getLocalizedCountryCode";
export { getLocalizedEnumMap } from "./getLocalizedEnumMap";
export { getLocalizedIdentityCard } from "./getLocalizedIdentityCard";
export { getLocalizedOneLineAddressFull } from "./getLocalizedOneLineAddressFull";
export { getLocalizedThirdPartyPayments } from "./getLocalizedThirdPartyPayments";
export { getLocalizedThirdPartyPaymentsLegacy } from "./getLocalizedThirdPartyPaymentsLegacy";
export { getLocalizedThirdPartyPaymentsAsList } from "./getLocalizedThirdPartyPaymentsAsList";
export { getLocalizedEmployerPayments } from "./getLocalizedEmployerPayments";
export { getLocalizedCountryOptions } from "./getLocalizedCountryOptions";
export { getLocalizedDomesticCountryOption } from "./getLocalizedDomesticCountryOption";
export { getLocalizedStatementFrequencyOptions } from "./getLocalizedStatementFrequencyOptions";
export { getLocalizedPensionStatementFrequencyMap } from "./getLocalizedPensionStatementFrequencyMap.js";
export { getLocalizedMutualFundsStatementFrequencyMap } from "./getLocalizedMutualFundsStatementFrequencyMap";
export { getLocalizedMutualFundsTransactionStatusMap } from "./getLocalizedMutualFundsTransactionStatusMap";
export { getLocalizedFundSellMeansDirect } from "./getLocalizedFundSellMeansDirect";
export { getLocalizedFundSellScope } from "./getLocalizedFundSellScope";
export { getLocalizedFundSellMeansRegular } from "./getLocalizedFundSellMeansRegular";
export { getLocalizedTwoLinesAddressFull } from "./getLocalizedTwoLinesAddressFull";
