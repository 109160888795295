import React, { FC } from "react";

import { Icon, SimpleLoader, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";

import i18n from "core/i18n";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";
import { ContractMetadata, ContractOverview, DataLoadingsStatusEnum, LegalEntity } from "types";

import { CheckboxLabelOverviewContentForContract } from "./CheckboxLabelOverviewContentForContract";

export interface ContractsPickerProps {
    contractMetaData: ContractMetadata;
    contractOverview: { state: DataLoadingsStatusEnum; data: ContractOverview };
    clientData: LegalEntity;
}

export const CheckboxLabelForContract: FC<ContractsPickerProps> = ({ contractMetaData, contractOverview, clientData }) => {
    const { t } = i18n.useTranslation();

    const contractOverviewDataState = contractOverview?.state || DataLoadingsStatusEnum.LOADING;
    const isDataLoading = contractOverviewDataState === DataLoadingsStatusEnum.LOADING || !clientData;
    const isDataLoaded = contractOverview?.state === DataLoadingsStatusEnum.LOADED && clientData;
    const isDataError = contractOverview?.state === DataLoadingsStatusEnum.ERROR;
    const shouldDisplayECommWarning = useHasRole(Roles.EMPLOYEE) && !contractMetaData.isUniqaContract;

    return (
        <div>
            {shouldDisplayECommWarning && (
                <Flexbox>
                    <Box mt={0.2} mr={0.5}>
                        <Icon className="tooltip" size={14} icon={"alert"} color="red" />
                    </Box>
                    <Typography variant="p" color="red" fontSize={13} markDown={t("form.clientDataOnContracts.ecommAlert")} />
                </Flexbox>
            )}

            <Typography component="p" fontSize={14} markDown={`**${contractMetaData.contractNumber}** ${contractMetaData.productCrm}`} />

            <SimpleLoader loading={isDataLoading} />

            {isDataLoaded && <CheckboxLabelOverviewContentForContract contractOverview={contractOverview.data} clientData={clientData} />}

            {isDataError && (
                <Flexbox>
                    <Box mt={0.2} mr={0.5}>
                        <Icon className="tooltip" size={16} icon={"alert"} color="red" />
                    </Box>
                    <Typography variant="h4" color="red" markDown={t("form.clientDataOnContracts.contract.error")} />
                </Flexbox>
            )}
        </div>
    );
};
