import { SubmitType } from "./types";

export const createToggleCopyActionType = (actionPrefix: string) => `${actionPrefix}/TOGGLE_COPY`;

export const createToggleCopyAction = (actionPrefix: string) => ({
    type: createToggleCopyActionType(actionPrefix),
});

export const createSubmitElComChangeActionType = (actionPrefix: string) => `${actionPrefix}/SUBMIT_EL_COM_CHANGE`;

export const createSubmitElComChangeAction = (actionPrefix: string, actionType: SubmitType) => ({
    type: createSubmitElComChangeActionType(actionPrefix),
    payload: actionType,
});
