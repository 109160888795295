import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form/immutable";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "ui-library/modules";
import { Box } from "ui-library/layouts";
import { Button, Loading, StandaloneLink } from "ui-library/atoms";

import i18n from "core/i18n";
import modal from "core/modal";
import { Field, getFormFieldValue, InputText, max6Characters, numberCharacters } from "core/form";

import { OTP_CODE_FIELD, OTP_ID_FIELD, SUBMIT_TYPE_FIELD } from "../constants";
import { createSubmitElComChangeAction } from "../createActions";
import { SubmitType } from "../types";

const EL_COM_SUBMIT_TYPES = [
    SubmitType.ENABLE_ON_ALL_CONTRACTS,
    SubmitType.ENABLE_ON_SELECTED_CONTRACTS,
    SubmitType.DISABLE_ON_ALL_CONTRACTS,
    SubmitType.DISABLE_ON_SELECTED_CONTRACTS,
];

export interface ModalOtpProps {
    formName: string;
    handleSubmit: () => void;
    isFormBeingSubmitted: boolean;
}

export const ModalOtp: FC<ModalOtpProps> = ({ formName, handleSubmit, isFormBeingSubmitted }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const submitType = useSelector(getFormFieldValue(formName, SUBMIT_TYPE_FIELD));
    const otpCode = useSelector(getFormFieldValue(formName, OTP_CODE_FIELD));

    const isModalFormOpen = useSelector(modal.isModalFormOpen(formName));
    const closeModalForm = () => {
        if (!isFormBeingSubmitted) {
            dispatch(change(formName, OTP_ID_FIELD, null));
            dispatch(change(formName, OTP_CODE_FIELD, null));
            dispatch(modal.closeModalForm(formName));
        }
    };

    const handleResendPin = () => {
        dispatch(change(formName, OTP_ID_FIELD, null));
        dispatch(change(formName, OTP_CODE_FIELD, null));
        handleDataSubmit();
    };

    const handleDataSubmit = () => {
        if (submitType === SubmitType.CHANGE_CLIENT_DATA) {
            handleSubmit();
        } else if (EL_COM_SUBMIT_TYPES.includes(submitType)) {
            dispatch(createSubmitElComChangeAction(formName, submitType));
        }
    };

    return (
        <Modal size={"small"} open={isModalFormOpen} onClose={closeModalForm}>
            <Loading loading={isFormBeingSubmitted} fullPage />

            <ModalHeader closeAction={closeModalForm} icon={"list-check"} title={t("form.confirmation")} />
            <ModalBody>
                <Box>
                    <Field
                        component={InputText}
                        name={OTP_CODE_FIELD}
                        label={t("form.otp.label")}
                        normalize={[numberCharacters, max6Characters]}
                        id="smsCode"
                        placeholder={t("form.otp.ePinPlaceholder")}
                    />
                    <StandaloneLink color="blue" onClick={handleResendPin}>
                        {t("form.otp.resend")}
                    </StandaloneLink>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleDataSubmit} disabled={!otpCode}>
                    {t("common.send")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
