import React, { FC } from "react";
import { useSelector } from "react-redux";

import { GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    Field,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    max6Characters,
    numberSpaceCharacters,
    SingleSelect,
} from "core/form";
import { getLocalizedCountryOptions, getLocalizedDomesticCountryOption } from "core/localized";
import { FieldSize } from "enums";

import {
    CITY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    ZIP_FORM_FIELD,
} from "../../../constants";

const isDomesticCountry = (country) => country === "CZ" || country === "SK";

export interface AddressFieldsProps {
    formName: string;
    sectionName: string;
    disabled?: boolean;
}

export const AddressFields: FC<AddressFieldsProps> = ({ formName, sectionName, disabled = false }) => {
    const { t } = i18n.useTranslation();
    const countryOptions = useSelector(getLocalizedCountryOptions);
    const domesticCountryOption = useSelector(getLocalizedDomesticCountryOption);
    const selectedCountry = useSelector(getFormFieldValue(formName, `${sectionName}.${STATE_FORM_FIELD}`));
    const isUniqaContract = false;

    return (
        <>
            <Field
                name={`${sectionName}.${STREET_FORM_FIELD}`}
                component={InputText}
                label={t("holder.street")}
                normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                disabled={disabled}
                size={FieldSize.SMALL}
                colourWhenChanged
                displayErrorAsPopover
            />

            <GridLayout spacing={1}>
                <GridItem xs={2}>
                    <Field
                        name={`${sectionName}.${DESCRIPTION_NUMBER_FORM_FIELD}`}
                        component={InputText}
                        label={t("holder.descriptionNumber")}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                        disabled={disabled}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem xs={2}>
                    <Field
                        name={`${sectionName}.${ORIENTATION_NUMBER_FORM_FIELD}`}
                        component={InputText}
                        label={t("holder.orientationNumber")}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem xs={3}>
                    <Field
                        name={`${sectionName}.${CITY_FORM_FIELD}`}
                        component={InputText}
                        label={t("holder.city")}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        disabled={disabled}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem xs={2}>
                    <Field
                        name={`${sectionName}.${ZIP_FORM_FIELD}`}
                        component={InputText}
                        label={t("holder.postalCode")}
                        normalize={[
                            isDomesticCountry(selectedCountry) ? numberSpaceCharacters : lettersNumbersBasicInterpunctionCharacters,
                            isDomesticCountry(selectedCountry) ? max6Characters : max20Characters,
                        ]}
                        disabled={disabled}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem xs={3}>
                    <Field
                        name={`${sectionName}.${STATE_FORM_FIELD}`}
                        component={SingleSelect}
                        label={t("holder.countryCode")}
                        options={isUniqaContract ? domesticCountryOption : countryOptions}
                        disabled={disabled}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        hideErrorMessage
                    />
                </GridItem>
            </GridLayout>
        </>
    );
};
