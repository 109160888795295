import { model } from "core/util";
import { Address as AddressType } from "types";

export const AddressV2 = model.createModel<AddressType, AddressType>((json) => ({
    addressTypeCode: json.addressTypeCode,
    street: json.street,
    orientationNumber: json.orientationNumber,
    descriptionNumber: json.descriptionNumber,
    city: json.city,
    postalCode: json.postalCode,
    countryCode: json.countryCode,
}));
