import { TFunction } from "core/i18n";
import { ChangesDefinition, ContractChangeType } from "types";

export const changesDefinition = (t: TFunction): ChangesDefinition => {
    const authenticatedChangeOptions = [
        {
            code: ContractChangeType.CONTRACT_NAME,
            value: t("changeType.CONTRACT_NAME"),
        },
        {
            code: ContractChangeType.ELECTRONIC_COMMUNICATION,
            value: t("changeType.ELECTRONIC_COMMUNICATION"),
        },
        {
            code: ContractChangeType.SAVER,
            value: t("changeType.SAVER"),
        },
        {
            code: ContractChangeType.BENEFICIARIES,
            value: t("changeType.BENEFICIARIES"),
        },
        {
            code: ContractChangeType.SAVER_PAYMENTS,
            value: t("changeType.SAVER_PAYMENTS"),
        },
    ];

    const oneTimeChangeOptions = [];

    return {
        authenticatedChangeOptions,
        oneTimeChangeOptions,
    };
};
