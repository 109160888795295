import React, { FC } from "react";

import { GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    Field,
    InputText,
    lettersDotSpaceCharacters,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
} from "core/form";
import { FieldSize } from "enums";

import { FIRST_NAME_FIELD, LAST_NAME_FIELD, PERSON_SECTION, TITLE_AFTER_FIELD, TITLE_BEFORE_FIELD } from "../../../constants";

export interface IndividualPersonFieldsProps {
    formName: string;
}

export const IndividualPersonFields: FC<IndividualPersonFieldsProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <GridLayout spacing={1}>
                <GridItem md={2}>
                    <Field
                        name={`${PERSON_SECTION}.${TITLE_BEFORE_FIELD}`}
                        component={InputText}
                        label={t("holder.titleBeforeShort")}
                        normalize={[lettersDotSpaceCharacters, max20Characters]}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem md={3}>
                    <Field
                        name={`${PERSON_SECTION}.${FIRST_NAME_FIELD}`}
                        component={InputText}
                        label={t("holder.firstName")}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem md={5}>
                    <Field
                        name={`${PERSON_SECTION}.${LAST_NAME_FIELD}`}
                        component={InputText}
                        label={t("holder.lastName")}
                        normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
                <GridItem md={2}>
                    <Field
                        name={`${PERSON_SECTION}.${TITLE_AFTER_FIELD}`}
                        component={InputText}
                        label={t("holder.titleAfterShort")}
                        normalize={[lettersDotSpaceCharacters, max20Characters]}
                        size={FieldSize.SMALL}
                        colourWhenChanged
                        displayErrorAsPopover
                    />
                </GridItem>
            </GridLayout>
        </>
    );
};
