import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { formatBirthNumber, oneLineNameWithTitles } from "core/formatters";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import { AddressTypeCodeEnum } from "types";

import { getPolicyHolder } from "../../../selectors";

export const ContractContent = () => {
    const { t } = i18n.useTranslation();
    const policyHolder = useSelector(getPolicyHolder);

    const clientData = {
        clientName: oneLineNameWithTitles(policyHolder),
        clientAddress: getLocalizedOneLineAddressFull(t, model.getAddressByTypeCode(policyHolder, AddressTypeCodeEnum.PER)),
        birthNumber: formatBirthNumber(policyHolder.birthNumber),
    };

    return (
        <ContentBox fullWidth>
            {/* Contract title */}
            <Typography variant="h4">{t("pension.dds.aoConsent.title")}</Typography>
            <br />

            {/* Contract introduction */}
            <Typography variant="p" markDown={t("pension.dds.aoConsent.introduction")} />
            <br />
            <Typography variant="p" markDown={t("pension.dds.aoConsent.uniqa")} />
            <br />
            <Typography variant="p" markDown={t("pension.dds.aoConsent.and")} />
            <br />
            <Typography variant="p" markDown={t("pension.dds.aoConsent.client", clientData)} />
            <br />
            <Typography variant="p" markDown={t("pension.dds.aoConsent.introductionEnding")} />
            <br />

            {/* Section 1 */}
            <h4>{t("pension.dds.aoConsent.section1.title")}</h4>
            <Typography variant="p" markDown={t("pension.dds.aoConsent.section1.content")} />
            <br />

            {/* Section 2 */}
            <h4>{t("pension.dds.aoConsent.section2.title")}</h4>
            <ol>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section2.paragraph1")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section2.paragraph2")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section2.paragraph3")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section2.paragraph4")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section2.paragraph5")} />
                </li>
            </ol>
            <br />

            {/* Section 3 */}
            <h4>{t("pension.dds.aoConsent.section3.title")}</h4>
            <Typography variant="p" markDown={t("pension.dds.aoConsent.section3.content")} />
            <ol type="a">
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section3.paragraphA")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section3.paragraphB")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section3.paragraphC")} />
                </li>
            </ol>
            <br />

            {/* Section 4 */}
            <h4>{t("pension.dds.aoConsent.section4.title")}</h4>
            <ol>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1")} />
                    <ol type="a">
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1a")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1b")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1c")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1d")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1e")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1f")} />
                        </li>
                    </ol>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph1ending")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2")} />
                    <ol type="a">
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2a")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2b")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2c")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2d")} />
                            <ol>
                                <li>
                                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2d1")} />
                                </li>
                                <li>
                                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2d2")} />
                                </li>
                                <li>
                                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph2d3")} />
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph3")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph4")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph5")} />
                    <ol type="a">
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph5a")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph5b")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph5c")} />
                        </li>
                    </ol>
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6")} />
                    <ol type="a">
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6a")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6b")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6c")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6d")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6e")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph6f")} />
                        </li>
                    </ol>
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph7")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph8")} />
                    <ol type="a">
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph8a")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section4.paragraph8b")} />
                        </li>
                    </ol>
                </li>
            </ol>
            <br />

            {/* Section 5 */}
            <h4>{t("pension.dds.aoConsent.section5.title")}</h4>
            <Typography variant="p" markDown={t("pension.dds.aoConsent.section5.content")} />
            <ol type="a">
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section5.paragraphA")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section5.paragraphB")} />
                </li>
            </ol>
            <br />

            {/* Section 6 */}
            <h4>{t("pension.dds.aoConsent.section6.title")}</h4>
            <ol>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph1")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2")} />
                    <ol type="a">
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2a")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2b")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2c")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2d")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2e")} />
                        </li>
                        <li>
                            <Typography variant="p" markDown={t("pension.dds.aoConsent.section6.paragraph2f")} />
                        </li>
                    </ol>
                </li>
            </ol>
            <br />

            {/* Section 7 */}
            <h4>{t("pension.dds.aoConsent.section7.title")}</h4>
            <ol>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section7.paragraph1")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section7.paragraph2")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section7.paragraph3")} />
                </li>
                <li>
                    <Typography variant="p" markDown={t("pension.dds.aoConsent.section7.paragraph4")} />
                </li>
            </ol>
            <br />
        </ContentBox>
    );
};
