import React, { FC, SVGAttributes } from "react";

import { Typography } from "./Typography";
import { Icon } from "../Icons/Icon";
import { Box, Flexbox } from "../../layouts";

export interface AlertMessageProps extends SVGAttributes<SVGElement> {
    message: string;
    color?: string;
    variant?: string;
    internalAppLink?: boolean;
}

export const AlertMessage: FC<AlertMessageProps> = ({ message, color = "red", variant = "p", internalAppLink }) => {
    return (
        <Flexbox alignItems="center" direction="row" gap={0.5}>
            <Box mt={0.2} mr={0.5}>
                <Icon className="tooltip" size={16} icon={"alert"} color={color} />
            </Box>
            <Typography variant={variant} color={color} markDown={message} internalAppLink />
        </Flexbox>
    );
};
