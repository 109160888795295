import React from "react";
import { useSelector } from "react-redux";

import { Block, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import { ContractChangeType } from "types";

import { getIdObject } from "../selectors";

export function RestrictionMessage() {
    const { t } = i18n.useTranslation();
    const idObject = useSelector(getIdObject);
    const link = router.getStaticUrl(
        Pages.CONTRACT_DDS,
        Tabs.CHANGES,
        { idObject },
        { type: ContractChangeType.CONTRACT_ACTIVE_OPERATIONS },
    );

    return (
        <Block position="relative" variant="redGradient" shadow={false} mb={2}>
            <Box display="flex">
                <Typography
                    color="gray"
                    variant="p"
                    markDown={t("pension.activeOperations.restrictionMessage", { link })}
                    internalAppLink
                />
            </Box>
        </Block>
    );
}
