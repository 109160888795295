import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { ContractMetadata, ContractOverview } from "model/contract";
import { LegalEntityV2 } from "model/common/v2";

const getClientData = (idSubject) => fetch.doGet(`${API_PREFIX}/client/${idSubject}`, null, LegalEntityV2.fromServer);

const getClientActiveContractsMetadata = (idSubject) =>
    fetch.doGet(`${API_PREFIX}/client/${idSubject}/contract/meta-data/active`, null, ContractMetadata.fromServerList);

const getClientContractOverview = (idSubject, idObject) =>
    fetch.doGet(`${API_PREFIX}/client/${idSubject}/contract/${idObject}/overview`, null, ContractOverview.fromServer);

export default {
    getClientData,
    getClientActiveContractsMetadata,
    getClientContractOverview,
};
