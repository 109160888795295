import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import contractCancellationForm from "form/fullPage/contractCancellation";
import { FORM_CONTRACT_CANCELLATION } from "../constants";
import i18n from "core/i18n";
import vehicleCancellation from "serverApi/vehicleCancellation";
import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

export default function ContractCancellationForm() {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const [isFeasible, setIsFeasible] = useState<boolean>(undefined);

    useEffect(() => {
        async function fetchIsFeasible() {
            try {
                const [error, res] = await vehicleCancellation.isFeasible(idObject);

                setIsFeasible(error ? false : res.body.isFeasible);
            } catch (err) {
                console.error(err);
            }
        }

        fetchIsFeasible();
    });

    if (isFeasible === true) {
        return contractCancellationForm.createInPageContainer(FORM_CONTRACT_CANCELLATION)();
    } else if (isFeasible === false) {
        return (
            <ContentBox mt={3}>
                <Typography variant={"b"}>{t("vehicle.change.notFeasible.cancellation")}</Typography>
            </ContentBox>
        );
    }

    return <span>...</span>;
}
