export const NAME = "page-detail-dds";

// Entity
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_ENQUEUED_DOCUMENTS = `${NAME}/ENTITY_ENQUEUED_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;
export const ENTITY_FUNDS_CHANGE_PERMITTED = `${NAME}/ENTITY_FUNDS_CHANGE_PERMITTED`;
export const ENTITY_CHANGE_PROPOSITIONS = `${NAME}/ENTITY_CHANGE_PROPOSITIONS`;

// Full page forms
export const FORM_EDIT_PARTICIPANT = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_BENEFICIARIES = `${NAME}/FORM_EDIT_BENEFICIARIES`;
export const FORM_EDIT_FUNDS_CHANGE = `${NAME}/FORM_EDIT_FUNDS_CHANGE`;

// Modal forms
export const FORM_EDIT_ELECTRONIC_COMMUNICATION = `${NAME}/FORM_EDIT_ELECTRONIC_COMMUNICATION`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_PARTICIPANT_PAYMENTS = `${NAME}/FORM_EDIT_CONTRACT_OWNER_PAYMENTS`;
export const FORM_EDIT_PENSION_STATEMENT_SETTINGS = `${NAME}/FORM_EDIT_PENSION_STATEMENT_SETTINGS`;
export const FORM_EDIT_STATEMENT_PASSWORD = `${NAME}/FORM_EDIT_STATEMENT_PASSWORD`;
export const FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS = `${NAME}/FORM_EDIT_CONTRACT_ACTIVE_OPERATIONS`;

// Columns
export const INVESTMENT_FUND_COLUMN = "investmentFund";
export const PAYMENT_DATE_COLUMN = "paymentDate";
export const TYPE_COLUMN = "type";
export const GROSS_AMOUNT_COLUMN = "grossAmount";
